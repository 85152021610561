import React, { useEffect, useState, useRef } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { giftcardData, userData, profileData, RedeemCouponData, profileSelector, BuygiftcardData } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import copy from "copy-to-clipboard";
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import { message, Card, Table } from 'antd';
import { generalSelector } from "../../store/reducer/general";


function ViewHistory() {
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	let userDetails = JSON.parse(localStorage.getItem('userdata'));

	if (!userDetails) {
		// dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const { giftDatas, userDatas, redeemDatas, redeemloading, buygiftcardDatas, buygiftcardloading } = useSelector(profileSelector);
	const { generalDatas } = useSelector(generalSelector);

	console.log(buygiftcardDatas, buygiftcardloading, "buygiftcardDatas111");

	const [PurchasedData, setPurchasedData] = useState([]);
	const [RedeemData, setRedeemData] = useState([]);
	const [BuyGiftData, setBuyGiftData] = useState([])
	console.log(BuyGiftData, "BuyGiftData");
	const [TopupData, setTopupData] = useState([])
	console.log(TopupData, "TopupData111");
	const [redeemDetails, setRedeemDetails] = useState({ coupon_code: "" });
	const [errorMessage, seterrorMessage] = useState('');
	const [showRedeemModal, setshowRedeemModal] = useState(false);
	const [walletAmount, setwalletAmount] = useState(0)
	const [copyButton, setcopyButton] = useState(false);
	const [timezone, setTimezone] = useState('America/Chicago');
	console.log(timezone, "timezone");


	const onCloseRedeem = () => {
		seterrorMessage('')
		setshowRedeemModal(false);
		window.scroll({ top: 0, left: 0, behavior: "smooth", });
	}

	const showRedeemPopup = () => {
		setshowRedeemModal(true);
	}

	const AddWalletAmount = () => {

		if (redeemDetails.coupon_code.trim() == '') {
			seterrorMessage('Please enter the coupon code');
			return false;
		}

		let postData = { redeem_code: redeemDetails.coupon_code }

		dispatch(
			RedeemCouponData({ postData })
		);

	};


	const getProfileDispatch = (loginUser) => { dispatch(profileData({ user_id: loginUser })) };
	const userDispatch = (loginUser) => { dispatch(userData({ user_id: loginUser })) };
	const giftCardDispatch = (loginUser) => { dispatch(giftcardData({ user_id: loginUser })) };
	const BuygiftCardDispatch = (loginUser) => { dispatch(BuygiftcardData({ user_id: loginUser })) };


	useEffect(() => {
		giftCardDispatch(loginUser)
		getProfileDispatch(loginUser)
		userDispatch(loginUser)
		BuygiftCardDispatch(loginUser)
	}, [])


	useEffect(() => {
		formValidator.current.showMessages()
	}, [])

	useEffect(() => {

		let taxdata = 0;
		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
		}

	}, [generalDatas])


	useEffect(() => {
		if (userDatas) {
			setwalletAmount(userDatas?.data[0].wallet)
			console.log(userDatas, 'userDatas');
		}
	}, [userDatas])

	useEffect(() => {
		if (giftDatas) {
			console.log(giftDatas, 'giftDatas');
			giftDatas.data.map((gifts, key) => {
				console.log(gifts, "gifts6789");

				let newdata = { redeem_flag: gifts.redeem_flag, amount: gifts.amount, created_at: gifts.created_at, date_created: gifts.date_created, sender_id: giftDatas.userEmails[key] }

				TopupData.push(newdata)

				// if (gifts.redeem_flag == 1) {
				// 	RedeemData.push(newdata)
				// } else {
				// 	PurchasedData.push(newdata)
				// }
			})
			setTopupData([...TopupData])
		}

	}, [giftDatas])

	// BuyGiftCardDatas
	useEffect(() => {
		if (buygiftcardDatas) {
			console.log(buygiftcardDatas, 'buygiftcardDatas');
			buygiftcardDatas.data.map((gifts, key) => {
				console.log(gifts, "giftsgifts");
				let newdata = { amount: gifts.amount, created_at: gifts.created_at, code: gifts.code, message: gifts.message, status: gifts.status, updated_at: gifts.updated_at, sender_id: gifts.sender_id, email: gifts.email_id }
				BuyGiftData.push(newdata)
			})
			setBuyGiftData([...BuyGiftData])
		}

	}, [buygiftcardDatas])


	useEffect(() => {
		console.log(redeemloading, "redeemloading")
		if (redeemloading === API_STATUS.FULFILLED) {
			seterrorMessage('');
			if (redeemDatas.status == true) {
				setshowRedeemModal(false)
				toast.dismiss();
				toast.success('Coupon amount added to your wallet successfully.!');

				window.setTimeout(function () {
					window.location.reload()
				}, 2000);
			} else {
				setshowRedeemModal(true)
				// console.log(re);
				seterrorMessage(redeemDatas.message);
				setRedeemDetails({ ...redeemDetails, coupon_code: '' })
			}

		}
		if (redeemloading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (redeemloading === API_STATUS.REJECTED) {
			toast.dismiss();
			// setshowRedeemModal(false)
			seterrorMessage('Something went wrong.');
			setRedeemDetails({ ...redeemDetails, coupon_code: '' })

		}
	}, [redeemloading]);
	console.log(RedeemData, 'RedeemData');

	const copyToClipboard = (code, key) => {

		copy(code);
		setcopyButton(key);
		// message.success("Code Copied")
		toast.dismiss()
		toast.success("Code Copied", { position: "top-center" })
	}

	// Table Column
	const columns = [
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount) => <span>{amount}</span>,

		},
		{
			title: 'Coupon Code',
			dataIndex: 'code',
			key: 'code',
			render: (amount) => <span>{amount}</span>,

		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Date',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount) => <span>{amount

			}</span>,
		},
	];

	console.log(BuyGiftData,'BuyGiftData');
	console.log(TopupData,'TopupData');

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="section-full mt-4 shop-account">
					<div className="container">
						<div className="row" style={{ paddingBottom: '1rem' }}>
							<div className="col-lg-9" style={{ paddingBottom: '10px' }}>
								<a href="/newgiftcard" class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} >Buy Gift Card</a>
								<a href="#" class="btn btn-primary btn-xs" onClick={showRedeemPopup} style={{ marginRight: '10px' }}>Redeem Code</a>&nbsp;
								<a href="/dipwallet" class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} >Dip Wallet</a>&nbsp;
								<button class="btn btn-success btn-xs" style={{ marginRight: '10px' }} >View History</button>

							</div>
							<div className="col-lg-3">
								<h3 className="m-b5 pull-right">Balance: $ {parseFloat(walletAmount).toFixed(2)} </h3>
							</div>
						</div>

						<div className="row" style={{ marginBottom: '35px' }}>
							<div className="col-md-6">
								<h2>Purchased Gift Cards</h2>
								<Card style={{ border: '1px solid black',height:BuyGiftData && BuyGiftData.length > 5 ? '360px' :'',overflowY:'scroll' }}>
									{/* <Table dataSource={BuyGiftData} columns={columns} /> */}
									<table className="table check-tbl tbl-border ">
										<thead>
											<tr>
												<th>Amount</th>
												<th>Coupon Code</th>
												<th> Status</th>
												<th style={{ textAlign: 'center' }}>Date</th>
											</tr>
										</thead>
										<tbody>

											{(BuyGiftData && BuyGiftData.length > 0) ? BuyGiftData.map((giftcard, key) => {
												console.log(key, 'key==');
												return <><tr className="alert">
													<td className="product-item-price">$ {parseFloat(giftcard.amount).toFixed(2)}</td>
													<td className="product-item-price"> {giftcard.code}</td>
													<td className="product-item-price"> {giftcard.status == 1 ? <><Button className="btn btn-outline-success btn-sm" href="javascript:void(0);" onClick={() => {
														copyToClipboard(giftcard.code, key)
													}}
													>Copy</Button></> : 'Redeemed'}</td>
													<td className="product-item-price" style={{ textAlign: 'center' }}> {(timezone) ? moment(giftcard.created_at).tz(timezone).format('MM-DD-YYYY hh:mm A') : moment(giftcard.created_at).tz('America/Chicago').format('MM-DD-YYYY hh:mm A')}</td>

												</tr></>
											}) : ''
											}

											{
												(BuyGiftData && BuyGiftData.length == 0) ? <td colSpan={4} style={{ textAlign: 'center' }}>No data found</td> : ''
											}

										</tbody>
									</table>
								</Card>
							</div>
							<div className="col-md-6">
								<h2>My Transactions</h2>
								<Card style={{ border: '1px solid black',height:TopupData && TopupData.length > 5 ? '360px' :'',overflowY:'scroll'  }}>
										<table className="table check-tbl tbl-border ">
											<thead>
												<tr>
													<th>Amount</th>
													<th>Transactions Type</th>
													<th style={{ textAlign: 'center' }}>Date</th>

												</tr>
											</thead>
											<tbody>

												{(TopupData && TopupData.length > 0) ? TopupData.map((giftcard, key) => {
													return <><tr className="alert">
														<td className="product-item-price">$ {parseFloat(giftcard.amount).toFixed(2)}</td>
														<td className="product-item-price"> {giftcard.redeem_flag == 1 ? "Redeem Code" : 'Dip Wallet'}</td>
														<td className="product-item-price" style={{ textAlign: 'center' }}> {(timezone) ? moment(giftcard.created_at).tz(timezone).format('MM-DD-YYYY hh:mm A') : moment(giftcard.created_at).tz('America/Chicago').format('MM-DD-YYYY hh:mm A')}</td>
														{/* {(timezone) ? moment(giftcard.created_at).tz(timezone).format('MM-DD-YYYY hh:mm A') : moment(giftcard.created_at).tz('America/Chicago').format('MM-DD-YYYY hh:mm A')} */}

													</tr></>
												}) : ''
												}

												{
													(TopupData && TopupData.length == 0) ? <td colSpan={4} style={{ textAlign: 'center' }}>No data found</td> : ''
												}

											</tbody>
										</table>
								</Card>
							</div>
						</div>
						{/* </div> */}
					</div>
				</div >
				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showRedeemModal} onHide={onCloseRedeem}>
					<Modal.Header closeButton>
						<Modal.Title>Redeem Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label >Coupon Code<span style={{ color: 'red' }}>*</span></label>
							<input
								className="form-control"
								id="signin-email"
								placeholder="Please enter coupon code"
								type="text"
								value={redeemDetails.coupon_code}
								autoComplete="off"
								onChange={val => {
									if (val.target.value.length) {
										seterrorMessage('')
									} else {
										seterrorMessage('Please enter the coupon code')
									}
									setRedeemDetails({ ...redeemDetails, coupon_code: val.target.value });
								}}
							/>
							<span style={{ color: 'red' }}>{errorMessage}</span>
						</div>

						<Button className="btn btn-primary btn-block1" href="javascript:void(0);" onClick={AddWalletAmount}
						>Redeem</Button>&nbsp;&nbsp;

						<Button variant="secondary" onClick={onCloseRedeem}>
							Close
						</Button>
					</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>
			</div >
			<Footer />
		</>

	)
}

export default ViewHistory;
