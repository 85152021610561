import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2'

function MobileLoader() {

    useEffect(() => {
        Swal.fire({
            title: 'Order confirming..!',
            icon: 'info',
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false,
            footer: 'Please wait Redirecting to Velvet Cream...',
        })
    }, [])

    return (
        <div>
        </div>
    )
}

export default MobileLoader
