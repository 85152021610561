import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { addfavouriteProductCart, favouriteData, favouriteSelector } from '../../store/reducer/favourite';
import moment from 'moment';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { assestURL, secretKey } from '../../services/config';
// var img = require('./../../images/banner/bnr3.jpg');
import { Input, Space } from 'antd';
import { API_STATUS } from '../../utils/constants';
import { toast } from 'react-hot-toast';
// import { allFavProductsData, ProductSelector } from '../../store/reducer/product';

function Favourite(){
	let temp_token = localStorage.getItem('temporaryToken');
	const dispatch = useDispatch();
	const {favDatas, favloading, favmessage} = useSelector(favouriteSelector);
    const [defaultPrice, setDefaultPrice] = useState([]);
	const { Search } = Input;
	const aboutUsDispatch = () => {
        dispatch(
            favouriteData({})
        );
    };

	useEffect(() => {
        aboutUsDispatch()
    }, [])

	useEffect(() => {
        if(favDatas){

			// let price = favDatas.prices.filter( (result, index) => {
			// 	return result.product_id==10 && result.itemsize_id==1
			// });
			// console.log(price, 'price');
			setDefaultPrice([...favDatas.prices])
		}
    }, [favDatas])

	const onSearch = (searchQuery) => {
		dispatch(
			favouriteData({
				query: searchQuery,
			})
		);
	}

	const addFavtoCart = (id) => {

		dispatch(
			addfavouriteProductCart({
				productid : id,
				temp_token: temp_token
			})
		);
	}

	useEffect(()=>{
		if (favloading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success(favmessage);
			setTimeout(() => {
				window.location.href = '/cartlist'
			}, 1000);
		} else if (favloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.success(favmessage);
		}
		
	}, [favloading])

	console.log(favDatas, 'favDatas');
	console.log(defaultPrice, 'favDatas');
		return(
			<>
			<Header />
			
			<div className="section-full content-inner1" >

<div className="container">

	<div className="row">
	
		<div className='col-6'>
		<h2>My Favorites</h2>
		{/* <h3>Products</h3> */}
		{/* {
			(allProductDatas && allProductDatas.count) ? <p>Showing {allProductDatas.count} products</p> : '' 
		} */}
		</div>
		<div className='col-6'>
		<Search className='mt-4 product-search' placeholder="Search by product name"  size="large" onSearch={onSearch} enterButton />	
		</div>
		<div className="col-lg-12">
		<p>Showing <strong>{ (favDatas && (favDatas.data && favDatas.data.length) ) ? favDatas.data.length : 0 } </strong>favorites</p>
			{favDatas && favDatas.data.map((result, tindex) => (
				<div className='card p-5 my-4'>
					<div className='row'>
						<div className='col-2'>
							
						<img style={{width:'154px',height:'115px',objectFit:'cover'}} className="product-item-img " src={assestURL + result.image} alt=""  />
						
						</div>
						<div className='col-5'>
						{
								(result.reward == '1') ?
							<a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize:'25px' }}>{result.name}</span></a>
							: <a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize:'25px' }}>{result.name}</span></a>
						}
							
							{/* <span className='ml-5'><strong>Prices :</strong> { (result.name == 1)  ? result.name : '$' + parseFloat(result.name).toFixed(2) }</span> */}
							<p>{result.description}</p>
							{ (result.default.length) ? 
							<p><strong>Default:</strong> { result.default.replace(",", ', ') }</p> : ""}
						</div>
						<div className='col-2'>
						<span ><strong>{(result.reward == 1) ? 'Points' : 'Price'} :</strong> { result.reward == '1' ?
								result.selectedPrice + ' pts' : '$' + parseFloat(result.selectedPrice).toFixed(2)
							}</span><br></br>
							<span ><strong>Size :</strong> {result.code}</span>
							{/* <br/><span><strong>{(result.reward == 1) ? 'Points' : 'Price' }:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span> */}
							
						</div>
						<div className='col-3 text-center'>
						
							<h3><p className='ml-3' style={{ paddingRight: '40px', marginTop: '20px' }}> <strong>{ result.reward == '1' ?
								result.total_cost + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)
							}</strong></p></h3>
							{
								(result.reward == 1) ? <a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btn-primary btn-lg btn-block">View Details</a> :
								<a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btn-primary btn-lg btn-block">View Details</a>
							}
						
						</div>
					</div>

				</div>
			))}

			{favDatas && (favDatas.data && favDatas.data.length == 0) ? <div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
								<h3>No Products Found</h3>
								<div className="form-group">
									<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
								</div>
							</div> : ''}
		</div>
	</div>
</div>

</div>
			
			<Footer  />
			</>
		)
	}


export default Favourite;