import React, { useEffect, useState, useRef } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { userData, profileData, RedeemCouponData, giftcardData, profileSelector } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';
import { assestURL, secretKey } from '../../services/config';
import { CardType, generalSelector } from "../../store/reducer/general";
import { addToCoupon, newPayment, orderSelector } from '../../store/reducer/order';
import Swal from 'sweetalert2'
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import { Button, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from "copy-to-clipboard";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

var img3 = require('./../../images/giftcard.png');


function Sendcoupon() {
	const formValidator = useRef(new SimpleReactValidator());
	const formValidator1 = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	const { id } = useParams()

	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const [loginDetails, setLoginDetails] = useState({ email: "", password: "", submitted: false, loading: false });
	const [forgotDetails, setForgotDetails] = useState({ email: "" });
	const [PasswordType, setPasswordType] = useState('password');
	const [ForgotFormSubmit, setForgotFormSubmit] = useState(false)
	const [cardtypeImage, setcardtypeImage] = useState('')
	const { cardtypeDatas, cardtypeLoading } = useSelector(generalSelector);
	if (!userDetails) {
		// dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const [formDetails, setformDetails] = useState({ email: '', amount: '', message: '' });
	const [paymentDetails, setpaymentDetails] = useState({ cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' });
	const { giftDatas, userDatas, redeemDatas, redeemloading, userloading, profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [AddForm1Submit, setAddForm1Submit] = useState(false)
	const { paymentloading, paymentMessage, paymentDatas, addToCouponLoading } = useSelector(orderSelector);
	const [displayFakeData, setdisplayFakeData] = useState(0)
	const [walletAmount, setwalletAmount] = useState(0)
	const [TransactionID, setTransactionID] = useState(null);
	const [showHistory, setshowHistory] = useState(false);
	const [showRedeemModal, setshowRedeemModal] = useState(false);
	const [redeemDetails, setRedeemDetails] = useState({ coupon_code: "" });
	const [errorMessage, seterrorMessage] = useState('');

	const [showPurchasedModal, setshowPurchasedModal] = useState(false);
	const [copyText, setCopyText] = useState('HGkHKGkBMHFhFyryhfjgfHTFHfhgFTFtFTf');
	const [copyButton, setcopyButton] = useState(false);

	console.log(showRedeemModal, 'showRedeemModal');
	const handleCopyText = (e) => {
		setCopyText(e.target.value);
	}

	const copyToClipboard = () => {
		const text = ` I'm sharing you Velvet Cream Gift coupon with you. \n\n Coupon code: ${copyText} \n\n Click here to redeem the coupon code: https://dipmenu-website-uat.demomywebapp.com/newgiftcard/RedeemCode`;
		console.log(text, 'textt');
		copy(text);
		setcopyButton(true);
	}

	useEffect(() => {
		if (id === 'RedeemCode') {
			setshowRedeemModal(true)
		}
	}, [])


	const submitHandler = (e) => {
		e.preventDefault();
		// alert('ok');
		// history1.push("/");
	};
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {

		// e.preventDefault();
		setAddFormSubmit(true);
		setAddForm1Submit(false);

		if (formValidator.current.allValid()) {
			// if (formDetails.email == userDetails.email) {
			// 	toast.dismiss();
			// 	toast.error('Please use Dip Wallet to Topup your Account')
			// 	return false;
			// }

			if (formDetails.amount == 0 || formDetails.amount < 0) {
				toast.dismiss();
				toast.error('Amount should be greater than 0.')
				return false;
			}
			if (activeTab !== tab) setActiveTab(tab);
		}

	}

	const onCloseRedeem = () => {
		console.log('function==');
		if (id === 'RedeemCode') {
			window.location.href = '/newgiftcard'
		} else {
			seterrorMessage('')
			setshowRedeemModal(false);
			window.scroll({ top: 0, left: 0, behavior: "smooth", });
		}
	}
	const onClosePurchase = () => {
		seterrorMessage('')
		setshowPurchasedModal(false);
		window.scroll({ top: 0, left: 0, behavior: "smooth", });
	}

	const showRedeemPopup = () => {
		setshowRedeemModal(true);
	}

	const AddWalletAmount = () => {

		if (redeemDetails.coupon_code.trim() == '') {
			seterrorMessage('Please enter the coupon code');
			return false;
		}

		let postData = { redeem_code: redeemDetails.coupon_code }

		dispatch(
			RedeemCouponData({ postData })
		);

	};



	const BuyNewCard = (e) => {
		// e.preventDefault();
		setAddFormSubmit(false);
		setAddForm1Submit(true);


		if (formValidator1.current.allValid()) {

			let paymentData = { channel: 'WEB', autoCapture: true, processAsSale: false }

			paymentData.order = {
				orderId: 'buy',
				totalAmount: parseFloat(formDetails.amount).toFixed(2),
				description: 'buy gift cards for the user',
				currency: 'USD',
				orderBreakdown: {
					subtotalAmount: parseFloat(formDetails.amount).toFixed(2),
					items: [{ "unitPrice": formDetails.amount, "quantity": 1 }]
				}
			}
			paymentData.customerAccount = {
				payloadType: "KEYED",
				accountType: "CHECKING",
				cardDetails: {
					dataFormat: "PLAIN_TEXT",
					cardNumber: paymentDetails.cc_number.replaceAll(" ", ""),
					cvv: paymentDetails.cc_cvc,
					expiryDate: paymentDetails.cc_month.replace('/', '')
				}
			}

			dispatch(newPayment({ postData: paymentData }));

		}
	};

	const getProfileDispatch = (loginUser) => { dispatch(profileData({ user_id: loginUser })) };
	const userDispatch = (loginUser) => { dispatch(userData({ user_id: loginUser })) };
	const giftCardDispatch = (loginUser) => { dispatch(giftcardData({ user_id: loginUser })) };

	useEffect(() => {
		giftCardDispatch(loginUser)
		getProfileDispatch(loginUser)
		userDispatch(loginUser)
	}, [])

	const displayFakeNumber = () => {
		if (formDetails.cc_number && formDetails.cc_number.length > 4)
			setdisplayFakeData(1)
	}

	const displayNumber = () => {
		setdisplayFakeData(0)
	}


	useEffect(() => {
		if (cardtypeDatas) {

			console.log(cardtypeDatas, 'cardtypeDatas');
			if (cardtypeLoading === API_STATUS.FULFILLED) {
				if (cardtypeDatas.status == true) {

					if (cardtypeDatas?.result.cardType == 'VISA' || cardtypeDatas?.result.cardType == 'VISA DEBIT') {
						setcardtypeImage(require('../../images/visa.jpg'));
					}
					if (cardtypeDatas?.result.cardType === 'ELECTRON') {
						setcardtypeImage(require('../../images/electron.jpeg'));
					}
					if (cardtypeDatas?.result.cardType === 'MAESTRO') {
						setcardtypeImage(require('../../images/Maestro.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DISCOVER') {
						setcardtypeImage(require('../../images/discover.png'));
					}
					if (cardtypeDatas?.result.cardType === 'JCB') {
						setcardtypeImage(require('../../images/jcb.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}

					if (cardtypeDatas?.result?.cardType == undefined) {
						setcardtypeImage('')
					}
				}
			}

			if (cardtypeLoading === API_STATUS.PENDING) {
				toast.dismiss();
			}

		}
	}, [cardtypeDatas])

	useEffect(() => {
		formValidator.current.showMessages()
		formValidator1.current.showMessages()
	}, [])


	useEffect(() => {
		if (userDatas) {
			setwalletAmount(userDatas?.data[0].wallet)
			console.log(userDatas, 'userDatas');
		}
	}, [userDatas])

	useEffect(() => {
		if (giftDatas) {
			console.log(giftDatas, 'giftDatas');
		}

	}, [giftDatas])

	const cc_format = (value) => {
		const v = value
			.replace(/\s+/g, "")
			.replace(/[^0-9]/gi, "")
			.substr(0, 16);
		const parts = [];

		for (let i = 0; i < v.length; i += 4) {
			parts.push(v.substr(i, 4));
		}

		return parts.length > 1 ? parts.join(" ") : value;
	}

	useEffect(() => {
		if (paymentloading === API_STATUS.FULFILLED) {
			if (paymentDatas) {
				if (paymentDatas.status === false) {
					Swal.fire({
						title: 'Payment Failed!',
						icon: 'error',
						text: paymentDatas.message,
						cancelButtonColor: '#d33',
						cancelButtonText: 'Retry Payment',
						showConfirmButton: false,
						showCancelButton: true
					}).then((result) => {
						if (result.dismiss) {
							setpaymentDetails({ ...formDetails, cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' })
							setdisplayFakeData(0)
							setcardtypeImage('')
						}
					})
					// return false;
				}
				else {
					setTransactionID(paymentDatas.data.uniqueReference)

					let newCoupon = {
						amount: formDetails.amount,
						// email: formDetails.email,
						code: paymentDatas.data.uniqueReference,
						redeem_flag: 0,
						message: formDetails.message

					}
					dispatch(addToCoupon({ postData: newCoupon }));

					// setshowModal(true);
					Swal.fire({
						title: 'Payment Successful!',
						icon: 'success',
						html: `Payment Successful. Email has been sent successfully.`,
						// timer: 2000,
						timerProgressBar: true,
						// footer: 'Please wait Confirming your Order...',
						// showConfirmButton: false,
						confirmButtonText: 'Close',
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.href = '/newgiftcard'
						}
					})

				}


			}
			// window.scrollTo(0, 0)
		} else if (paymentloading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (paymentloading === API_STATUS.REJECTED) {

		}
	}, [paymentloading, paymentDatas])

	useEffect(() => {
		console.log(redeemloading, "redeemloading")
		if (redeemloading === API_STATUS.FULFILLED) {

			if (redeemDatas.status == true) {
				setshowRedeemModal(false)
				toast.dismiss();
				toast.success('Coupon amount added to your wallet successfully.!');

				window.setTimeout(function () {
					window.location.reload()
				}, 2000);
			} else {
				setshowRedeemModal(true)
				// console.log(re);
				seterrorMessage(redeemDatas.message);
				setRedeemDetails({ ...redeemDetails, coupon_code: '' })
			}

		}
		if (redeemloading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (redeemloading === API_STATUS.REJECTED) {
			toast.dismiss();
			// setshowRedeemModal(false)
			seterrorMessage('Something went wrong.');
			setRedeemDetails({ ...redeemDetails, coupon_code: '' })

		}
	}, [redeemloading]);

	useEffect(() => {
		console.log(addToCouponLoading, 'addToCouponLoading');
		if (addToCouponLoading === API_STATUS.FULFILLED) {
			setshowPurchasedModal(true)
		}
		if (addToCouponLoading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (addToCouponLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			seterrorMessage('Something went wrong.');
		}
	}, [addToCouponLoading])

	const submitGoogle = (e) => {
		if (formDetails.amount <= 0) {
			e.preventDefault();
			toast.error('Amount is required.!')
			return false;
		}
	}

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="section-full mt-4 shop-account">
					<div className="container">
						<div className="row">
							{/* <div className="col-lg-2"> */}
							{/* <h3 className="m-b5">Buy Gift Card </h3> */}
							{/* </div> */}
							<div className="col-lg-9" style={{ paddingBottom: '10px' }}>

								<button class={showHistory ? "btn btn-primary btn-xs" : "btn btn-success btn-xs"} style={{ marginRight: '10px' }} onClick={val => {
									setshowHistory(false)
								}}>Buy Gift Card</button>
								<a href="#" class="btn btn-primary btn-xs" onClick={showRedeemPopup} style={{ marginRight: '10px' }}>Redeem Code</a>&nbsp;
								<a href="/dipwallet" class="btn btn-primary btn-xs" style={{ marginRight: '10px' }}>Dip Wallet</a>&nbsp;
								<a href={'/viewhistory'} class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} >View History</a>

								{/* </div>
					<div className="col-lg-4" style={{ paddingTop: '0.5rem', paddingLeft: '16rem'}}> */}

								{/* </div> */}
								{/* </div> */}
							</div>
							<div className="col-lg-3">
								<h3 className="m-b5 pull-right">Balance: $ {parseFloat(walletAmount).toFixed(2)}  </h3>
							</div>
						</div>
						<div className="row" style={(showHistory) ? { display: 'none' } : { display: 'block' }}>
							<div className="col-lg-12">
								<div className="p-a30 border-1 m-auto radius-sm">
									<TabContent activeTab={activeTab}>
										<TabPane tabId="1">
											<div className='row'>
												<div className='col-md-6' style={{ paddingBottom: '25px', height: 'fit-content' }}>
													{(img3) ? <img src={img3}></img> : ""}
												</div>
												<div className='col-md-6'>
													{/* <div className="form-group col-12">
														<label>Email <span style={{ color: 'red' }}>*</span></label>
														<input
															className="form-control"
															name='email'
															placeholder="Enter the Email"
															type="email"
															value={formDetails.email}
															autoComplete="off"
															onChange={val => {
																setformDetails({ ...formDetails, email: val.target.value });
															}}
														/>
														{formValidator.current.message(
															'email',
															formDetails.email,
															'required|email',
															{
																className: `invalid_feedback 
														${AddFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter the email',
																	email: 'Please Enter the valid email',
																}
															}
														)}
													</div> */}
													<div className="form-group col-12">
														<label> Amount <span style={{ color: 'red' }}>*</span></label>
														<input
															className="form-control"
															name='amount'
															placeholder="Enter the Amount"
															type="text"
															value={formDetails.amount}
															autoComplete="off"
															onChange={val => {

																if (val.target.value.length > 15) {
																	return false;
																}
																var numbers = /^[0-9]*\.?\d*$/;

																if (val.target.value.match(numbers) || val.target.value == '') {
																	setformDetails({ ...formDetails, amount: val.target.value });
																} else {
																	return false;
																}
															}}
														/>
														{formValidator.current.message(
															'amount',
															formDetails.amount,
															'required',
															{
																className: `invalid_feedback 
														${AddFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter the amount',
																}
															}
														)}
													</div>
													<div className="form-group col-12">
														<label>Message </label>
														<textarea
															className='form-control'
															name='amount'
															placeholder="Enter the message"
															value={formDetails.message}
															rows={4} cols={38}
															style={{ paddingLeft: '1rem' }}
															onChange={val => {
																setformDetails({ ...formDetails, message: val.target.value });
															}}
														/>
													</div>
													<div className="form-group col-12">
														{/* <button className={classnames({ active: activeTab === '1' }) + ' btn blue'} 
												onClick={() => { toggle('2'); }}>Proceed to Pay</button>&nbsp;
												<a href={'#'} className='btn btn-danger' style={{ width:'30%' }}>Back</a> */}
														{/* <div className='col-md-4' style={{ maxWidth: '10%' }}> */}
														<form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
															<input type="hidden" name="TERMINALID" value={process.env.REACT_APP_TERMINAL} />
															<input type="hidden" name="ORDERID" value={moment().format('YYYY-MM-DD HHmmss')} />
															<input type="hidden" name="CURRENCY" value="USD" />
															<input type="hidden" name="PAGES" value="BUYGIFTCARD" />
															{/* <input type="hidden" name="EMAIL" value={formDetails.email} /> */}
															<input type="hidden" name="MESSAGE" value={formDetails.message} />
															<input type="hidden" name="AMOUNT" value={parseFloat(formDetails.amount).toFixed(2)} />
															<input type="hidden" name="DATETIME" value={moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
															<input type="hidden" name="HASH" value={CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${moment().format("YYYY-MM-DD HHmmss")}:${parseFloat(formDetails.amount).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
															{/* <button className='btn btn-success' type="submit" onClick={submitGoogle} value="Pay with Google">Pay with Google</button> */}
															<input className={(formDetails.amount > 0) ? 'btn button-warning btn-block' : 'btn btn-danger btn-block'} type={(formDetails.amount > 0) ? "submit" : "button"} onClick={toggle} value={(formDetails.amount > 0) ? `Pay $ ${parseFloat(formDetails.amount).toFixed(2)}` : 'Enter Amount To Pay'} />
														</form>
														{/* </div> */}
													</div>
												</div>
											</div>
										</TabPane>
										{/* <TabPane tabId="2">
											<div className="form-group row">
												<div className='col-md-8'>
													<h4>Payment Method</h4>
												</div>
												<div className='col-md-4' style={{ maxWidth: '10%' }}>
													<form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
														<input type="hidden" name="TERMINALID" value={process.env.REACT_APP_TERMINAL} />
														<input type="hidden" name="ORDERID" value={moment().format('YYYY-MM-DD HHmmss')} />
														<input type="hidden" name="CURRENCY" value="USD" />
														<input type="hidden" name="PAGES" value="BUYGIFTCARD" />
														<input type="hidden" name="EMAIL" value={formDetails.email} />
														<input type="hidden" name="MESSAGE" value={formDetails.message} />
														<input type="hidden" name="AMOUNT" value={parseFloat(formDetails.amount).toFixed(2)} />
														<input type="hidden" name="DATETIME" value={moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
														<input type="hidden" name="HASH" value={CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${moment().format("YYYY-MM-DD HHmmss")}:${parseFloat(formDetails.amount).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
														<button className='btn btn-success' type="submit" onClick={submitGoogle} value="Pay with Google">Pay with Google</button>
													</form>
												</div>
											</div>

											<form id="forgot-password"
												onSubmit={(e) => submitHandler(e)}
												className={activeTab === '2' ? 'tab-pane fade col-12 p-a0  show' : ' tab-pane fade col-12 p-a0 '}>

												<div className='row'>
													<div className="form-group col-6">
														<label>Card Holder Name <span style={{ color: 'red' }}>*</span> </label>
														<input type="text" value={paymentDetails.cc_name} className="form-control"
															placeholder="Card Holder Name"
															name='cc_name'
															onChange={val => {

																var matches = val.target.value.match(/\d+/g);
																if (matches != null) {
																	return false
																}

																setpaymentDetails({ ...paymentDetails, cc_name: val.target.value });
															}}
														/>
														{formValidator1.current.message(
															'cc_name',
															paymentDetails.cc_name,
															'required|alpha_space',
															{
																className: `invalid_feedback 
														  ${AddForm1Submit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter Name',
																	alpha_space: 'Please Enter Valid Name',
																}
															}
														)}
													</div>
													<div className="form-group col-6">
														<label>Card Number <span style={{ color: 'red' }}>*</span> </label>

														<input type="text" value={(displayFakeData == 0 || paymentDetails.cc_number == '') ? cc_format(paymentDetails.cc_number) : ('****' + paymentDetails.cc_number.substr(-4))} onBlur={displayFakeNumber} onFocus={displayNumber} className="form-control"
															placeholder="Card number"
															name='cc_number'
															onPaste={(e) => {
																e.preventDefault()
																return false;
															}}
															onCopy={(e) => {
																e.preventDefault()
																return false;
															}}
															onChange={val => {

																let name = val.target.value;
																var format = /^[0-9\s]*$/;
																if (format.test(name)) {
																	setpaymentDetails({ ...paymentDetails, cc_number: val.target.value });

																} else {
																	return false
																}

																console.log(val.target.value, 'val.target.value');
																let type = val.target.value.replaceAll(" ", "")
																if (type.length == 16) {
																	let type = val.target.value.replaceAll(" ", "")
																	dispatch(
																		CardType({ card_id: type })
																	)
																} else {
																	setcardtypeImage('')
																}


																// if (name.length > 16) {
																// 	return false
																// }

																// var regExp = /[a-zA-Z]/g;

																// 	const joy = val.target.value.toString().replace(/\d{2}(?=.)/g, '$& ');
																// joy.join(' ');
																//let name = val.target.value.toString().replace(/.{1,4}(?=(.{4})+$)/g, '$& ')

															}}
														/>
														{formValidator1.current.message(
															'cc_number',
															paymentDetails.cc_number,
															'required|card_num',
															{
																className: `invalid_feedback 
														  ${AddForm1Submit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter Card Number',
																	card_num: 'Please Enter Valid Card Number',
																}
															}
														)}
													</div>
													<div className="form-group col-6">
														<label>Expiration Month & Year <span style={{ color: 'red' }}>*</span> </label>

														<input type="text" value={paymentDetails.cc_month} className="form-control"
															placeholder="MM/YY"
															name='cc_month'
															onChange={val => {

																if (val.target.value == '/') {
																	setpaymentDetails({ ...paymentDetails, cc_month: '' });
																	return false
																}
																console.log(val.target.value, val.target.value.length, new Date().getFullYear().toString().substr(-2), 'val.target.value');
																if (val.target.value.length > 5) {
																	return false;
																}
																if (val.target.value.length) {
																	let data = val.target.value.split("/")

																	if (data[0] > 12) {
																		// if (data[0] > 12 || ( data[0].length == 1 && data[0] < 1)) {
																		return false
																	}
																	let year = new Date().getFullYear().toString().substr(-2);
																	console.log(year, 'year');

																	if (data.length > 1) {
																		if (data[1].length == 2) {
																			if (year === data[1] || (data[1] <= (Number(year) + Number(20)))) {

																			} else {
																				return false
																			}
																		}

																		if (data[1].length > 2) {
																			return false
																		}
																	}
																	//  && (Number(data[1])) > new Date().getFullYear().toString().substr(-2)){
																	// 	return false;
																	// }

																}

																var regExp = /[a-zA-Z]/g;
																if (regExp.test(val.target.value)) {
																	return false
																}


																if (val.target.value.length === 2 && val.target.value > 12) {
																	return false;
																}
																console.log(val.target.value.indexOf('/'), 'val.target.value--1');
																if (val.target.value.length === 2) {
																	if (val.target.value.indexOf('/') !== -1) {
																		let replacevalue = val.target.value.replace("/", '')
																		if (replacevalue > 0 && val.target.value != '1/') {
																			setpaymentDetails({ ...paymentDetails, cc_month: "0" + val.target.value });
																		} else {
																			setpaymentDetails({ ...paymentDetails, cc_month: val.target.value });
																		}

																		return false
																	}
																	val.target.value = val.target.value + '/'
																}
																else {
																	// if (val.target.value.length === 3 && val.target.value.charAt(2) === '/')
																	// val.target.value = val.target.value.replace('/', '');
																}

																if (val.target.value.length === 4 && val.target.value.charAt(3) < 2) {
																	return false
																}
																if (val.target.value.length === 5) {
																	let third = val.target.value.charAt(3)
																	let fourth = val.target.value.charAt(4)
																	let numbers = third + fourth
																	console.log(numbers, 'numbers-');
																	let currentyear = new Date().getFullYear().toString().substr(-2)
																	if (Number(numbers) < currentyear)
																		return false
																}
																let updateValue = val.target.value;

																if (updateValue.length === 1 && (updateValue != 1 && updateValue != 0)) {
																	updateValue = "0" + updateValue;
																	updateValue = updateValue + '/'
																}
																// console.log(val.target.value, 'val.target.value---2');
																setpaymentDetails({ ...paymentDetails, cc_month: updateValue });
															}}
														/>
														{formValidator1.current.message(
															'cc_month',
															paymentDetails.cc_month,
															'required|card_exp',
															{
																className: `invalid_feedback 
														  ${AddForm1Submit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter Card Expiry Month & Year',
																	card_exp: 'Please Enter Valid Month & Year (MM/YY)',
																}
															}
														)}
													</div>
													<div className="form-group col-6">
														<label>CVV Number <span style={{ color: 'red' }}>*</span> </label>

														<input type="password" value={paymentDetails.cc_cvc} className="form-control"
															placeholder="Card Verification Number"
															name='cc_cvc'
															onChange={val => {
																if (val.target.value.length > 4) {
																	return false;
																}
																var numbers = /^[0-9]+$/;
																if (val.target.value.match(numbers) || val.target.value == '') {
																	setpaymentDetails({ ...paymentDetails, cc_cvc: val.target.value });
																} else {
																	return false;
																}

															}}
														/>
														{formValidator1.current.message(
															'cc_cvc',
															paymentDetails.cc_cvc,
															'required|integer|min:3|max:7',
															{
																className: `invalid_feedback 
														  ${AddForm1Submit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter CVV',
																	integer: 'Please Enter Valid Number',
																	min: 'CVV Number must be at least 3 characters',
																	max: 'CVV Number should not greater than 7 characters',
																}
															}
														)}

													</div>
													<div className="form-group col-6">
														<button type="button" className="btn btn-success" onClick={BuyNewCard} >Pay $ {parseFloat(formDetails.amount).toFixed(2)}</button> &nbsp;&nbsp;
														<button type="button" className="btn btn-danger" onClick={() => { toggle('1'); }}>Back</button>

													</div>
												</div>
											</form>

										</TabPane> */}
									</TabContent>
								</div>
							</div>
						</div><br></br>

					</div>
				</div >
				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showRedeemModal} onHide={onCloseRedeem}>
					<Modal.Header closeButton>
						<Modal.Title>Redeem Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label >Coupon Code<span style={{ color: 'red' }}>*</span></label>
							<input
								className="form-control"
								id="signin-email"
								placeholder="Please enter coupon code"
								type="text"
								value={redeemDetails.coupon_code}
								autoComplete="off"
								onChange={val => {
									if (val.target.value.length) {
										seterrorMessage('')
									} else {
										seterrorMessage('Please enter the coupon code')
									}
									setRedeemDetails({ ...redeemDetails, coupon_code: val.target.value });
								}}
							/>
							<span style={{ color: 'red' }}>{errorMessage}</span>
						</div>

						<Button className="btn btn-primary btn-block1" href="javascript:void(0);" onClick={AddWalletAmount}
						>Redeem</Button>&nbsp;&nbsp;

						<Button variant="secondary" onClick={onCloseRedeem}>
							Close
						</Button>
					</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="lg" show={showPurchasedModal} onHide={onClosePurchase}>

					{/* <Modal  className='dialog-screen modal-dialog1'  size="lg" show={showPurchasedModal} onHide={onClosePurchase}> */}
					<Modal.Header style={{ background: '#c1272d' }} closeButton>
						<Modal.Title  >Purchased Gift Coupon</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label><center >Gift Coupon purchased successfully !</center><br />
								Copy and share your loved one<span style={{ color: 'red' }}></span></label>
						</div>
						<div class="row">
							<div class="col-md-8">
								<div className="form-group" >
									{/* <label >Here your purchased coupon code . Copy and share your loved one<span style={{ color: 'red' }}></span></label> */}
									<input
										className="form-control"
										id="signin-email"
										placeholder="Please enter coupon code"
										type="text"
										value={copyText}
										onChange={handleCopyText}
										autoComplete="off"
										readOnly

									/>

								</div>
							</div>
							<div class="col-md-2">
								<button class={copyButton ? "btn btn-success btn-xs" : "btn btn-primary btn-xs"} href="javascript:void(0);" onClick={copyToClipboard}
								>{copyButton ? 'Copied' : 'Copy'}</button>

							</div>

						</div>



						{/* <Button className="btn btn-primary btn-block1" href="javascript:void(0);" onClick={AddWalletAmount}
						>Copy</Button>&nbsp;&nbsp; */}


					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={onClosePurchase}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div >
			<Footer />
		</>

	)
}

export default Sendcoupon;
