import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import 'moment-timezone';
import { addToWallet, addToCoupon, orderSelector, newPayment, UpdateOrder, OrderPDF } from "../../store/reducer/order";
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { profileSelector } from "../../store/reducer/profile";
import copy from "copy-to-clipboard";
var giftcardImg = require('./../../images/gallery/gift-card.png');
var img1 = require('./../../images/background/bg5.jpg');

function PaymentReceipt() {
	// render(){
	// window.scrollTo(0, 0)
	// let { ORDERID, AMOUNT, RESPONSECODE, UNIQUEREF, CARDTYPE } = useParams();
	const location = useLocation();
	let params = queryString.parse(location.search);
	const dispatch = useDispatch();
	const [orderTotal, setOrderTotal] = useState(0);
	const [timezone, setTimezone] = useState('America/Chicago');
	const { recentDatas, reorderDatas, reorderloading, addToCouponLoading, couponCode } = useSelector(orderSelector);
	const [OrderDetails, setOrderDetails] = useState('');
	const [showModal, setshowModal] = useState(false);
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const [loginUser] = useState(userDetails?.id);
	const [showPurchasedModal, setshowPurchasedModal] = useState(false);
	const [errorMessage, seterrorMessage] = useState('');
	const [copyButton, setcopyButton] = useState(false);

	let alreadyLogged = localStorage.getItem('isAuthenticated');
	// let mobile = localStorage.getItem('mobile')
	console.log(alreadyLogged);
	if (!alreadyLogged) {
		// alert(window.history.back());
		toast.dismiss();
		toast.error('User not authenticated.!');
		// window.location.href = host
		// window.setTimeout(function () {
		// 	window.location.href = '/'
		// }, 550);
	}

	const onCloseCart = () => {
		setshowModal(false);
	}

	const copyToClipboard = () => {
		const text = `${params?.MESSAGE ? params?.MESSAGE : ''} I'm sharing you Velvet Cream Gift card with you. \n\n Redeem code: ${couponCode} \n\n Click here to redeem the coupon code: ${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "https://dipmenu-website-uat.demomywebapp.com/newgiftcard/RedeemCode"}`;
		console.log(text, 'textt');
		copy(text);
		setcopyButton(true);
	}

	const onClosePurchase = () => {
		// seterrorMessage('')
		setshowPurchasedModal(false);
		window.scroll({ top: 0, left: 0, behavior: "smooth", });
		window.location.href = '/viewhistory'
	}

	console.log(params, 'params=====');
	console.log(params?.PAGES, params?.PAGES == 'BUYGIFTCARD', params?.DEVICE, params?.DEVICE == 'MOBILE', 'pages===');
	useEffect(() => {
		const navigationEntries = window.performance.getEntriesByType('navigation');
		if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
			window.location.href = params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/viewhistory' : params?.PAGES == 'BUYGIFTCARD' ? '/viewhistory' : ''
		} else {
			if (params?.RESPONSECODE == 'A') {
				if (params?.PAGES == 'DIPWALLET') {
					let newgiftcard = {
						amount: params.AMOUNT,
						user_id: loginUser,
						code: params.UNIQUEREF,
						sender_id: loginUser,
						redeem_flag: 0,
						payment_status: 0,
						payment_message: 'Payment Success',
						mobile_token: (params?.DEVICE == 'MOBILE') ? params?.MOBILETOKEN : null,
					}
					dispatch(addToWallet({ postData: newgiftcard }));
				}
				if (params?.PAGES == 'ORDER') {
					let updateorderDetails = {
						transactionid: params.UNIQUEREF,
						type: 'SALE',
						payment_method: params.CARDTYPE,
						order_number: Number(params.ORDERID),
						order_status: (params?.DEVICE == 'MOBILE') ? 4 : 1,
						status_message: 'Payment Success'
					}
					dispatch(
						UpdateOrder({
							postData: updateorderDetails
						})
					)
					dispatch(OrderPDF({
						order_id: Number(params.ORDERID),
					}));
				}
				if (params?.PAGES == 'BUYGIFTCARD') {
					let newCoupon = {
						amount: params.AMOUNT,
						email: params.EMAIL,
						code: params.UNIQUEREF,
						redeem_flag: 0,
						message: params.MESSAGE,
						payment_status: 0,
						payment_message: 'Payment Success',
						mobile_token: (params?.DEVICE == 'MOBILE') ? params?.MOBILETOKEN : null,
					}
					dispatch(addToCoupon({ postData: newCoupon }));
				}

				// if (params?.DEVICE == 'MOBILE') {
				// 	window.location.href = '/mobileloader'
				// } else {
				Swal.fire({
					title: 'Payment Successful!',
					icon: 'success',
					html: `Payment Successful. Transaction ID : <strong>${params.UNIQUEREF}</strong><br>
					${params?.PAGES == 'ORDER' ? `Please pickup your order at the Front Window and have your <strong>ORDER#${params.ORDERID}</strong> ready for the dispatcher` : ''}`,
					timer: 3000,
					timerProgressBar: true,
					showConfirmButton: false,
					footer: 'Please wait Redirecting to Velvet Cream...',
				}).then((result) => {
					if (params?.DEVICE == 'MOBILE') {
						window.location.href = '/mobileloader'
					}
					if (params?.PAGES == 'BUYGIFTCARD') {
						setshowPurchasedModal(true)
					}
					else {
						window.location.href = params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/viewhistory' : '/paymentreceipt';
					}
				})
				// }
				// setTimeout(() => {
				// 	window.location.href = params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/dipwallet' : params?.PAGES == 'BUYGIFTCARD' ? '/newgiftcard' : ''
				// }, 2000);
				return false
			} else if (params?.RESPONSECODE != undefined) {
				if (params?.PAGES == 'ORDER') {
					let updateorderDetails = {
						transactionid: params.UNIQUEREF,
						type: 'FAILED',
						payment_method: params.CARDTYPE,
						order_number: Number(params.ORDERID),
						order_status: 3,
						status_message: 'Payment Failed'
					}
					dispatch(
						UpdateOrder({
							postData: updateorderDetails
						})
					)
				}
				if (params?.PAGES == 'DIPWALLET') {
					let newgiftcard = {
						amount: null,
						user_id: loginUser,
						code: params.UNIQUEREF,
						sender_id: loginUser,
						redeem_flag: null,
						payment_status: 1,
						payment_message: 'Payment Failed',
						mobile_token: (params?.DEVICE == 'MOBILE') ? params?.MOBILETOKEN : null,
					}
					dispatch(addToWallet({ postData: newgiftcard }));
				}
				if (params?.PAGES == 'BUYGIFTCARD') {
					let newCoupon = {
						amount: null,
						email: params.EMAIL,
						code: params.UNIQUEREF,
						redeem_flag: null,
						message: null,
						payment_status: 1,
						payment_message: 'Payment Failed',
						mobile_token: (params?.DEVICE == 'MOBILE') ? params?.MOBILETOKEN : null,
					}
					dispatch(addToCoupon({ postData: newCoupon }));
				}
				if (params?.DEVICE == 'MOBILE') {
					window.location.href = '/mobileloader'
				} else {
					Swal.fire({
						title: 'Payment Failed!',
						icon: 'error',
						text: 'Payment Failed',
						cancelButtonColor: '#d33',
						cancelButtonText: 'Retry Payment',
						showConfirmButton: false,
						showCancelButton: true
					}).then((result) => {
						if (params?.DEVICE == 'MOBILE') {
							window.location.href = '/mobileloader'
						}
						if (result.isConfirmed) {
							window.location.href = params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/viewhistory' : '/paymentreceipt';
						} else {
							window.location.href = params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/viewhistory' : '/paymentreceipt';
						}
					})
				}
			}
		}
	}, []);

	useEffect(() => {
		console.log(addToCouponLoading, 'addToCouponLoading');
		if (addToCouponLoading === API_STATUS.FULFILLED) {
			// setshowPurchasedModal(true)
		}
		if (addToCouponLoading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (addToCouponLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			seterrorMessage('Something went wrong.');
		}
	}, [addToCouponLoading])


	return (
		(params?.DEVICE == 'MOBILE') ?
			<></>
			:
			<>
				<Header />


				<Modal className='dialog-screen modal-dialog1' size="lg" show={showModal} onHide={onCloseCart}>
					<Modal.Header closeButton>
						<Modal.Title>Re-Order Confirmation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Payment Initiated. Please wait....
					</Modal.Body>
					<Modal.Footer>


					</Modal.Footer>
				</Modal>
				<div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
					<div className="form-group">
						<Link to={params?.PAGES == 'ORDER' ? '/orderlist' : params?.PAGES == 'DIPWALLET' ? '/viewhistory' : params?.PAGES == 'BUYGIFTCARD' ? '/viewhistory' : '/'} className="btn btnhover" >Back to Website</Link>
					</div>
				</div>
				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="lg" show={showPurchasedModal} onHide={onClosePurchase}>

					{/* <Modal  className='dialog-screen modal-dialog1'  size="lg" show={showPurchasedModal} onHide={onClosePurchase}> */}
					<Modal.Header style={{ background: '#c1272d' }} closeButton>
						<Modal.Title><img src={giftcardImg} width="65px"></img> &nbsp;&nbsp;&nbsp;Purchased Gift Card</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label>Gift Card purchased successfully !<br /></label>

						</div>
						<label>Copy and share this code to your loved ones &nbsp;<i class="fa fa-gift" style={{ fontSize: '25px' }}></i>
						</label>
						<div class="row">
							<div class="col-md-8">
								<div className="form-group" >

									<input
										style={{ fontWeight: 600 }}
										className="form-control"
										id="signin-email"
										type="text"
										value={couponCode}
										autoComplete="off"
										readOnly

									/>

								</div>
							</div>
							<div class="col-md-2">
								<button class={copyButton ? "btn btn-success btn-xs" : "btn btn-primary btn-xs"} href="javascript:void(0);" onClick={copyToClipboard}
								>{copyButton ? <span>Copied <i class="fa fa-check"></i></span>
									: 'Copy'}</button>

							</div>

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={onClosePurchase}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<Footer />
			</>

	)
}


export default PaymentReceipt;