import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, } from 'reactstrap';
// import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import {  subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import {  useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import EncryptDecrypt from '../../utils/encrypt&decrypt';



const Popupss = (props) => {
    const [activeTab, setActiveTab] = useState('1');
	const {subcategoryProductDatas} = useSelector(subcategoryProductSelector);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    let {id} = useParams();
    useEffect(() => {
        if(subcategoryProductDatas){
            console.log(subcategoryProductDatas.data, 'list images');
        }
    }, subcategoryProductDatas);

    
    return (

        <div class="section-full bg-white pizza-full-menu">

			
			<div className="content-inner">
				<div className="container-fluid">
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div className="row tab-pane fade show active" style={ (subcategoryProductDatas && subcategoryProductDatas.data && subcategoryProductDatas.data.length == 1) ?  { marginTop: '-75px', width: '45%' } : { marginTop: '-75px', width: '100%' }} >
                            { (subcategoryProductDatas && subcategoryProductDatas.data) ?  subcategoryProductDatas.data.map((result, index) => {
                                    return  <div className={ ( subcategoryProductDatas.data.length == 1 && index==0) ? "dzz-col col m-b30 ok" : 'dz-col col m-b30'}>  
                                   {(result.reward == 1) ? (<a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`}>
                                   
                                    <div className="item-box shop-item style2">
                                        <div className="item-img">
                                            {
                                                (subcategoryProductDatas && subcategoryProductDatas.data) ? <img src={ assestURL + result.image } alt="" style={{ height:'300px' }}/> : <img src={require('./../../images/product/pizza/pic1.jpg')} alt="" />
                                            }
                                            {/* <img src={require('./../../images/product/pizza/pic1.jpg')} alt="" /> */}
                                        </div>
                                        <div className="item-info text-center" style={{minHeight: '125px'}}>
                                        <h4 className="item-title" style={(result.name.length > 15) ? {fontSize:'21px', overflowWrap:'anywhere'} : {fontSize:'24px', overflowWrap:'anywhere'} }>{ result.name }</h4>
                                             <h5 className="price text-primary"><span>{ subcategoryProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).points}</span></h5>
                                             {/* <h5 className="price text-primary"><span>${subcategoryProductDatas.data[index].price[0].price}</span></h5> */}
                                            <div className="cart-btn"><a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div>
                                        </div>
                                    </div>
</a>) : (<a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`}>
                                   
                                   <div className="item-box shop-item style2">
                                       <div className="item-img">
                                           {
                                               (subcategoryProductDatas && subcategoryProductDatas.data) ? <img src={ assestURL + result.image } alt="" style={{ height:'300px' }}/> : <img src={require('./../../images/product/pizza/pic1.jpg')} alt="" />
                                           }
                                           {/* <img src={require('./../../images/product/pizza/pic1.jpg')} alt="" /> */}
                                       </div>
                                       <div className="item-info text-center" style={{minHeight: '125px'}}>
                                       <h4 className="item-title" style={(result.name.length > 15) ? {fontSize:'21px', overflowWrap:'anywhere'} : {fontSize:'24px', overflowWrap:'anywhere'} }>{ result.name }</h4>
                                            <h5 className="price text-primary"><span>${ parseFloat(subcategoryProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5>
                                            {/* <h5 className="price text-primary"><span>${subcategoryProductDatas.data[index].price[0].price}</span></h5> */}
                                           <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div>
                                       </div>
                                   </div>
</a>) }
                                </div>
                                }) : ''}
							</div>	
                            {
                                (subcategoryProductDatas && subcategoryProductDatas.data && subcategoryProductDatas.data.length == 0) ? 
                                <div className="col-lg-12" style={{ textAlign: 'center' }}>
                                    <h3>No Products Found</h3>
                                    <div className="form-group">
                                        <Link to={'/'} className="btn btnhover" >Browse Products</Link>
                                    </div>
                                </div>
                                : ''
                            }
                            
						</TabPane>
					</TabContent>
				</div>	
			</div>	
        </div>
    )
}

export default Popupss;