import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { Space, Badge, Card } from 'antd';
import { Button, Modal } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import { Form } from 'react-bootstrap';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { orderCreate, cartData, ProductSelector } from '../../store/reducer/product';
import { UpdateOrder, OrderPDF, CreateOrder, newPayment, orderSelector, getbookedScheduleTime } from '../../store/reducer/order';
import { assestURL } from '../../services/config';
import { profileData, profileUpdateData, profileSelector } from "../../store/reducer/profile";
import { CardType, CheckGiftCard, generalData, generalSelector, colorList } from "../../store/reducer/general";
import { API_STATUS } from '../../utils/constants';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2'
import carsIcon from "../../images/gallery/cars1.png"
import driveBanner from "../../images/gallery/drivelogo.png"
import suvRock from "../../images/gallery/suv2.png"
import truckImg from "../../images/gallery/truck.png"
import suvImg from "../../images/gallery/suv.png"
import driveImg from "../../images/gallery/cars_Drive.png"
import CarImg from "../../images/gallery/vintage.png"
import driveIcon from "../../images/gallery/test1.png"
import driveIcons from "../../images/gallery/drive2.png"
import pickup from "../../images/gallery/pickup.png"
import pickup1 from "../../images/gallery/pickup1.png"
import carsIcons from "../../images/gallery/car2.png"
import mapIcon from "../../images/gallery/map.png"
import scheduleIcons from "../../images/gallery/sss.png"
import hereIcons from "../../images/gallery/here.png"
import hereIcone from "../../images/gallery/here1.png"
import scheduleIcon from "../../images/gallery/schedule.png"
import questionIcon from "../../images/gallery/question1.png"
import { getPosData, subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import moment from 'moment'
// import { toast } from 'react-hot-toast';
import { GetColorName } from 'hex-color-to-color-name';
import { SHA512 } from 'crypto-js';
import CryptoJS from 'crypto-js';
import giftImage from "../../images/gallery/gift-card.png"
// import GooglePayButton from '@google-pay/button-react';
var bnr = require('./../../images/banner/bnr1.jpg');

function Checkout() {
	const dispatch = useDispatch();
	const formValidator = useRef(new SimpleReactValidator());
	const driveValidator = useRef(new SimpleReactValidator());
	const scheduleValidator = useRef(new SimpleReactValidator());
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const [loginUser] = useState(userDetails && userDetails.id);
	const { cartDatas } = useSelector(ProductSelector);
	const { posDatas } = useSelector(subcategoryProductSelector);
	const [orderTotal, setOrderTotal] = useState(0);
	const [finalTotal, setfinalTotal] = useState(0);
	const [subTotal, setSubTotal] = useState(0);
	const [TotalQuantity, setTotalQuantity] = useState(0);
	const [paymentDetails, setPaymentDetails] = useState([]);
	const [checkoutItems, setcheckoutItems] = useState(0);
	const [showModal, setshowModal] = useState(false);
	const [formData, setformData] = useState({ first_name: '', last_name: '', company_name: '', address: '', landmark: '', city: '', state_country: '', zipcode: '', email: '', phone: '', order_notes: '', item_details: [], orderid: 0, orderTotal: 0, paypal_response: [], created_by: userDetails?.id });
	const [giftCoupon, setgiftCoupon] = useState('');
	const [salesTax, setsalestax] = useState(0);
	const [rewardTotal, setRewardTotal] = useState(0);
	console.log(rewardTotal, 'rewardTotal');
	console.log(orderTotal, 'orderTotal');
	const { cardtypeDatas, cardtypeLoading, generalDatas, giftcardDatas, giftcardLoading, colorLists } = useSelector(generalSelector);
	const { orderUpdateloading, orderUpdateDatas, bookedScheduleTime, scheduleTimeloading, paymentloading, paymentMessage, paymentDatas, createOrderloading, createOrderDatas, createOrderMessage } = useSelector(orderSelector);
	const [discount, setDiscount] = useState(0);
	const [disabled, setDisabled] = useState(false);
	const [Message, setMessage] = useState('');
	const [cardNameError, setcardNameError] = useState('');
	const [cardNumberError, setCardNumberError] = useState('');
	const [expiryError, setexpiryError] = useState('');
	const [cvcError, setcvcError] = useState('');
	const [ccData, setccData] = useState({ cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' });
	const [chargePayment, setchargePayment] = useState({ channel: 'WEB', terminal: 5271001, order: {}, customerAccount: {}, autoCapture: true, processAsSale: false });
	const [orderNumber, setorderNumber] = useState('');
	const [orderItems, setorderItems] = useState([]);
	const [TransactionID, setTransactionID] = useState(null);
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [showCashlessModal, setshowCashlessModal] = useState(false)
	const [showOrderMode, setshowOrderMode] = useState(false);
	const [orderModeValue, setorderModeValue] = useState('');
	const [scheduleModal, setscheduleModal] = useState(false);
	const [driveThruModal, setdriveThruModal] = useState(false);
	const [scheduleTimeSlot, setscheduleTimeSlot] = useState([])
	const [scheduleTime, setscheduleTime] = useState('')
	const [scheduleFormSubmit, setscheduleFormSubmit] = useState(false)
	const [driveFormSubmit, setdriveFormSubmit] = useState(false)
	const [pickupHere, setpickupHere] = useState('')
	const [disableTimeSlot, setdisableTimeSlot] = useState([])
	const [driveDetails, setdriveDetails] = useState({ vehicleModel: "", vehicleColor: "" });
	const { profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	console.log(profileDatas, finalTotal, orderTotal, "profileDatas");
	const [timezone, setTimezone] = useState('America/Chicago');
	const [displayFakeData, setdisplayFakeData] = useState(0)
	const [cardtypeImage, setcardtypeImage] = useState('')
	const [vehicleColors, setvehicleColors] = useState([])
	const [useWallet, setuseWallet] = useState(0);
	const [showCardData, setshowCardData] = useState(1);
	const [orderModeModal, setorderModeModal] = useState(false);
	const [scheduleSubmitted, setscheduleSubmitted] = useState(false);
	const [paymentOrder, setPaymentOrder] = useState(false);
	const [paymentOrderModal, setpaymentOrderModal] = useState(false);
	const [scheduleOrder, setscheduleOrder] = useState({ schedulePickup: "0", schedulePickup_time: "" });
	const [disclaimerText, setdisclaimerText] = useState('');
	const [cartProducts, setcartProducts] = useState([])
	console.log(cartProducts, 'cartProducts');

	console.log(finalTotal > 0, finalTotal, orderTotal, 'bookedScheduleTime-===');
	// const CreateOrder = (details) => {
	// 	let payload = { order: details, items: checkoutItems };
	// 	setshowModal(true);
	// 	//dispatch(orderCreate({ payload }));
	// };
	// const PosDispatch = () => {
	// 	dispatch(
	// 		getPosData({})
	// 	);
	// };
	console.log(finalTotal, orderTotal, 'driveDetails==');

	const setOnlineSavings = (totalAmnt) => {
		console.log(Number(generalDatas && generalDatas[0].discount), (Number(generalDatas && generalDatas[0].discount) / 100), totalAmnt, 'datataaa=')
		let savingsAmnt = (Number(totalAmnt) * (Number(generalDatas && generalDatas[0].discount) / 100))
		// let diffAmnt = (Number(savingsAmnt) - Number(totalAmnt))
		return savingsAmnt ? parseFloat(savingsAmnt).toFixed(2) : 0
	}


	const displayFakeNumber = () => {
		if (ccData.cc_number.length > 4)
			setdisplayFakeData(1)
	}

	const displayNumber = () => {
		setdisplayFakeData(0)
	}


	const getProfileDispatch = (loginUser) => {
		if (loginUser) {
			dispatch(
				profileData({ user_id: loginUser })
			);

		}
	};

	const submitGoogle = (e) => {
		if (finalTotal > 0) {
			e.preventDefault();
			toast.error('Amount is required.!')
			return false;
		}
	}

	useEffect(() => {
		getProfileDispatch(loginUser)
	}, [loginUser])

	useEffect(() => {
		dispatch(
			getbookedScheduleTime({})
		);
		dispatch(colorList({}))
	}, [])

	// useEffect(() => {
	// 	setorderModeModal(true)
	// }, [])

	useEffect(() => {
		dispatch(
			getPosData({})
		);
	}, [])


	useEffect(() => {
		if (profileDatas) {

			if (profileDatas.driveThru_details) {
				let vehicleDetails = JSON.parse(profileDatas.driveThru_details);

				setdriveDetails({
					...driveDetails,
					vehicleModel: vehicleDetails.vehicleModel,
					vehicleColor: vehicleDetails.vehicleColor,
					// vehicleNo: vehicleDetails.vehicleNo
				})
			}
		}
	}, [profileDatas])

	useEffect(() => {

	}, [displayFakeData])

	useEffect(() => {

		if (bookedScheduleTime) {

			let disableTime = bookedScheduleTime.map((item) => { return item.schedulepickup_time })

			if (disableTime.length > 0) {
				setdisableTimeSlot([...disableTime])
			}
		}
	}, [bookedScheduleTime, scheduleTimeSlot])


	useEffect(() => {

		//Schedule Pickup TimeSlot array
		if (posDatas) {
			let endtime = '';
			let starttime = ''
			let showfromtime = 0;
			let showtotime = 0

			if (posDatas.data[0].start_time !== '' && posDatas.data[0].end_time !== '' && posDatas.data[0].schedule == 1) {
				let from_array = posDatas.data[0].start_time.split(' ') // AM,PM
				let to_array = posDatas.data[0].end_time.split(' ')

				starttime = from_array[0].split(':') //01, 22
				endtime = to_array[0].split(':')

				let newtimezone = (timezone) ? timezone : 'America/Chicago'

				let frommoment = moment().tz(newtimezone).format('LT').split(' ')

				let restricttime = frommoment[0].split(':')
				let currenttime = restricttime[0];

				if (restricttime[0].length == 1) {
					currenttime = '0' + restricttime[0];
				}

				var timenow = parseInt(currenttime);
				var startnow = parseInt(starttime[0])
				var endnow = parseInt(endtime[0])

				if (from_array[1] === to_array[1]) {
					if (frommoment[1] !== from_array[1]) {
						showfromtime = 0;
						showtotime = 0;
					} else {

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1;
							// showtotime = 1;
						}

						if (startnow === timenow && (parseInt(starttime[1]) > parseInt(restricttime[1]))) {
							showfromtime = 0;
						}

						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1;
							// showfromtime = 1;

						}

						if (endnow === timenow && (parseInt(endtime[1]) < parseInt(restricttime[1]))) {
							showtotime = 0;
						}

						if (startnow !== timenow) {

							if ((startnow !== 12 && startnow < timenow)) {
								showfromtime = 1;
							}

							if (startnow === 12 && startnow > timenow) {
								showfromtime = 1;
							}
						}
						if (endnow !== timenow) {


							if (timenow !== 12 && (endnow > timenow)) {
								showtotime = 1;
							}

							if (timenow === 12 && (endnow < timenow)) {
								showtotime = 1;
							}
						}

					}
				}

				if (from_array[1] === 'AM' && to_array[1] === 'PM') {
					showfromtime = 0;
					showtotime = 0;

					if (frommoment[1] === 'AM') {
						showtotime = 1;
						if (parseInt(starttime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(starttime[1]) <= parseInt(restricttime[1])) {
								showfromtime = 1
							} else {
								showfromtime = 0
							}
						} else if (parseInt(starttime[0]) === 12) {
							showfromtime = 1
						} else {
							if (parseInt(starttime[0]) <= parseInt(restricttime[0]) && parseInt(restricttime[0]) != 12) {
								showfromtime = 1
								if (parseInt(starttime[0]) === parseInt(restricttime[0]) && parseInt(starttime[1]) <= parseInt(restricttime[1])) {
									showfromtime = 1
								}
							}
						}
					}

					if (frommoment[1] === 'PM') {
						showfromtime = 1;
						if (parseInt(endtime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(endtime[1]) >= parseInt(restricttime[1])) {
								showtotime = 1
							} else {
								showtotime = 0
							}
						} else if (parseInt(restricttime[0]) === 12) {
							showtotime = 1
						} else {
							if (parseInt(endtime[0]) >= parseInt(restricttime[0]) || parseInt(restricttime[0]) === 12) {
								showtotime = 1
								if (parseInt(endtime[0]) === parseInt(restricttime[0]) && parseInt(endtime[1]) >= parseInt(restricttime[1])) {
									showtotime = 1
								}
							}
						}
					}

				}

				if (from_array[1] === 'PM' && to_array[1] === 'AM') {

					if (frommoment[1] === 'PM' && startnow <= timenow) {
						showfromtime = 1
						showtotime = 1
						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1
						}

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 0
						}
					}

					if (frommoment[1] === 'AM' && endnow >= timenow) {
						showtotime = 1
						showfromtime = 1
						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1
						}

						if (endnow === timenow && (parseInt(endtime[1]) <= parseInt(restricttime[1]))) {
							showtotime = 0
						}
					}
				}

			}

			if (posDatas && posDatas.data[0].schedule == 1 && (showfromtime == 0 || showtotime == 0)) {
				setshowOrderMode(false)
				toast.error("Your action cannot be performed now.!")
				setTimeout(() => {
					window.location.href = '/cartlist'
				}, 2000);
			}


			let from_array = posDatas.data[0].start_time.split(' ')
			let to_array = posDatas.data[0].end_time.split(' ')

			let starttime1 = from_array.join(' ')
			let endtime1 = to_array.join(' ')

			var checkingstartTime = moment(starttime1, 'hh:mm A');
			var checkingendTime = moment(endtime1, 'hh:mm A');

			let currentCSTtime = moment().tz(timezone).format('LT')

			let currentTime = moment(currentCSTtime, 'hh:mm A')

			let validateStartTime = currentTime.isSameOrAfter(checkingstartTime)

			//****RoundOff current time and check start time-- 15mins */

			if (validateStartTime) {
				let minutes = currentTime.format('hh:mm').split(':')

				let roundOff = (Math.round(minutes[1] / 15) * 15) % 60;

				let newStartTime = moment(minutes[0] + ':' + roundOff, 'hh:mm A')

				checkingstartTime = newStartTime
			}

			//****Check end time is next day or not -- 15mins */

			if (checkingendTime.isBefore(checkingstartTime)) {
				checkingendTime.add(1, 'day');
			}

			var timeSlotarray = [];

			//****Forming 15mins slot */
			while (checkingstartTime <= checkingendTime) {
				timeSlotarray.push(new moment(checkingstartTime).format('hh:mm A'));
				checkingstartTime.add(15, 'minutes');
			}

			if (timeSlotarray.length) {
				//30mins starttime change
				timeSlotarray.splice(0, 2)
				//  timeSlotarray.shift()
				setscheduleTimeSlot([...timeSlotarray])
			}

			console.log(timeSlotarray, 'timeSlotarray');


			//*****Formatting TimeSlot In Indian Time (IST)

			// var convertStarttime = moment.utc(StarttimeAndDate);
			// var convertEndtime = moment.utc(EndtimeAndDate);


			// let checkCurrentTime = moment.utc(nowDate);

			// console.log(convertStarttime, checkCurrentTime.tz('America/Chicago'), 'checkCurrentTime');
			// // convert using the TZDB identifier for US Central time
			// convertStarttime.tz('America/Chicago');
			// convertEndtime.tz('America/Chicago');
			// checkCurrentTime.tz('America/Chicago');

			// console.log(convertStarttime, 'convertStarttime-convertStarttime');
			// // format output 
			// var startTime = moment(convertStarttime, 'hh:mm A');
			// var endTime = moment(convertEndtime, 'hh:mm A');
			// var currentTime = moment(checkCurrentTime, 'hh:mm A');
			// console.log(moment(currentTime).format('hh:mm A'), moment(startTime).format('hh:mm A'), 'checkStartTime-timeee888');


			// // if (checkStartTime) {
			// // 	startTime = currentTime
			// // }
			// // console.log(checkStartTime, startTime, 'checkStartTime');


			// if (endTime.isBefore(startTime)) {
			// 	endTime.add(1, 'day');
			// }

			// var timeStops = [];

			// while (startTime <= endTime) {
			// 	timeStops.push(new moment(startTime).format('hh:mm A'));
			// 	startTime.add(15, 'minutes');
			// }

			// if (timeStops.length) {
			// 	setscheduleTimeSlot([...timeStops])
			// }

			// console.log(timeStops, 'timeStopstimeStops');
		}
	}, [posDatas])

	useEffect(() => {

		let taxdata = 0;
		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
			taxdata = parseFloat(Number(generalDatas[0].tax) / 100).toFixed(2);
			setsalestax(taxdata)
			setdisclaimerText(generalDatas[0].disclaimer)
			console.log(taxdata, 'sales tax');
		}


		if (cartDatas) {



			let products = cartDatas.data && cartDatas.data.length && cartDatas.data.filter((item) => {
				if (item.reward == 0)
					return item
			})

			if (products && products.length) {
				setcartProducts([...products])
			}
		}


		if (cartDatas && cartDatas.data) {
			let subcost = []
			let ordercost = []
			let totalcount = []
			let checkoutData = []
			let tax = salesTax
			let rewardPoints = []
			let orderitems = []

			setsalestax(taxdata)
			cartDatas.data.forEach(function (selecteditem) {

				if (selecteditem.reward == 1) {
					rewardPoints.push(selecteditem.total_cost);
				} else {
					let itemss = { unitPrice: selecteditem.product_price, quantity: selecteditem.quantity }
					orderitems.push(itemss)
					subcost.push(selecteditem.total_cost);
					ordercost.push(selecteditem.total_cost);
				}


				totalcount.push(selecteditem.quantity);
				//subcost.push(selecteditem.total_cost);
				//ordercost.push(selecteditem.total_cost);
				checkoutData.push({ name: selecteditem.product.name, description: selecteditem.product.description, quantity: selecteditem.quantity, unit_amount: { currency_code: "USD", value: selecteditem.total_cost } });
			});

			setcheckoutItems(checkoutData);
			setformData({ ...formData, item_details: checkoutData });
			setorderItems(orderitems)

			//{
			// 	"name": "T-Shirt",
			// 	"description": "Green XL",
			// 	"quantity": "1",
			// 	"unit_amount": {
			// 		"currency_code": "USD",
			// 		"value": "100.00"
			// 	}
			// }

			if (totalcount.length) {
				setTotalQuantity(totalcount)
			}

			let subAmount;
			if (subcost.length == 1) {
				subAmount = subcost.reduce((a) => Number(a) + Number(0));
				setSubTotal(subAmount);
			} else if (subcost.length == 0) {
				subAmount = 0;
				setSubTotal(subAmount);

			} else {
				subAmount = subcost.reduce((a, b) => Number(a) + Number(b));
				setSubTotal(subAmount);
			}

			if (rewardPoints.length == 1) {
				setRewardTotal(rewardPoints.reduce((a) => Number(a) + Number(0)));
			} else if (rewardPoints.length == 0) {
				setRewardTotal(0);
			} else {
				setRewardTotal(rewardPoints.reduce((a, b) => Number(a) + Number(b)));
			}

			// console.log(taxAmount, 'aaaaa');
			let taxAmount = parseFloat(Number(subAmount) * Number(taxdata)).toFixed(2);
			let amount
			let showflag = 0
			if (subcost.length == 1) {
				amount = ordercost.reduce((a) => Number(a) + Number(0));
				console.log(amount, 'aaaaa2');
				if (Number(amount) < 0) {
					taxAmount = 0
				}
				console.log(amount, 'aaa3');
				console.log(taxAmount, 'aaa4');
				let sumAmount = Number(amount) + Number(taxAmount)
				if (sumAmount > 0) {
					showflag = 1
				}
				setOrderTotal(sumAmount);
			} else if (subcost.length == 0) {
				setOrderTotal(0);
				// setshowCardData(0)
			} else {
				amount = ordercost.reduce((a, b) => Number(a) + Number(b));
				if (Number(amount) < 0) {
					taxAmount = 0
				}
				let sumAmount = Number(amount) + Number(taxAmount)
				if (sumAmount > 0) {
					showflag = 1
				}
				setOrderTotal(sumAmount);
			}

			setshowCardData(showflag)

		} else {
			console.log('empty');
		}

	}, [generalDatas, cartDatas])

	// useEffect(() => {
	// if(giftcardDatas){
	// 	console.log(giftcardDatas, 'giftcardDatas1');
	// }else{
	// 	setDiscount(0.00)
	// }

	// if (giftcardLoading === API_STATUS.FULFILLED) {
	// 	if(giftcardDatas){
	// 		console.log(giftcardDatas, 'giftcardDatas2');
	// 	}else{
	// 		setDiscount(0.00)
	// 	}

	// } else if (giftcardLoading === API_STATUS.REJECTED) {
	// 	// toast.dismiss();
	// }

	// }, [giftcardLoading])

	// useEffect(() => {
	// 	if (giftcardDatas) {
	// 		if (giftcardDatas.status == true) {

	// 			if (giftcardDatas.data.length == 0) {
	// 				setDiscount(0.00)
	// 				setMessage("Invalid Gift Card.")
	// 			} else {
	// 				let points = giftcardDatas.data.load_points
	// 				if ((orderTotal - points) < 0) {
	// 					points = orderTotal
	// 					setDiscount(orderTotal);
	// 					setfinalTotal(0)
	// 				} else {
	// 					setDiscount(giftcardDatas.data.load_points);
	// 					setfinalTotal(parseFloat(orderTotal - points).toFixed(2))
	// 				}
	// 			}

	// 		} else {
	// 			setDiscount(0.00);
	// 			setfinalTotal(orderTotal)
	// 			setMessage(giftcardDatas.message)
	// 		}

	// 	} else {
	// 		setDiscount(0.00)
	// 	}
	// }, [giftcardDatas])

	useEffect(() => {
		if (cardtypeDatas) {

			console.log(cardtypeDatas, 'cardtypeDatas');
			if (cardtypeLoading === API_STATUS.FULFILLED) {
				if (cardtypeDatas.status == true) {

					if (cardtypeDatas?.result.cardType == 'VISA' || cardtypeDatas?.result.cardType == 'VISA DEBIT') {
						setcardtypeImage(require('../../images/visa.jpg'));
					}
					if (cardtypeDatas?.result.cardType === 'ELECTRON') {
						setcardtypeImage(require('../../images/electron.jpeg'));
					}
					if (cardtypeDatas?.result.cardType === 'MAESTRO') {
						setcardtypeImage(require('../../images/Maestro.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DISCOVER') {
						setcardtypeImage(require('../../images/discover.png'));
					}
					if (cardtypeDatas?.result.cardType === 'JCB') {
						setcardtypeImage(require('../../images/jcb.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}

					if (cardtypeDatas?.result?.cardType == undefined) {
						setcardtypeImage('')
					}
				}
			}

			if (cardtypeLoading === API_STATUS.PENDING) {
				toast.dismiss();
			}

		}
	}, [cardtypeDatas])

	useEffect(() => {
		console.log(checkoutItems, {
			"purchase_units": [
				{
					"items": checkoutItems,
					"amount": {
						"currency_code": "USD",
						"value": `${parseFloat(orderTotal).toFixed(2)}`,
						"breakdown": {
							"item_total": {
								"currency_code": "USD",
								"value": `${parseFloat(orderTotal).toFixed(2)}`
							}
						}
					}
				}
			],
		}, "checkoutItems")
	}, [checkoutItems])

	const CheckValid = (e) => {

		console.log(e.target.checked, 'check option');

		if (e.target.checked) {
			setuseWallet(0)
			let final = Number(profileDatas.wallet) - Number(orderTotal)
			if (final > 0) {
				setshowCardData(0)
				setfinalTotal(0)
				setDiscount(parseFloat(orderTotal).toFixed(2))
			} else {

				let newfinal = Number(orderTotal) - Number(profileDatas.wallet)
				if (newfinal > 0) {
					setshowCardData(1)
				} else {
					setshowCardData(0)
				}
				setfinalTotal(parseFloat(newfinal).toFixed(2))
				setDiscount(parseFloat(profileDatas.wallet).toFixed(2))
			}

		} else {
			if (orderTotal > 0) {
				setshowCardData(1)
			}
			setuseWallet(1)
			setfinalTotal(parseFloat(orderTotal).toFixed(2))
			setDiscount(0)
		}
		console.log(finalTotal, 'checkfinal');
		console.log(showCardData, 'checkfinalshow');
		// if (giftCoupon.trim() == '') {
		// 	setMessage('Please enter the valid Giftcard.')
		// 	setTimeout(() => {
		// 		setMessage('')
		// 	}, 2000);
		// 	e.preventDefault()
		// 	return false;
		// }

		// dispatch(CheckGiftCard({ giftcard: giftCoupon }));

		// setDiscount(50.00);
		// setfinalTotal(parseFloat(orderTotal - 50).toFixed(2))
		// e.preventDefault()
	}


	useEffect(() => {
		if (colorLists) {
			let selectedColor = colorLists[0].selected_colors
			console.log(selectedColor, 'selectedColor--colorARR');
			let parseDatas = [];
			if (selectedColor) {
				parseDatas = JSON.parse(selectedColor)
			}
			console.log(parseDatas, 'parseDatass==');
			setvehicleColors([...parseDatas])
		}
	}, [colorLists])

	const RemoveCoupon = (e) => {
		setDiscount(0.00);
		setfinalTotal(parseFloat(orderTotal).toFixed(2))
		setgiftCoupon('')
		e.preventDefault()
	}

	useEffect(() => {
		console.log(finalTotal, 'newfinalTotal');
		// if (finalTotal > 0) {
		// 	setshowCardData(1)
		// }else{
		// 	setshowCardData(0)

		// }
	}, [orderTotal, finalTotal, giftCoupon, showCardData])

	useEffect(() => {
		formValidator.current.showMessages()
		driveValidator.current.showMessages()
		scheduleValidator.current.showMessages()
	}, [])
	//[{unitPrice: 5,quantity: 1},{unitPrice: 8,quantity: 2}]

	const chooseOrderMode = () => {
		setdriveThruModal(false)
		setdriveFormSubmit(false)
		setorderModeModal(false)
		setshowOrderMode(true)
	}

	const onshowOrderClose = () => {
		window.location.href = '/cartlist'
	}

	const onVehicleColor = (color) => {
		let colorNamess = GetColorName(color.colorCode)
		setdriveDetails({
			...driveDetails,
			vehicleColor: color.colorName
		})
	}

	const driveBackToCart = () => {
		setdriveThruModal(false)
		setdriveFormSubmit(false)
		setshowOrderMode(true)
	}

	const backtoSchedule = () => {
		if (scheduleSubmitted) {
			setdriveThruModal(false)
			setdriveFormSubmit(false)
			setshowOrderMode(false)
			setorderModeModal(false)
			setscheduleModal(true)
		} else {
			setdriveThruModal(false)
			setdriveFormSubmit(false)
			setshowOrderMode(false)
			setorderModeModal(true)
		}
	}

	const scheduleBackToCart = () => {
		setscheduleModal(false)
		setscheduleFormSubmit(false)
		setshowOrderMode(false)
		setscheduleSubmitted(false)
		setorderModeModal(true)
	}

	const onDriveSubmit = () => {

		if (driveValidator.current.allValid()) {
			setshowOrderMode(false)
			setorderModeModal(false)
			setdriveThruModal(false)
			setscheduleSubmitted(false)
			toast.success('Drive-Thru Details Submitted')
		} else {
			setdriveFormSubmit(true)
		}

	}

	const onScheduleSubmit = () => {
		setscheduleFormSubmit(true)
		if (scheduleValidator.current.allValid()) {
			setscheduleOrder({ ...scheduleOrder, schedulePickup: '1' })
			setscheduleModal(false)
			setshowOrderMode(true)
			setscheduleSubmitted(true)
			// toast.success('Scheduled Pickup Time Submitted')
		}
	}

	const pickUpSubmit = () => {
		setpickupHere('1')
		setorderModeModal(false)
		setscheduleSubmitted(false)
	}


	const setOrderModeType = (type) => {

		if (type == 'drive') {
			setorderModeValue(1)
			setdriveThruModal(true)
			setshowOrderMode(false)
		}
		if (type == 'schedule') {
			// setorderModeValue(2)
			setscheduleModal(true)
			setshowOrderMode(false)
			setorderModeModal(false)
		}
		if (type == 'pickup') {
			setorderModeValue(2)
			setshowOrderMode(false)
			toast.success('Pickup Order Mode submitted')
		}
	}

	const cc_format = (value) => {
		const v = value
			.replace(/\s+/g, "")
			.replace(/[^0-9]/gi, "")
			.substr(0, 16);
		const parts = [];

		for (let i = 0; i < v.length; i += 4) {
			parts.push(v.substr(i, 4));
		}

		return parts.length > 1 ? parts.join(" ") : value;
	}

	console.log(discount, 'discount');
	const submitPayment = () => {

		// let error = 0
		// if (ccData.cc_number.length == 0) {
		// 	error = 1
		// 	setCardNumberError('Card number is required.!')
		// }
		// if (ccData.cc_number.length > 0 && ccData.cc_number.length !== 16) {
		// 	error = 1
		// 	setCardNumberError('Card number must be 16 digits.!')
		// }
		// if (ccData.cc_cvc.length == 0) {
		// 	error = 1
		// 	setcvcError('CVC number is required.!')
		// }
		// if (ccData.cc_cvc.length > 0 && ccData.cc_cvc.length !== 3) {
		// 	error = 1
		// 	setcvcError('CVC number must be 3 digits.!')
		// }
		// if (ccData.cc_name.length == 0) {
		// 	error = 1
		// 	setcardNameError('Cardholder name is required.!')
		// }

		// if (ccData.cc_month.length == 0) {
		// 	error = 1
		// 	setexpiryError('Expiry date is required.!')
		// }

		// if (error == 1) {
		// 	return false
		// }

		// setAddFormSubmit(true)

		// if (formValidator.current.allValid()) {
		// if (orderModeValue) {
		// Swal.fire({
		// 	title: 'Confirm Order ?',
		// 	icon: 'question',
		// 	text: disclaimerText,
		// 	cancelButtonColor: '#d33',
		// 	confirmButtonText: 'Confirm',
		// 	showConfirmButton: true,
		// 	showCancelButton: true
		// }).then((result) => {
		// 	if (result.isConfirmed) {

		let orderForm = {
			transactionid: "0",
			type: 'sale',
			final_amount: parseFloat(Number(orderTotal) - Number(discount)).toFixed(2),
			order_amount: parseFloat(orderTotal).toFixed(2),
			sub_total: parseFloat(subTotal).toFixed(2),
			tax_percent: salesTax * 100,
			tax_amount: parseFloat(Number(subTotal) * Number(salesTax)).toFixed(2),
			redeem_points: rewardTotal,
			po_number: 1,
			payment_method: "CARD",
			payment_status: "READY",
			order_status: 0,
			print_status: 0,
			status: 1,
			gift_card: (discount > 0) ? 'Wallet' : null,
			gift_point: parseFloat(discount).toFixed(2),
			refund: 0,
			order_response: '',
			// order_number: 0,
			schedulePickup: scheduleOrder.schedulePickup,
			schedulePickup_time: scheduleOrder.schedulePickup_time,
			order_mode: 2,
			orderMode_details: 'frontwindow-order'
			// order_mode: orderModeValue,
			// orderMode_details: orderModeValue == 1 ? JSON.stringify(driveDetails) : orderModeValue == 2 ? 'pickup-order' : ''
		}

		dispatch(CreateOrder({
			postData: orderForm
		}));
		setPaymentOrder(true)
		// }
		// })s
		// dispatch(OrderPDF({
		// 	order_id: paymentDatas.orderId
		// }));
		// } 
		// else {
		// 	toast.error('Choose Order Mode');
		// 	setshowOrderMode(true)
		// }

		// let paymentData = chargePayment
		// paymentData.order = {
		// 	orderId: "ORDDP002", currency: "USD", totalAmount: parseFloat(orderTotal).toFixed(2),
		// 	orderBreakdown: {
		// 		subtotalAmount: parseFloat(orderTotal).toFixed(2),
		// 		items: orderItems,
		// 	}
		// }
		// paymentData.customerAccount = {
		// 	payloadType: "KEYED",
		// 	accountType: "CHECKING",
		// 	cardDetails: {
		// 		dataFormat: "PLAIN_TEXT",
		// 		cardNumber: ccData.cc_number.replaceAll(" ", ""),
		// 		cvv: ccData.cc_cvc,
		// 		expiryDate: ccData.cc_month.replace('/', '')
		// 	}
		// }

		// dispatch(newPayment({ postData: paymentData }));
	}

	const cashlessOrderPayment = () => {
		// if (orderModeValue) {
		Swal.fire({
			title: 'Confirm Order ?',
			icon: 'question',
			text: disclaimerText,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Confirm',
			showConfirmButton: true,
			showCancelButton: true
		}).then((result) => {
			if (result.isConfirmed) {
				let orderForm = {
					transactionid: "0",
					type: (rewardTotal > 0 && discount == 0.00) ? 'reward' : 'gift',
					final_amount: parseFloat(finalTotal).toFixed(2),
					order_amount: parseFloat(orderTotal).toFixed(2),
					sub_total: parseFloat(subTotal).toFixed(2),
					tax_percent: salesTax * 100,
					tax_amount: parseFloat(Number(subTotal) * Number(salesTax)).toFixed(2),
					redeem_points: rewardTotal,
					po_number: 1,
					payment_method: "CASHLESS",
					payment_status: "READY",
					order_status: 1,
					print_status: 0,
					status: 1,
					gift_card: (discount > 0) ? 'Wallet' : null,
					gift_point: parseFloat(discount).toFixed(2),
					refund: 0,
					order_response: "0",
					order_number: "",
					schedulePickup: scheduleOrder.schedulePickup,
					schedulePickup_time: scheduleOrder.schedulePickup_time,
					order_mode: 2,
					orderMode_details: 'frontwindow-order'
					// order_mode: orderModeValue,
					// orderMode_details: orderModeValue == 1 ? JSON.stringify(driveDetails) : orderModeValue == 2 ? 'pickup-order' : ''
				}

				dispatch(CreateOrder({
					postData: orderForm
				}));
			}
		})
		// } else {
		// 	toast.error('Choose Order Mode');
		// 	setshowOrderMode(true)
		// }
	}

	useEffect(() => {
		if (paymentloading === API_STATUS.FULFILLED) {
			let updateorderDetails
			if (paymentDatas) {
				if (paymentDatas.status === false) {
					// setexpiryError(paymentDatas.message)
					// setTimeout(() => {
					// 	setexpiryError('')
					// }, 3000);
					Swal.fire({
						title: 'Payment Failed!',
						icon: 'error',
						text: paymentDatas.message,
						cancelButtonColor: '#d33',
						cancelButtonText: 'Retry Payment',
						showConfirmButton: false,
						showCancelButton: true
					}).then((result) => {
						if (result.dismiss) {
							setccData({ ...ccData, cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' })
							setdisplayFakeData(0)
							setcardtypeImage('')
						}
					})
					// return false;

					updateorderDetails = {
						order_number: createOrderDatas,
						order_status: 3,
						status_message: paymentDatas.message
					}
				}
				else {
					setTransactionID(paymentDatas.data.uniqueReference)
					// setshowModal(true);
					Swal.fire({
						title: 'Payment Successful!',
						icon: 'success',
						html: `Payment Successful. Transaction ID : <strong>${paymentDatas.data.uniqueReference}</strong>`,
						timer: 2000,
						timerProgressBar: true,
						footer: 'Please wait Confirming your Order...',
						showConfirmButton: false,
						// confirmButtonText: 'Close',
						// }).then((result) => {
						// 	if (result.isConfirmed) {
						// 		window.location.href = '/'
						// 	}
					})

					updateorderDetails = {
						transactionid: paymentDatas.data.uniqueReference,
						type: paymentDatas?.data?.transactionResult?.type,
						order_response: JSON.stringify(paymentDatas.data),
						order_number: paymentDatas.orderId,
						order_status: 1,
						status_message: 'Payment Success'
					}


					// dispatch(OrderPDF({
					// 	order_id: paymentDatas.orderId
					// }));

				}

				dispatch(
					UpdateOrder({
						postData: updateorderDetails
					})

				)
			}
			// window.scrollTo(0, 0)
		} else if (paymentloading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.error('Login Failed! Please check username and password');
		}
		if (paymentloading === API_STATUS.REJECTED) {
			// toast.dismiss();
			// toast.error('Email not found.');
		}
	}, [paymentloading, paymentDatas])
	console.log(Number(profileDatas?.wallet), 'Number(profileDatas?.wallet)');
	console.log(Number(discount), 'discount');
	useEffect(() => {
		console.log(createOrderloading, 'createOrderloading');
		if (createOrderloading === API_STATUS.FULFILLED) {
			if (createOrderDatas == '0') {
				Swal.fire({
					title: 'Duplicate Order.!',
					icon: 'error',
					html: `<strong>Order Rejected. Duplicate Order attempt.!</strong>`,
					confirmButtonText: 'Close'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = '/orderlist'
					}
				})
				setTimeout(() => {
					window.location.href = '/orderlist'
				}, 2000);
				return false;
			}

			if (paymentOrder) {
				setpaymentOrderModal(true)
				// Swal.fire({
				// 	title: 'Confirm Order ?',
				// 	icon: 'question',
				// 	text: disclaimerText,
				// 	cancelButtonColor: '#d33',
				// 	confirmButtonText: `<form action={${process.env?.REACT_APP_PAYMENT_PAGE} ? ${process.env.REACT_APP_PAYMENT_PAGE} : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
				// 	<input type="hidden" name="TERMINALID" value=${process.env.REACT_APP_TERMINAL} />
				// 	<input type="hidden" name="ORDERID" value=${createOrderDatas} />
				// 	<input type="hidden" name="CURRENCY" value="USD" />
				// 	<input type="hidden" name="PAGES" value="ORDER" />
				// 	<input type="hidden" name="AMOUNT" value=${parseFloat(orderTotal).toFixed(2)} />
				// 	<input type="hidden" name="DATETIME" value=${moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
				// 	<input type="hidden" name="HASH" value=${CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${createOrderDatas}:${parseFloat(orderTotal).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
				// 	<input className="btn button-lg btnhover btn-block" type="submit" value='Order' />
				// </form>`,
				// 	showConfirmButton: true,
				// 	showCancelButton: true,
				// 	html: `<form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
				// 		<input type="hidden" name="TERMINALID" value=${process.env.REACT_APP_TERMINAL} />
				// 		<input type="hidden" name="ORDERID" value=${createOrderDatas} />
				// 		<input type="hidden" name="CURRENCY" value="USD" />
				// 		<input type="hidden" name="PAGES" value="ORDER" />
				// 		<input type="hidden" name="AMOUNT" value=${parseFloat(orderTotal).toFixed(2)} />
				// 		<input type="hidden" name="DATETIME" value=${moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
				// 		<input type="hidden" name="HASH" value=${CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${createOrderDatas}:${parseFloat(orderTotal).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
				// 		<input className="btn button-lg btnhover btn-block" type="submit" value='Order' />
				// 	</form>`
				// 	// }).then((result) => {
				// 	// 	if (result.isConfirmed) {
				// 	// let hashValue = CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${createOrderDatas}:${parseFloat(orderTotal).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)

				// 	// console.log(hashValue, createOrderDatas, orderTotal, process.env.REACT_APP_TERMINAL, process.env.REACT_APP_WORLDNET_SECRET, 'hashValue===');
				// 	// window.location = (`https://testpayments.worldnettps.com/merchant/paymentpage?TERMINALID=${process.env.REACT_APP_TERMINAL}&ORDERID=${createOrderDatas}&CURRENCY=USD&PAGES=ORDER&DATETIME=${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}&AMOUNT=${parseFloat(orderTotal).toFixed(2)}&HASH=${hashValue}`)
				// 	// <form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
				// 	// 	<input type="hidden" name="TERMINALID" value={process.env.REACT_APP_TERMINAL} />
				// 	// 	<input type="hidden" name="ORDERID" value={createOrderDatas} />
				// 	// 	<input type="hidden" name="CURRENCY" value="USD" />
				// 	// 	<input type="hidden" name="PAGES" value="ORDER" />
				// 	// 	<input type="hidden" name="AMOUNT" value={parseFloat(orderTotal).toFixed(2)} />
				// 	// 	<input type="hidden" name="DATETIME" value={moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
				// 	// 	<input type="hidden" name="HASH" value={CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${createOrderDatas}:${parseFloat(orderTotal).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
				// 	// 	<input className="btn button-lg btnhover btn-block" type={(finalTotal || orderTotal > 0) ? "submit" : "button"} value={`Pay $ ${(finalTotal > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)}`} />
				// 	// </form>
				// 	// }
				// })
			}

			// if (ccData.cc_number !== '') {

			// 	let paymentData = chargePayment
			// 	paymentData.order = {
			// 		orderId: createOrderDatas, currency: "USD", totalAmount: parseFloat(orderTotal).toFixed(2),
			// 		orderBreakdown: {
			// 			subtotalAmount: parseFloat(orderTotal).toFixed(2),
			// 			items: orderItems,
			// 		}
			// 	}
			// 	paymentData.customerAccount = {
			// 		payloadType: "KEYED",
			// 		accountType: "CHECKING",
			// 		cardDetails: {
			// 			dataFormat: "PLAIN_TEXT",
			// 			cardNumber: ccData.cc_number.replaceAll(" ", ""),
			// 			cvv: ccData.cc_cvc,
			// 			expiryDate: ccData.cc_month.replace('/', '')
			// 		}
			// 	}

			// 	dispatch(newPayment({ postData: paymentData }));
			// }
			else {
				dispatch(OrderPDF({
					order_id: createOrderDatas
				}));
				Swal.fire({
					title: 'Your Order has been Confirmed!',
					icon: 'success',
					html: `Your Order ID : <strong>#${createOrderDatas}</strong>`,
					confirmButtonText: 'Close'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = '/orderlist'
					}
				})
				setTimeout(() => {
					window.location.href = '/orderlist'
				}, 2000);
			}
		}
		if (createOrderloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(createOrderMessage);
		}
		if (createOrderloading === API_STATUS.PENDING) {
			toast.dismiss();
		}

	}, [createOrderloading]);

	useEffect(() => {
		console.log(orderUpdateloading, 'orderUpdateloading');
		if (orderUpdateloading === API_STATUS.FULFILLED) {

			if (orderUpdateDatas.order_status === 1) {
				dispatch(OrderPDF({
					order_id: createOrderDatas
				}));

				Swal.fire({
					title: 'Your Order has been Confirmed!',
					icon: 'success',
					html: TransactionID ?
						`<div>Your Payment Transaction ID: 
					<strong>#${TransactionID}</strong><br>
				Your Order ID : <strong>#${createOrderDatas}</strong>
				</div> `: `Your Order ID : <strong>#${createOrderDatas}</strong>`,
					confirmButtonText: 'Close'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = '/orderlist'
					}
				})
				setTimeout(() => {
					window.location.href = '/orderlist'
				}, 2000);
			}
		}
		if (orderUpdateloading === API_STATUS.REJECTED) {
			toast.dismiss();
			// toast.error(createOrderMessage);
		}
		if (orderUpdateloading === API_STATUS.PENDING) {
			toast.dismiss();
		}

	}, [orderUpdateloading]);

	console.log(showCardData, 'showcarddata');

	// useEffect(() => {
	// 	let data = `${process.env.REACT_APP_TERMINAL}:150750218:25.00:2023-06-01 15:07:50:218:Test@123456789011`
	// 	let data1 = CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:232:10.00:13-06-2023:10:01:21:906:Test@123456789011`).toString(CryptoJS.enc.Hex)
	// 	let data2 = CryptoJS.SHA512("678002:300145858:325.56:15-3-2006:10:43:01:673:x4n35c32RT").toString(CryptoJS.enc.Hex);
	// 	//5b39821025c33a3c37560196f36af68668e46e82afc4017434d72e62dbc4c06781afc6364e992d5594656fb185c901ece65adf85e8822832b8985f602e533eba
	// 	//5b39821025c33a3c37560196f36af68668e46e82afc4017434d72e62dbc4c06781afc6364e992d5594656fb185c901ece65adf85e8822832b8985f602e533eba
	// 	//"https://testpayments.worldnettps.com/merchant/paymentpage?TERMINALID=5271001&ORDERID=150750218&CURRENCY=USD&DATETIME=2023-06-01 15:07:50:218&AMOUNT=25.00&HASH=8d0ddaa7f203e348b205c83a5e1a11f2806b0fa9a36a22b72281076cd712a259ee9ba51f597ba837cbd666f9e615434ce959b95ba53230907ccd3a552a2b0f01"
	// 	//https://testpayments.worldnettps.com/merchant/paymentpage?TERMINALID=5271001&ORDERID=232&CURRENCY=USD&DATETIME=13-06-2023:10:01:21:906&AMOUNT=10.00&HASH=f2b05b1862cec80805c33e16595f61bdff6eb621065e0462359c750a6ec227f8b03f524f5c9706f22c9ce7e418eb2a33f94120e4bae1b9be189cb0cf2c1d1a09
	// 	console.log(data1, 'dataaa===', data2);
	// }, [orderTotal > 0])

	console.log(orderTotal, 'parseFloat(finalTotal).toFixed(2)');

	return (
		<>
			<Header />

			<div className="page-content bg-white">

				<div className="content-block">

					{/* <div className="breadcrumb-row" style={{ backgroundColor: 'red', color: 'white' }}>
						<ul className="list-inline">
							<li><Link to={'/'}>Home</Link></li>
							<li> <Link to={'#'}>Checkbox</Link> </li>
						</ul>
					</div> */}
					<div className="breadcrumb-row" style={{ backgroundColor: '#c1272d', paddingLeft: '20px' }}>
						<ul className="list-inline">
							<li><Link to={'/'} onClick={() => {
								window.scroll({ top: 0, left: 0, behavior: "smooth", });
							}} style={{ color: 'white' }}>Home</Link></li>
							{/* <li>{ subcategoryDatas.data ? subcategoryDatas.data.name : '-' }</li> */}

							<li><a href={'/cartlist'} style={{ color: 'white' }}>SHOP Cart</a></li>
							<li><a href='javascript:;' style={{ color: 'white' }}>Checkout</a></li>

						</ul>
					</div>
				</div>


				<div className="section-full content-inner1">

					<div className="container">

						<div className="dlab-divider bg-gray-dark text-gray-dark icon-center"><i className="fa fa-circle bg-white text-gray-dark"></i></div>
						<div className="row">
							<div className="col-lg-6">
								<h3>Your Order</h3>
								<table className="table-bordered check-tbl">
									<thead>
										<tr>
											<th>IMAGE</th>
											<th>PRODUCT NAME</th>
											<th>TOTAL</th>
										</tr>
									</thead>
									<tbody>
										{cartDatas && cartDatas.data.map((result, tindex) => (
											<tr>
												<td><img src={assestURL + result.product.image} alt="" /></td>
												<td><span style={{ color: 'green', fontSize: '16px' }}>{result.product.name}</span><br />
													<span>{(result.item_names && result.default_custom == 0) ? <span style={{ fontSize: '14px' }}><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</span> : (result.item_names && result.default_custom == 1) ? <span style={{ fontSize: '14px' }}><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</span> : ''}</span>
												</td>
												<td className="product-price">{(result.reward == 1) ? result.total_cost + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)}</td>
											</tr>
										))}

									</tbody>
								</table>
							</div>
							<div className="col-lg-6">
								<form className="shop-form">
									<div className='col-md-12'>
										<div className='row'>
											<div className='col-md-4'>
												<h3 style={{ marginTop: '5px' }}>Order Total</h3>
											</div>
											<div className='col-md-8'>
												<Badge.Ribbon text={<><i class="fa fa-dot-circle-o"></i>&nbsp;Reward Points</>} color="rgb(26 49 183 / 90%)">
													<Card size="small">
														<i class="fa fa-gift" style={{ fontSize: '25px' }} aria-hidden="true"></i> &nbsp;<span style={{ fontSize: '16px', marginTop: '5px', marginBottom: '5px' }} >You Will Earn :<b> {Math.round(subTotal)} pts</b></span>
													</Card >
												</Badge.Ribbon >
											</div >
										</div >
										{/* <div className='col-md-8'>
												<Badge.Ribbon text={<><i class="fa fa-gift"></i>&nbsp;Purchased Reward Points : <b>{subTotal} pts</b></>} style={{ fontSize: '14px', padding: '5px',marginBottom:'5px' }} color="rgb(26 49 183 / 90%)" ></Badge.Ribbon>
											</div></div> */}

									</div >
									<table className="table-bordered check-tbl mt-1">
										<tbody>
											{scheduleSubmitted ? <tr>
												<td>Schedule Pickup</td>
												<td style={{ textAlign: 'right' }}>{scheduleOrder.schedulePickup_time}</td>
											</tr>
												: ''}
											{orderModeValue ?
												<tr>
													<td>Order Mode</td>
													<td style={{ textAlign: 'right' }}>{orderModeValue == 1 ? 'Drive-Thru' : orderModeValue == 2 ? 'Front Window Order' : ''}&nbsp;&nbsp;<i className='fa fa-edit' onClick={() => { setorderModeModal(true) }} title='Edit' style={{ fontSize: '20px' }}></i></td>
												</tr>
												: ''}
											<tr>
												<td>Order Subtotal</td>
												<td className="product-price" style={{ textAlign: 'right' }}>${parseFloat(subTotal).toFixed(2)}</td>
											</tr>
											<tr>
												<td style={{ color: 'green' }}>Online Order Savings</td>
												<td style={{ color: 'green', textAlign: 'right' }}>${setOnlineSavings((discount > 0) ? finalTotal : orderTotal)}</td>
											</tr>
											{rewardTotal > 0 ?
												<tr>
													<td>Reward Products Purchased For</td>
													<td style={{ textAlign: 'right' }}>{rewardTotal} pts</td>
												</tr> : ''}
											<tr>
												<td>Sales Tax ({salesTax * 100}%)</td>
												{
													(subTotal == 0) ?
														<td style={{ textAlign: 'right' }}>0.00</td>
														: <td style={{ textAlign: 'right' }}>${parseFloat(Number(subTotal) * Number(salesTax)).toFixed(2)}</td>
												}
											</tr>
											<tr>
												<td>Total</td>
												<td className="product-price-total" style={{ textAlign: 'right' }}>${parseFloat(orderTotal).toFixed(2)}</td>
											</tr>
											{(finalTotal && Number(finalTotal) > 0) || (orderTotal && Number(orderTotal) > 0) ?
												<tr>
													<td>
														Dip wallet Balance:<span> $ {
															(profileDatas) && (Number(profileDatas?.wallet) > discount) ?
																parseFloat(Number(profileDatas?.wallet) - Number(discount)).toFixed(2) :
																(Number(profileDatas?.wallet) < discount) ? parseFloat(Number(discount) - Number(profileDatas?.wallet)).toFixed(2) : 0}</span>
													</td>

													<td>
														<input type="checkbox" disabled={profileDatas?.wallet == 0} id="vehicle1" name="vehicle1" value="Bike" onClick={CheckValid}></input>
														<label for="vehicle1" style={{ float: 'right', marginTop: '1rem' }}></label><br />
													</td>

												</tr> : false}
											{/* <tr>
												<td>GiftCard</td>
												<td className="product-price text-right w-50" >
													<div className="d-flex justify-content-end" >
														<div className="form-group mb-0 text-right mr-2">
															<input
																className="form-control w-100"
																// style={{ width: '50%' }}
																id="signin-email"
																placeholder="Enter gift card"
																type="text"
																value={giftCoupon}
																name='gift_card'
																autoComplete="off"
																onChange={val => {
																	if (val.target.value.trim() !== '') {
																		setMessage('')
																	}
																	setgiftCoupon(val.target.value);
																}}
															/></div>&nbsp;&nbsp;
														<div className='form-group mb-0'>

															{(discount == 0) ? <button className='btn btn-success' onClick={CheckValid}>Apply</button> : ''}
															{(discount != 0) ? <button className='btn btn-danger' onClick={RemoveCoupon}>Remove</button> : ''}
														</div>
													</div>
													<span style={{ color: 'red' }}>{Message}</span>
												</td>

											</tr> */}
											{/* <tr>
												<td>Discount</td>
												<td className="product-price" style={{ textAlign: 'right' }}>$ {parseFloat(discount).toFixed(2)}</td>
											</tr> */}
											<tr>
												<td>Final Total</td>
												<td className="product-price-total" style={{ textAlign: 'right' }}>${(discount > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)}</td>
											</tr>
										</tbody>
									</table>

								</form >
								{/* <div className="form-group">
										<input type="text" className="form-control" placeholder="Name on Card" />
									</div> */}
								{/* <div className="form-group">
										<Form.Group controlId="exampleForm.ControlSelect1">
											
											<Form.Control as="select" >
												<option value="">Credit Card Type</option>
												<option value="">Another option</option>
												<option value="">A option</option>
												<option value="">Potato</option>
											</Form.Control>	
												
										</Form.Group>

									</div> */}
								{
									(showCardData == 1) ?

										<>
											{
												(finalTotal || (discount !== orderTotal)) ?
													<div className="form-group">
														<button className="btn button-lg btnhover btn-block"
															type="button"
															onClick={submitPayment}
														>Pay ${(finalTotal > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)} </button>
													</div>

													: <div className="form-group">
														<button className="btn button-lg btnhover btn-block"
															type="button"
															onClick={cashlessOrderPayment}
														>Order Now </button>
													</div>
											}

											{/* <div className="form-group">
											<button className="btn button-lg btnhover btn-block"
												type="button"
												onClick={submitPayment}
											>Pay ${(finalTotal || (discount === orderTotal)) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)} </button>
										</div> */}
											<span className=''>{expiryError}</span></>
										: <div className="form-group">
											<button className="btn button-lg btnhover btn-block"
												type="button"
												onClick={cashlessOrderPayment}
											>Order Now </button>
										</div>
								}
							</div >
						</div >
					</div >



					<Modal className='dialog-screen modal-dialog1 ' size="lg" show={orderModeModal} onHide={'Choose Order Mode to Proceed Checkout'}>
						<Modal.Header style={{ background: '#c1272d' }} closeButton={false}>
							<Modal.Title>Choose Schedule Pickup</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ minHeight: '250px' }}>
							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<div style={{ paddingTop: '15px' }}>
									<p style={{ fontWeight: '500' }}>Do you want to schedule this order at a particular time?</p>
								</div>
							</div>
							<div className='row'>
								<div className="col-md-12" style={{ marginLeft: '11rem' }}>

									<Button variant="outline-success" size="lg" onClick={() => { setOrderModeType('schedule') }} style={{ paddingLeft: '30px', paddingRight: '25px' }}>
										Schedule for later
									</Button>&nbsp;&nbsp;
									<Button variant="outline-danger" size="lg" onClick={() => { chooseOrderMode() }} >
										Order Now
									</Button>


								</div>
							</div>
						</Modal.Body>
						<Modal.Footer style={{ fontSize: '14px' }}>
							<a className='btn btnhover' style={{ marginRight: '280px', color: 'white' }} onClick={onshowOrderClose}>
								Back To Cart
							</a>
							<i className="fa fa-info-circle" aria-hidden="true"></i> &nbsp;&nbsp;Choose Order Mode to Proceed Checkout
						</Modal.Footer>
					</Modal>

					<Modal className='dialog-screen modal-dialog1 schedule-modal' style={{ width: '400px' }} size="lg" show={showOrderMode} onHide={'Choose Order Mode to Proceed Checkout'}>
						<Modal.Header style={{ background: '#c1272d' }} closeButton={false}>
							<Modal.Title>Choose Order Mode</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{scheduleSubmitted ? <p><i className="fa fa-info-circle" aria-hidden="true"></i> &nbsp;&nbsp;Choose Order Mode for Scheduled Time Pickup</p> : <p><i className="fa fa-info-circle" aria-hidden="true"></i> &nbsp;&nbsp;Choose Order Mode for Delivery</p>}
							<div style={{ textAlign: 'center' }}>
								<div style={{ paddingTop: '15px' }}>
									<img src={driveIcons} width="70px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
									<Button variant="outline-success" size="lg" style={{ width: '300px' }} onClick={() => { setOrderModeType('drive') }}>
										Drive-Thru
									</Button><br></br>
								</div>
								{/* <div style={{ paddingTop: '18px' }}>
									<img src={scheduleIcon} width="63px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
									<Button variant="outline-danger" size="lg" style={{ width: '300px' }} onClick={() => { setOrderModeType('schedule') }} >
										Scheduled Pick Up Order
									</Button><br></br>
								</div> */}
								<div style={{ paddingTop: '20px' }}>
									<img src={pickup} width="70px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
									<Button variant="outline-warning" size="lg" style={{ width: '300px' }} onClick={() => { setOrderModeType('pickup') }}>
										Front Window Order
									</Button>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer style={{ fontSize: '14px' }}>
							<Button variant="secondary" onClick={backtoSchedule}>
								Back To Schedule Pickup
							</Button>
						</Modal.Footer>
					</Modal>

					{/* <Modal className='dialog-screen modal-dialog1 schedule-modal' size="lg" show={driveThruModal} onHide={driveBackToCart}>
						<Modal.Header style={{ background: '#c1272d' }} closeButton>
							<Modal.Title>
								<img src={driveIcon} width="70px"></img> &nbsp;&nbsp;&nbsp;<label >Drive-Thru</label></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div style={{ textAlign: 'center', paddingLeft: '420px' }} >
								<label style={{ color: '#000', fontSize: '20px' }}>Edit</label> &nbsp;<i className='fa fa-edit' style={{ fontSize: '25px' }}></i>
							</div>
							<form>

								<div className="form-group row">
									<label for="staticEmail" className="col-sm-3 col-form-label">Vehicle Model</label>
									<div className="col-sm-7">
										<input type="text" className="form-control" placeholder='Toyota SUV' id="staticEmail" value={driveDetails.vehicleModel} onChange={(e) => {
											setdriveDetails({
												...driveDetails,
												vehicleModel: e.target.value
											})
										}} />
										{driveValidator.current.message(
											'vehicleModel',
											driveDetails.vehicleModel,
											'required',
											{
												className: `invalid_feedback 
														  ${driveFormSubmit ? 'show' : 'hide'}`,
												messages: {
													required: 'Please Enter Vehicle Model',
												}
											}
										)}
									</div>
								</div>
								<div className="form-group row">
									<label for="inputPassword" className="col-sm-3 col-form-label">Vehicle Colour</label>
									<div className="col-sm-7">
										<input type="text" className="form-control" id="inputPassword" placeholder="Blue" value={driveDetails.vehicleColor} onChange={(e) => {
											setdriveDetails({
												...driveDetails,
												vehicleColor: e.target.value
											})
										}} />
										{driveValidator.current.message(
											'vehicleColor',
											driveDetails.vehicleColor,
											'required|alpha_space',
											{
												className: `invalid_feedback 
														  ${driveFormSubmit ? 'show' : 'hide'}`,
												messages: {
													required: 'Please Enter Vehicle Colour',
													alpha_space: 'Please Enter Valid Colour'
												}
											}
										)}
									</div>
								</div>
								<div className="form-group row">
									<label for="inputPassword" className="col-sm-3 col-form-label">Vehicle No</label>
									<div className="col-sm-7">
										<input type="text" className="form-control" id="inputPassword" placeholder="AAA-001" value={driveDetails.vehicleNo} onChange={(e) => {
											setdriveDetails({
												...driveDetails,
												vehicleNo: e.target.value
											})
										}} />
										{driveValidator.current.message(
											'vehicleNo',
											driveDetails.vehicleNo,
											'required',
											{
												className: `invalid_feedback 
														  ${driveFormSubmit ? 'show' : 'hide'}`,
												messages: {
													required: 'Please Enter Vehicle No',
												}
											}
										)}
									</div>
								</div>
							</form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={onDriveSubmit}>
								Submit
							</Button>
							<Button variant="secondary" onClick={driveBackToCart}>
								Back To Order Mode
							</Button>
						</Modal.Footer>
					</Modal> */}

					<Modal className='dialog-screen modal-dialog1 schedule-modal' size="lg" show={driveThruModal} onHide={driveBackToCart}>
						<Modal.Header style={{ background: '#c1272d' }} closeButton>
							<Modal.Title>
								<img src={driveIcons} width="70px"></img> &nbsp;&nbsp;&nbsp;<label >Drive-Thru</label></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form>
								<div className="form-group row">
									<label for="staticEmail" className="col-sm-3 col-form-label" style={{ paddingTop: '25px' }}>Vehicle Model</label>
									<div className="col-sm-7">
										<input type="checkbox"></input>
										<Space size={"small"}>
											<input style={{ opacity: '10' }} name='vehicleModel' type="checkbox" checked={driveDetails.vehicleModel == "Car"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></input>&nbsp;&nbsp;
											<img src={driveImg} width="210px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></img>&nbsp;&nbsp;
											<input name='vehicleModel' style={{ opacity: '10' }} type="checkbox" checked={driveDetails.vehicleModel == "SUV"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "SUV" }) }}></input>&nbsp;&nbsp;
											<img src={suvRock} width="200px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "SUV" }) }}></img>&nbsp;&nbsp;
											<input name='vehicleModel' style={{ opacity: '10' }} type="checkbox" checked={driveDetails.vehicleModel == "Truck"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Truck" }) }}></input>
											<img src={truckImg} width="255px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Truck" }) }}></img>&nbsp;&nbsp;
										</Space>
										<Space size={"small"}>
											<label style={{ marginLeft: '40px' }}>Car</label>
											<label style={{ marginLeft: '110px' }}>SUV</label>
											<label style={{ marginLeft: '105px' }}>Truck</label>
										</Space>
										{/* <div className="d-block">
											<div className="me-3 d-inline-block">
												<div className="d-flex">
													<div>
														<input className="me-3" style={{ opacity: '10' }} type="checkbox" checked={driveDetails.vehicleModel == "Car"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></input>
													</div>
													<div>
														<img className="m-0 p-0" src={driveImg} width="100px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></img>&nbsp;&nbsp;
													</div>
												</div>
												<label style={{ marginLeft: '30px', marginTop: '5px' }} className="d-block p-0 ">Car</label>
											</div>
											<div className="me-3 d-inline-block">
												<div className="d-flex">
													<input className="me-3" style={{ opacity: '10' }} type="checkbox" checked={driveDetails.vehicleModel == "SUV"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></input>
													<img className="m-0 p-0" src={suvRock} width="100px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "SUV" }) }}></img>&nbsp;&nbsp;
												</div>
												<label style={{ marginLeft: '30px', marginTop: '5px' }} className="d-block p-0 ">SUV</label>
											</div>
											<div className="me-3 d-inline-block">
												<div className="d-flex">
													<input className="me-3" style={{ opacity: '10' }} type="checkbox" checked={driveDetails.vehicleModel == "Truck"} onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Car" }) }}></input>&nbsp;&nbsp;
													<img className="m-0 p-0" src={truckImg} width="130px" onClick={(e) => { setdriveDetails({ ...driveDetails, vehicleModel: "Truck" }) }}></img>&nbsp;&nbsp;
												</div>
												<label style={{ marginLeft: '30px', marginTop: '5px' }} className="d-block p-0 ">Truck</label>
											</div>
										</div> */}
										{driveValidator.current.message(
											'vehicleModel',
											driveDetails.vehicleModel,
											'required',
											{
												className: `invalid_feedback 
														  ${driveFormSubmit ? 'show' : 'hide'}`,
												messages: {
													required: 'Please Enter Vehicle Model',
												}
											}
										)}
									</div>
								</div>
								<div className="form-group row">
									<label for="staticEmail" className="col-sm-3 col-form-label">Vehicle Color</label>
									<div className="col-sm-8">
										{vehicleColors && vehicleColors.length ? vehicleColors.map((item, i) => {
											console.log(item, 'item00==');
											return (
												<Space size={"small"}>
													<input style={{ opacity: '10' }} type="checkbox" checked={item.colorName == driveDetails.vehicleColor} onClick={(e) => { onVehicleColor(item, e.target.checked) }}></input>&nbsp;
													<Button style={{ background: `${item.colorCode}`, height: '50px', width: '50px', marginRight: '12px', marginBottom: '10px', borderColor: 'black', border: '2px solid black' }} onClick={(e) => { onVehicleColor(item) }} ></Button>
												</Space>
											)
										}) : ''
										}
										{
											driveValidator.current.message(
												'vehicleColor',
												driveDetails.vehicleColor,
												'required',
												{
													className: `invalid_feedback 
														  ${driveFormSubmit ? 'show' : 'hide'}`,
													messages: {
														required: 'Please Choose Vehicle Color',
													}
												}
											)
										}
									</div>
								</div>
							</form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={onDriveSubmit}>
								Submit
							</Button>
							<Button variant="secondary" onClick={driveBackToCart}>
								Back To Order Mode
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal className='dialog-screen modal-dialog1 schedule-modal' size="lg" show={scheduleModal} onHide={scheduleBackToCart}>
						<Modal.Header style={{ background: '#c1272d' }} closeButton>
							<Modal.Title><img src={scheduleIcon} width="60px"></img> &nbsp;&nbsp;&nbsp;&nbsp;Scheduled Pick Up Order</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{/* <a href="javascript:void(0);"><span style={{ fontSize: '18px', fontWeight: '500', paddingTop: '20px' }}>{dayFormat}&nbsp;&nbsp;{dateFormat}</span><br /></a> */}

							<div className='row' style={{ paddingTop: '20px' }}>
								<div className='time-wrapper d-flex'>
									<span style={{ fontWeight: '500', fontSize: '23px' }}>Schedule Time :</span>&nbsp;&nbsp;
									<div className='select-time ml-3'>
										<label htmlFor='time-pick mb-0 pb-0' className='time-icon'><i className="fa fa-clock-o" aria-hidden="true"></i></label> &nbsp;
										{/* <label htmlFor='time-pick' className='time-icon'><i className="fa fa-clock-o" aria-hidden="true"></i></label> */}
										<select id='time-pick' name="time_schedule" placeholder='Schedule Time' value={scheduleOrder.schedulePickup_time} onChange={(e) => {
											console.log(e.target.value, 'e.target.value');
											setscheduleOrder({ ...scheduleOrder, schedulePickup_time: e.target.value })
										}} style={{ width: '300px' }} >
											<option value={''}>-- Select Time --</option>
											{scheduleTimeSlot && scheduleTimeSlot.map((item, i) => {
												return (
													<option key={i} value={item}
														disabled={disableTimeSlot.some(exist => { return item == exist })}
													>{item}</option>
												)
											})}
										</select>
										{scheduleValidator.current.message(
											'time_schedule',
											scheduleOrder.schedulePickup_time,
											'required',
											{
												className: `invalid_feedback 
														  ${scheduleFormSubmit ? 'show' : 'hide'}`,
												messages: {
													required: 'Please choose Time slot',
												}
											}
										)}
									</div>
								</div>
							</div>

							{/* <div className='row' style={{ paddingTop: '20px' }}>
								<div style={{ textAlign: 'center', paddingTop: '15px' }}>
									<div style={{ paddingTop: '15px' }}>
										<p style={{ fontWeight: '500' }}>Choose Order Mode</p>
									</div>
								</div>
								<div style={{ textAlign: 'center' }}>
									<div style={{ paddingTop: '15px' }}>
										<img src={driveIcons} width="70px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
										<Button variant="outline-success" size="lg" style={{ width: '300px' }} onClick={() => { setOrderModeType('drive') }}>
											Drive-Thru
										</Button><br></br>
									</div>
									<div style={{ paddingTop: '20px' }}>
										<img src={pickup} width="70px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
										<Button variant="outline-warning" size="lg" style={{ width: '300px' }} onClick={() => { setOrderModeType('pickup') }}>
											Pick Up Order
										</Button>
									</div>
								</div>
							</div> */}

						</Modal.Body>
						<Modal.Footer>

							<Button variant="primary" onClick={onScheduleSubmit}>
								Submit
							</Button>
							<Button variant="secondary" onClick={scheduleBackToCart}>
								Back
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal className='dialog-screen modal-dialog1 ' size="lg" show={false} onHide={'Choose Order Mode to Proceed Checkout'}>
						<Modal.Header closeButton={false}>
							<Modal.Title><img src={pickup} width="70px"></img> &nbsp;&nbsp;&nbsp;<label style={{ color: 'black' }}>Pick Up Order</label></Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ minHeight: '250px' }}>
							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<div style={{ paddingTop: '15px' }}>
									<img src={hereIcone} width="85px"></img> &nbsp;&nbsp;&nbsp;&nbsp;
									<Button variant="outline-warning" size="lg" style={{ width: '300px' }} onClick={pickUpSubmit}>
										I'm Here
									</Button><br></br>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal className='dialog-screen modal-dialog1 payment-modal' size="lg" style={{ maxWidth: '600px !important' }} show={paymentOrderModal} >
						{/* <Modal.Header style={{ background: '#c1272d' }} closeButton={false}>
							<Modal.Title><label style={{ color: 'white' }}>Confirm Order</label>&nbsp;<img src={questionIcon} width="50px" height="50px"></img></Modal.Title>
						</Modal.Header> */}
						<Modal.Body style={{ minHeight: '200px' }}>
							<div style={{ textAlign: 'center', paddingTop: '5px' }}>
								<h2>Confirm Order&nbsp;&nbsp;<img src={questionIcon} width="40px" height="40px"></img></h2>
								<div style={{ paddingTop: '15px' }}>
									<p>{disclaimerText}</p>
									<div className='row'>
										<div className='col-md-6'>
											<div className="form-group">
												<form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE : "https://testpayments.worldnettps.com/merchant/paymentpage"} method="post">
													<input type="hidden" name="TERMINALID" value={process.env.REACT_APP_TERMINAL} />
													<input type="hidden" name="ORDERID" value={createOrderDatas} />
													<input type="hidden" name="CURRENCY" value="USD" />
													<input type="hidden" name="PAGES" value="ORDER" />
													<input type="hidden" name="AMOUNT" value={(finalTotal > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)} />
													<input type="hidden" name="DATETIME" value={moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
													<input type="hidden" name="HASH" value={CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${createOrderDatas}:${(finalTotal > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
													<input className="btn button-lg btnhover" style={{ width: '250px' }} type={(finalTotal || orderTotal > 0) ? "submit" : "button"} value={'Confirm'} />
												</form>
											</div>
										</div>
										<div className='col-md-6'>
											<Button variant="btn button-lg btnhover btn-danger" style={{ width: '250px' }} onClick={() => { setpaymentOrderModal(false) }} >
												Cancel
											</Button>
										</div>
									</div>

									{/* `Pay $ ${(finalTotal > 0) ? parseFloat(finalTotal).toFixed(2) : parseFloat(orderTotal).toFixed(2)}` */}
									{/* <Button variant="outline-warning" size="lg" style={{ width: '300px' }} onClick={pickUpSubmit}>
										Confirm
									</Button><br></br> */}
								</div>
							</div>
						</Modal.Body>
					</Modal>

					{/* <Modal className='dialog-screen modal-dialog1' size="lg" show={showModal}>
						<Modal.Header style={{ backgroundColor: 'green' }}>
							<Modal.Title>Payment Successful</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Payment Successful. Transaction ID: <b>{TransactionID}</b>
						</Modal.Body>
						<Modal.Footer>
							<Link to='/' className="btn btn-secondary">
								Close
							</Link>
						</Modal.Footer>
					</Modal> */}
					{/* <Modal className='dialog-screen modal-dialog1' size="lg" show={showCashlessModal}>
						<Modal.Header style={{ backgroundColor: 'green' }}>
							<Modal.Title>Your Order has been Confirmed!</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Your Order ID : &nbsp;<b>{createOrderDatas}</b>
						</Modal.Body>
						<Modal.Footer>
							<Link to='/' className="btn btn-secondary">
								Close
							</Link>
						</Modal.Footer>
					</Modal> */}
				</div >

			</div >

			<Footer />
		</>
	)

}

export default Checkout;