import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Button, Modal } from "react-bootstrap";
import { productData,addToCart, updateToCart, viewCartData, ProductSelector } from '../../store/reducer/product';
import { viewFavData } from '../../store/reducer/favourite';
import { custommenuData, custommenuSelector } from '../../store/reducer/custommenu';
import { itemsizeData, itemsizeSelector } from '../../store/reducer/itemsize';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import { Tabs, Checkbox } from 'antd';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { getPosData, subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import { favouriteSelector } from '../../store/reducer/favourite';
import moment from 'moment'
import { Alert } from 'antd';
import { addToFavourite, profileSelector, removeFavourite } from '../../store/reducer/profile';
import { doLogin, forgotPwd, loginSelector } from "../../store/reducer/login";
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import { API_STATUS } from '../../utils/constants';
import { generalData, generalSelector } from "../../store/reducer/general";
import 'moment-timezone';

function FavoriteProduct() {

	let { id } = useParams();
	let { product_id } = useParams();
	id = EncryptDecrypt.decryptdata(id, secretKey);
	product_id = EncryptDecrypt.decryptdata(product_id, secretKey);
	// cartid = EncryptDecrypt.decryptdata(cartid, secretKey);
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	let temp_token = localStorage.getItem('temporaryToken');
	const { generalDatas } = useSelector(generalSelector);
	const [timezone, setTimezone] = useState('America/Chicago');

	const dispatch = useDispatch();
	const { productDatas, viewcartDatas,cartLoading,cartMessage } = useSelector(ProductSelector);
	const { viewfavdatas } = useSelector(favouriteSelector);
	const [MenuDetails, setMenuDetails] = useState({ id: product_id });
	const [ItemSizeDetails, setItemSizeDetails] = useState({ id: product_id });
	const [totalCost, setTotalCost] = useState(0);
	const [num, setNum] = useState(1);
	const [toppins, setToppins] = useState(0);
	const [toppinID, setToppinID] = useState([]);
	const [chosenItems, setChosenItems] = useState([]);
	const [defaultItems, setDefaultItems] = useState(0);
	const [selectedPrice, setselectedPrice] = useState(1);
	const [Tag, setTag] = useState('');
	const [defaultTags, setDefaultTags] = useState('');
	const [showCustommenuItem, setshowCustommenuItem] = useState(false);
	const [selectedDatas, setselectedDatas] = useState([]);
	const [rowcheckbox, setrowcheckbox] = useState([]);
	const { custommenuDatas } = useSelector(custommenuSelector);
	const { itemsizeDatas, itemsizePrice } = useSelector(itemsizeSelector);
	const [defaultSelect, setDefaultSelect] = useState([]);
	// const [customerror, setcustomerror] = useState(false);
	const [checked, setChecked] = useState(false);
	const [finalCartProduct, setFinalCartProduct] = useState({ product_id: "", quantity: 0, product_price: 0, item_price: 0, item_ids: [], total_cost: 0, status: 1, default_size: '', default_size_name: '', product_zeroprice: '' });
	const [MenuItems, setMenuItems] = useState([]);
	const [defaultSizeOptions, setDefaultSizeOptions] = useState([]);
	const [fromTime, setFromTime] = useState(0);
	const [toTime, setToTime] = useState(0);
	const { posDatas } = useSelector(subcategoryProductSelector);
	const [showValidationMessage, setshowValidationMessage] = useState('');
	const [Title, setTitle] = useState('');
	const [itemVariationCost, setItemVariationCost] = useState([]);
	const [showLoginModal, setshowLoginModal] = useState(false);
	const [passwordMessage, setpasswordMessage] = useState('')
	const [emailMessage, setemailMessage] = useState('')
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const { loading, forgotloading, errorMessage } = useSelector(loginSelector);
	const [PasswordType, setPasswordType] = useState('password');
	const [loginDetails, setLoginDetails] = useState({ email: "", password: "", submitted: false, loading: false });
	const [useEffectRender, setuseEffectRender] = useState(false);
	const [forgotDetails, setForgotDetails] = useState({ email: "" });
	const [slashPrice, setslashPrice] = useState(1);
	const [zeroProductValue, setZeroProductValue] = useState(0)
	const [favCartProduct, setFavCartProduct] = useState({ product_id: "", quantity: 0, product_price: 0, item_price: 0, item_ids: [], total_cost: 0, status: 1 });

	console.log(viewfavdatas, 'viewfavdatas');
	console.log(id, 'id');
	console.log(product_id, 'product_id');
	console.log(showCustommenuItem, 'showCustommenuItem');
	console.log(custommenuDatas, 'custommenuDatas');
	console.log(defaultTags,'defaultTags');

	const ProductDispatch = (id) => {
		console.log(id, 'ids');
		dispatch(
			productData({
				// query: searchQuery,
				// page: page + 1,
				// limit: rowsPerPage,
				// sortby: sortBy,
				// order: order,
				productid: id
			})

		);
		console.log(id, 'beforeids');

	};

	const setOnlinePrice = (Productprice) => {
		const slashprice = (Number(Productprice) * (1 - (Number(generalDatas && generalDatas[0].tax) / 100)))
		setslashPrice(slashprice)
		console.log(slashprice, 'slashprice==');
		const priceAfterDiscount = (Number(slashprice) * (1 - (Number(generalDatas && generalDatas[0].discount) / 100)))
		console.log(priceAfterDiscount, 'slashprice--priceAfterDiscount==');
		return priceAfterDiscount ? priceAfterDiscount : 0
	}

	console.log(MenuDetails, 'MenuDetails');

	const CustomMenuDispatch = (id) => {
		let postData = MenuDetails;

		dispatch(
			custommenuData({ postData })

		);

	};

	const ItemDispatch = (id) => {
		let postData = ItemSizeDetails;
		dispatch(
			itemsizeData({ postData })
		);

	};

	// const ViewCartDispatch = (cartid) => {
	// 	dispatch(
	// 		viewCartData({ cart_id: cartid })
	// 	);

	// };

	const ViewFavDispatch = (id) => {
		console.log(id, 'viewId');
		dispatch(
			viewFavData({ id: id })
		);

	};

	const PosDispatch = () => {
		dispatch(
			getPosData({})
		);
	};


	const submitHandler = (e) => {
		e.preventDefault();
	};

	const onCloseLoginCart = () => {
		setshowLoginModal(false);
	}

	const onLoginSubmit = () => {

		setAddFormSubmit(true)
		let postData = loginDetails;
		postData.temp_token = temp_token
		postData.is_website = 1
		console.log(postData, 'postData')
		setemailMessage('')
		setpasswordMessage('')
		let errors = [];

		if (postData.email == '') {
			errors.push('Email');
			setemailMessage('Email is required.!')
		}
		if (postData.password == '') {
			errors.push('Password');
			setpasswordMessage('Password is required.!')

		}

		if (errors.length > 0) {
			// toast.error(errors.toString() + ' is required.!');
			return false;
		}

		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(postData.email)) {
		} else {
			setemailMessage('Please enter the vaild email.!')

			//toast.error(' Please enter the vaild email .!');
			return false;
		}

		dispatch(doLogin({ postData }));
	};

	useEffect(() => {

		if (loading === API_STATUS.FULFILLED) {
			setshowLoginModal(false);
			toast.dismiss();
			toast.success("Logged-in successfully.!");

			dispatch(
				addToFavourite({ product_id: id, status: 1 })
			);

			setInterval(() => {
				window.location.reload()
			}, 1000);
		}

		if (loading === API_STATUS.REJECTED) {
			toast.dismiss();
			setshowLoginModal(true);
			// setpasswordMessage(errorMessage)
			// toast.error(errorMessage);
			if (errorMessage == 'login.invalidPassword') {
				setpasswordMessage('Invalid Password!')
			}
			if (errorMessage == 'Email not found') {
				setemailMessage(errorMessage)
			}
		}


	}, [loading]);

	useEffect(() => {
		console.log(cartLoading, "loading")
		if (cartLoading === API_STATUS.FULFILLED) {
			setshowCustommenuItem(false);
			toast.dismiss();
			toast.success('Product added to cart successfully.!');
			// window.setTimeout(function () {
			// 	window.location.reload();
			// }, 2000);
			window.location.href = '/'
		}
		if (cartLoading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (cartLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(cartMessage);
			setshowCustommenuItem(false);
		}
	}, [cartLoading]);

	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const onForgotSubmit = () => {
		let forgotData = forgotDetails;
		console.log(forgotData, "forgotData")


		if (forgotData.email == '') {
			toast.error(' Please enter the email .!');
			return false;
		}

		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgotData.email)) {
		} else {
			toast.error(' Please enter the vaild email .!');
			return false;
		}

		dispatch(forgotPwd({ forgotData }));
	};

	useEffect(() => {
		// console.log(forgotloading,"forgotloading")
		if (forgotloading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Password reset link sent to your registered email.!");
			//   history.push('/login');
			setshowLoginModal(false);
		} else if (forgotloading === API_STATUS.PENDING) {
			console.log('erorr')

			toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (forgotloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error('Email not found.');
		}
	}, [forgotloading]);

	const togglePassword = () => {
		if (PasswordType == 'text') {
			setPasswordType('password')
		} else {
			setPasswordType('text')

		}
	}

	useEffect(() => {
		ProductDispatch(product_id)
		CustomMenuDispatch(product_id)
		ItemDispatch(product_id)
		// ViewCartDispatch(cartid)
		console.log(id, 'iiiddddd');
		ViewFavDispatch(id)
		PosDispatch()
	}, [])

	useEffect(() => {

		if (viewfavdatas) {
			setFinalCartProduct({
				...finalCartProduct,
				product_id: viewfavdatas[0].product_id,
				quantity: viewfavdatas[0].quantity,
				product_price: viewfavdatas[0].product_price,
				item_price: viewfavdatas[0].item_price.split(','),
				item_ids: viewfavdatas[0].item_ids.split(','),
				total_cost: viewfavdatas[0].total_cost,
				default_size: viewfavdatas[0].default_size,
				default_size_name: viewfavdatas[0].default_size_name,
				status: 1

			})
			
			const defaultWords = viewfavdatas[0]?.item_names?.split(',').map(word => (word.trim()));
			const idsArray = viewfavdatas[0]?.item_ids?.split(',');
			const valuesArray = viewfavdatas[0]?.item_names?.split(',');
			const pairedData = idsArray.map((id, index) => ({
				menu_id: Number(id.trim()),
				menu_name: valuesArray[index].trim()
			}));

			console.log(pairedData,'pairedData');
			// formattedData[0] = wordsArray;
			// viewfavdatas.items.forEach(function (carts) {
			// 	tagArray.push({ menu_name: carts.name, menu_id: carts.id });
			// 	if (carts.name) {
			// 		defaulTagData.push(carts.name)
			// 	}
			// });
			setTag(pairedData)
			setDefaultTags(defaultWords.toString())

			if (viewfavdatas[0].default_custom == 1) {
				setTitle('Default')
			} else {
				setTitle('Customized')
			}

			// setTotalCost(viewcartDatas.data[0].total_cost)
			setuseEffectRender(true)
			// setselectedPrice(finalCartProduct.product_price)
			console.log(viewcartDatas, 'viewcartDatas')
		}

	}, [viewfavdatas])

	useEffect(() => {

		// if(finalCartProduct){
		setTotalCost(finalCartProduct.total_cost)
		// setZeroProductValue(finalCartProduct.total_cost)
		setToppins(finalCartProduct.item_price)
		setToppinID(finalCartProduct.item_ids)
		setNum(finalCartProduct.quantity)
		let ArraySelect = []
		let rowSelect = []
		let menus = []
		let autoFetch = []
		let total = []

		if (custommenuDatas && custommenuDatas.length) {

			for (let check = 0; check < custommenuDatas.length; check++) {

				let rowdata = custommenuDatas[check];
				if (rowdata && rowdata?.custom_menu_items.length) {

					for (let index = 0; index < rowdata?.custom_menu_items.length; index++) {

						console.log(rowdata?.custom_menu_items, rowdata?.custom_products, 'rowdata?.custom_menu_items');

						//console.log(rowdata?.custom_menu_items[index], 'rowdata?.custom_menu_items')

						let checkboxData = finalCartProduct.item_ids.indexOf(rowdata?.custom_menu_items[index].id.toString());

						console.log(checkboxData, 'checkboxData');
						if (checkboxData > -1) {
							let selectArray = rowdata?.custom_menu_items[index];
							let productPrice = rowdata?.custom_products.find(val => val.itemsize_id == defaultItems && val.custom_item_id == selectArray.id)
							console.log(selectArray, defaultItems, selectArray.itemsize_id == defaultItems, 'selectArray-selectArray');

							ArraySelect.push({ item_name: selectArray.name, custom_menu_id: selectArray.custom_menu_id, item_id: selectArray.id, price: [], item_price: productPrice && productPrice.price, itemsize_id: [], default: 0 });

							console.log(ArraySelect, 'selectArray-ArraySelect');
							// if(rowcheckbox.length)
							if (Array.isArray(rowSelect[check])) {
								rowSelect[check].push(index)
							} else {
								rowSelect[check] = [index]
							}
							// else
							// 	rowcheckbox[index] = [check];


						}

						// for (let index1 = 0; index1 < finalCartProduct.item_ids.length; index1++) {



						// 	if(rowdata?.custom_menu_items[index].id == Number(finalCartProduct.item_ids[index1])){
						// 		let selectArray = rowdata?.custom_menu_items[index];

						// console.log(check, index, rowdata?.custom_menu_items[index].id,  Number(finalCartProduct.item_ids[index1]),'checking' )

						// 		console.log(rowcheckbox[check], 'test');
						// 		ArraySelect.push({ item_name: selectArray.name, custom_menu_id: selectArray.custom_menu_id, item_id: selectArray.id, price: [],  item_price: selectArray.price , itemsize_id: [], default: 0 });

						// 		rowcheckbox[check] = [index];
						// 		console.log(rowcheckbox, 'updated rowcheckbox');
						// 	}
						// }


					}
					// 					let selectArray = rowdata?.custom_menu_items.filter(function (element) {
					// 						return element.id === Number(finalCartProduct.item_ids[check]);
					// 					});
					// 	console.log(selectArray, 'selectArray');
					// 					if(selectArray.length){
					// // rowcheckbox[check] = 
					// 						// ArraySelect.push({ item_name: selectArray[0].name, custom_menu_id: selectArray[0].custom_menu_id, item_id: selectArray[0].id, price: selectArray[0].price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
					// 					}

				}
			}
			// console.log(rowcheckbox, 'rowcheckboxrowcheckbox');
			// setrowcheckbox([...rowcheckbox])
			// setrowcheckbox([...rowcheckbox]);
			// console.log(rowcheckbox, 'rowcheckbox ArraySelect');
			// console.log(ArraySelect, 'ArraySelect');

			for (let index = 0; index < custommenuDatas.length; index++) {
				let menus1 = custommenuDatas[index]?.custom_products.map((products, selectedRow) => {
					if (products.default_select === 1) {
						autoFetch.push(products.custom_item_id);
						if (menus.indexOf(products.custom_item_id) > -1)
							total.push(products.price);

					}
					menus.push(products.custom_item_id);
					return true;
				});
			}
			setItemPrice();
			setshowCustommenuItem(true)
		}

		setrowcheckbox([...rowSelect])

		if (menus.length) {
			setMenuItems(menus)
			setDefaultSizeOptions(autoFetch)
		}

		if (ArraySelect.length)
			setselectedDatas([...ArraySelect])

		// setrowcheckbox(finalCartProduct.item_ids)
		setDefaultItems(finalCartProduct.default_size)
		// let productPrice = setOnlinePrice(finalCartProduct.product_price)
		let priceTax = (Number(finalCartProduct.product_price) / 0.80)
		console.log(priceTax, 'priceTax555');
		priceTax = (priceTax / 0.88)
		// const slashprice = (Number(Productprice) * (1 - (Number(generalDatas && generalDatas[0].tax) / 100)))
		let productPrice = setOnlinePrice(priceTax)
		setselectedPrice(finalCartProduct.product_price)

		// }
	}, [finalCartProduct, custommenuDatas])

	// useEffect(() => {
	// 	if (custommenuDatas) {

	// 		let menus = []
	// 		let autoFetch = []
	// 		let total = []
	// 		for (let index = 0; index < custommenuDatas.length; index++) {
	// 			let menus1 = custommenuDatas[index]?.custom_products.map((products, selectedRow) => {
	// 				if (products.default_select === 1) {
	// 					autoFetch.push(products.custom_item_id);
	// 					if (menus.indexOf(products.custom_item_id) > -1)
	// 						total.push(products.price);

	// 				}
	// 				menus.push(products.custom_item_id);
	// 				return true;
	// 			});
	// 		}


	// 		if (menus.length) {
	// 			setMenuItems(menus)
	// 			setDefaultSizeOptions(autoFetch)
	// 		}

	// 	}
	// }, [custommenuDatas])

	setTimeout(() => {
		if (viewFavData) {
			if (viewFavData.length == 0) {
				window.location.href = '/'
			}
		}
	}, 500);
	useEffect(() => {
		// setNum(num)

		if (productDatas && productDatas.data) {
			if (productDatas.data[0].price) {
				if (productDatas.data[0].price[0]) {
					if (custommenuDatas && custommenuDatas.length) {
						let itemprice = 0
						if (toppins.length == 1) {
							itemprice = toppins.reduce((a, b) => Number(a) + Number(0));
						} else if (toppins.length > 1) {
							itemprice = toppins.reduce((a, b) => Number(a) + Number(b));
						}
						// setTotalCost((selectedPrice * num)+Number(itemprice));
						setTotalCost((Number(selectedPrice) + Number(itemprice)) * num);
					} else {
						setTotalCost(Number(selectedPrice) * num);
					}
				}
			}

		}

		// setTotalCost(num * totalCost)
	}, [num])

	useEffect(() => {
		// subcategoryProductDispatch(id)
		if (productDatas) {

			if (productDatas.data[0].status == 0 || productDatas.data[0].trash == 1) {
				toast.error('Product is inactive or not found.! ')

				window.setTimeout(function () {
					window.location.href = '/'
				}, 2000);

				return false;

			}

			if (userDetails?.id) {
				setChecked(productDatas.favourite);
			}

			setTotalCost(finalCartProduct.total_cost);
			// setZeroProductValue(finalCartProduct.total_cost)
			setDefaultItems(productDatas.data[0].default_size)

			for (var i = 0; i < productDatas.data[0].price.length; i++) {

				if (productDatas.data[0].price[i].itemsize_id == productDatas.data[0].default_size) {

					if (custommenuDatas && custommenuDatas.length) {
						let additems = 0;
						if (toppins.length == 1) {
							additems = toppins.reduce((a, b) => Number(a) + Number(0));
						} else if (toppins.length > 1) {
							additems = toppins.reduce((a, b) => Number(a) + Number(b));
						}

						let productPrice = setOnlinePrice(productDatas.data[0].price[i].price)

						// setselectedPrice(productDatas.data[0].price[i].price)
						setselectedPrice(productPrice)


						// setTotalCost((Number(productDatas.data[0].price[i].price) + Number(additems)) * num);
						setTotalCost((productPrice + Number(additems)) * num);
						// setZeroProductValue((productPrice + Number(additems)) * num)
						break;
					} else {
						let productPrice = setOnlinePrice(productDatas.data[0].price[i].price)
						setselectedPrice(productPrice)
						setTotalCost((productPrice * num))
						// setZeroProductValue((productPrice * num))
						// setselectedPrice(productDatas.data[0].price[i].price)
						// setTotalCost(Number(productDatas.data[0].price[i].price) * num);
						break;
					}
				}
			}

			//setselectedPrice(productDatas.data[0].price[productDatas.data[0].default_size].price)
		}

	}, [productDatas])

	// const addFavourite = (e) => {

	// 	// signing with favourites

	// 	var value = (!checked) ? 1 : 0


	// 	if (userDetails?.id) {
	// 		setChecked(value)
	// 		// localStorage.setItem('addtowishlist' + id, value)
	// 		dispatch(
	// 			addToFavourite({ product_id: id, status: value })
	// 		);
	// 	} else {
	// 		setshowLoginModal(true);
	// 	}
	// 	// }

	// }

	const addFavourite = (type) => {
		var value = (!checked) ? 1 : 0

		if (userDetails?.id) {
			setChecked(value)
			// localStorage.setItem('addtowishlist' + id, value)

			let postData = favCartProduct
			postData.product_id = id
			postData.quantity = num
			postData.product_price = Number(selectedPrice)
			postData.item_price = (toppins) ? toppins.toString() : 0
			postData.item_ids = (toppinID) ? toppinID.toString() : ''
			postData.total_cost = (toppins) ? totalCost : (num * selectedPrice)
			postData.default_size = defaultItems
			postData.default_custom = Title == 'Default' ? 1 : 0;

			let defaultname = []

			for (let index1 = 0; index1 < itemsizeDatas?.data.length; index1++) {

				if (itemsizeDatas.data[index1].id == defaultItems) {

					defaultname.push(itemsizeDatas.data[index1].code)
				}
			}

			postData.default_size_name = (defaultname.length) ? defaultname[0] : '';
			postData.item_names = (defaultTags) ? defaultTags.toString() : ''
			postData.description = productDatas.data[0].description

			console.log(value, 'value');
			let postData1 = { id: Number(id) }
			if (value == 0) {
				dispatch(removeFavourite({ postData1 }));
			}
			else {
				console.log(id, 'id5555');
				dispatch(addToFavourite({ postData }));

			}

			// window.setTimeout(function () {
			// 	window.location.href = '/cartlist'
			// }, 1000);
		}
		else {
			setshowLoginModal(true);
		}
		// setshowCustommenuItem(false);



	}

	const toggleChecked = (e) => {
		setDefaultItems(e.target.value);

		if (custommenuDatas && custommenuDatas.length) {
			let filterSelectedID = selectedDatas && selectedDatas.length && selectedDatas.map((item) => {
				return item.item_id
			})

			let Selectedtoppin = [];
			if (filterSelectedID.length) {

				for (let index = 0; index < custommenuDatas.length; index++) {

					let defaultSelectedProducts = custommenuDatas[index];
					let data = defaultSelectedProducts?.custom_products.map((checkProducts) => {
						filterSelectedID.map((item) => {
							if (item == checkProducts.custom_item_id && checkProducts.itemsize_id == e.target.value) {
								Selectedtoppin.push(Number(checkProducts.price))
							}
						})
					})
				}

				setToppins([...Selectedtoppin])
			}

			let itemVariationPrice = [];
			for (var i = 0; i < productDatas.data[0].price.length; i++) {
				if (productDatas.data[0].price[i].itemsize_id == e.target.value) {
					let productPrice = setOnlinePrice(productDatas.data[0].price[i].price)
					// setselectedPrice(productDatas.data[0].price[i].price)
					setselectedPrice(productPrice)
					let additems = 0;
					if (Selectedtoppin.length == 1) {
						additems = Selectedtoppin.reduce((a, b) => Number(a) + Number(0));
					} else if (Selectedtoppin.length > 1) {
						additems = Selectedtoppin.reduce((a, b) => Number(a) + Number(b));
					}
					setTotalCost((productPrice * num) + Number(additems) * num)
					// setZeroProductValue((productPrice * num) + Number(additems) * num)

					// setTotalCost((Number(productDatas.data[0].price[i].price) * num) + Number(additems) * num)
					break;
				}
			}
			for (let index = 0; index < custommenuDatas.length; index++) {
				let itemVariationData = custommenuDatas[index];
				let data = itemVariationData?.custom_products.map((variantProducts) => {
					console.log(variantProducts.custom_item_id, variantProducts.itemsize_id, e.target.value, 'itemVariationID--map');
					if (variantProducts.itemsize_id == e.target.value) {
						itemVariationPrice.push({ menu_id: variantProducts.custom_item_id, item_name: variantProducts.item_name, price: Number(variantProducts.price), itemSizeId: e.target.value })
					}
				})
			}
			setItemVariationCost([...itemVariationPrice])
		} else {
			for (var i = 0; i < productDatas.data[0].price.length; i++) {
				if (productDatas.data[0].price[i].itemsize_id == e.target.value) {
					let productPrice = setOnlinePrice(productDatas.data[0].price[i].price)
					// setselectedPrice(productDatas.data[0].price[i].price)
					setselectedPrice(productPrice)
					// setTotalCost(Number(productDatas.data[0].price[i].price) * num);
					setTotalCost(productPrice * num);
					// setZeroProductValue(productPrice * num);
					break;
				}
			}
		}
	};

	useEffect(() => {
		console.log(checked, 'checked')
	}, [checked, rowcheckbox])

	//zeroProduct Check

	useEffect(() => {
		if (num > 1) {
			console.log('hiii');
			let newVal = totalCost / num;
			console.log(newVal, 'newVal');
			setZeroProductValue(newVal)
		}
		else {
			setZeroProductValue(totalCost)
		}
	}, [num, totalCost])

	const onCloseCustommenuItem = () => {
		setshowCustommenuItem(false);
	}

	// const onSaveCustommenuItem = () => {
	// 	setshowValidationMessage('')
	// 	if (custommenuDatas && custommenuDatas.length) {
	// 		let minarray = productDatas.data[0].custom_menu_min.split(',');
	// 		let maxarray = productDatas.data[0].custom_menu_max.split(',');
	// 		let errors = []

	// 		for (let finalindex = 0; finalindex < minarray.length; finalindex++) {
	// 			//let finalminimum = minarray[finalindex]
	// 			//let finalmaximum = maxarray[finalindex]
	// 			let selectedIds = selectedDatas.map((item) => item.item_id)
	// 			let menuname = custommenuDatas.map((item) => item.name)

	// 			// let filterArray  = _.filter(custommenuDatas, { id: row.id });

	// 			for (let index = 0; index < custommenuDatas.length; index++) {
	// 				let datas_check = productDatas.data[0].custom_menu_ids.split(',');

	// 				let get_index = datas_check.indexOf(custommenuDatas[index].id.toString());

	// 				let finalminimum = minarray[get_index]
	// 				let finalmaximum = maxarray[get_index]
	// 				let customdata = custommenuDatas[index];
	// 				if (customdata?.custom_menu_items) {
	// 					let mindata = 0
	// 					for (let index = 0; index < customdata?.custom_menu_items.length; index++) {
	// 						if (selectedIds.indexOf(customdata?.custom_menu_items[index].id) > -1) {
	// 							mindata += 1;
	// 						}

	// 					}

	// 					if (mindata < finalminimum) {
	// 						errors.push(menuname[index]);
	// 					}
	// 				}
	// 			}

	// 		}

	// 		if (errors.length) {

	// 			let uniqueData = errors.filter(function (item, pos) {
	// 				return errors.indexOf(item) == pos;
	// 			})

	// 			setshowValidationMessage('Minimum item should be checked in ' + uniqueData.toString() + ' tab');
	// 			return false;
	// 		}
	// 	}
	// 	let postData = finalCartProduct
	// 	postData.product_id = id
	// 	postData.quantity = num
	// 	postData.product_price = selectedPrice
	// 	postData.item_price = toppins.toString()
	// 	postData.item_ids = toppinID.toString()
	// 	postData.total_cost = totalCost ? totalCost : 0
	// 	postData.item_names = (defaultTags) ? defaultTags.toString() : ''
	// 	postData.default_custom = Title == 'Default' ? 1 : 0;
	// 	postData.default_size = defaultItems;
	// 	postData.product_zeroprice = zeroProductValue ? zeroProductValue : '0'
	// 	let defaultname = []

	// 	for (let index1 = 0; index1 < itemsizeDatas?.data.length; index1++) {

	// 		if (itemsizeDatas.data[index1].id == defaultItems) {

	// 			defaultname.push(itemsizeDatas.data[index1].code)
	// 		}

	// 	}

	// 	postData.default_size_name = (defaultname.length) ? defaultname[0] : '';
	// 	postData.item_names = (defaultTags) ? defaultTags.toString() : ''
	// 	if (userDetails) {
	// 		//postData.created_by = userDetails?.id
	// 	} else {
	// 		// postData.created_by = ""
	// 		postData.temp_token = localStorage.getItem('temporaryToken')
	// 	}

	// 	setshowCustommenuItem(false);
	// 	toast.success('Cart product updated successfully.!');
	// 	dispatch(updateToCart({ postData }));

	// 	window.setTimeout(function () {
	// 		window.location.href = '/cartlist'
	// 	}, 3000);


	// }

	const onSaveCustommenuItem = (type) => {
		// setshowCustommenuItem(false);
		console.log(type, 'type--');
		if (custommenuDatas && custommenuDatas.length) {
			console.log('working---1');

			setshowValidationMessage('')

			let minimum;
			let get_max;

			let minarray = productDatas.data[0].custom_menu_min.split(',');
			let maxarray = productDatas.data[0].custom_menu_max.split(',');
			let errors = []

			for (let finalindex = 0; finalindex < minarray.length; finalindex++) {

				let selectedIds = selectedDatas.map((item) => item.item_id)
				let menuname = custommenuDatas.map((item) => item.name)

				// console.log(selectedIds, 'selectedIds');
				// console.log(itemsizeDatas, 'itemsizeDatas');
				// console.log(custommenuDatas, 'custommenuDatas');
				// console.log(menuname, 'menuname');
				// let filterArray  = _.filter(custommenuDatas, { id: row.id });

				for (let index = 0; index < custommenuDatas.length; index++) {

					let datas_check = productDatas.data[0].custom_menu_ids.split(',');

					let get_index = datas_check.indexOf(custommenuDatas[index].id.toString());

					let finalminimum = minarray[get_index]
					let finalmaximum = maxarray[get_index]

					let customdata = custommenuDatas[index];

					if (customdata?.custom_menu_items) {
						// let countdata = customdata?.custom_menu_items.map((items)=>{
						// 	console.log(items, 'items');
						// 	return selectedIds.indexOf(items.id) > -1
						// });
						let mindata = 0
						for (let index = 0; index < customdata?.custom_menu_items.length; index++) {
							if (selectedIds.indexOf(customdata?.custom_menu_items[index].id) > -1) {
								mindata += 1;
							}
						}

						if (mindata < finalminimum) {
							errors.push(menuname[index]);
						}

						// 					console.log(countdata, 'countdata');
						// 					console.log(countdata.length, 'countdata.length');
						// 					console.log(finalminimum, 'finalminimum');
						// 					if(countdata.length < finalminimum){
						// 					console.log(menuname[index], 'menuname index');
						// errors.push(menuname[index]);
						// toast.error('Minimum item should be checked in ' + menuname[index] + ' tab')
						// setshowCustommenuItem(false);
						// setshowValidationMessage('Minimum item should be checked in ' + menuname[index] + ' tab');
						// return false;
						// }
					}
				}

			}
			if (errors.length) {
				if (type) {
					toast.error('Custom Items should be choosed in Customize Tab!');
					return false;
				}

				let uniqueData = errors.filter(function (item, pos) {
					return errors.indexOf(item) == pos;
				})

				setshowValidationMessage('Minimum item should be checked in ' + uniqueData.toString() + ' tab');
				return false;
			}
		}

		let postData = finalCartProduct
		postData.product_id = product_id
		postData.quantity = num
		postData.product_price = selectedPrice;
		postData.item_price = (toppins) ? toppins.toString() : 0
		postData.item_ids = (toppinID) ? toppinID.toString() : ''
		postData.total_cost = (toppins) ? totalCost : (num * selectedPrice)
		postData.default_size = defaultItems
		postData.default_custom = Title == 'Default' ? 1 : 0;
		postData.product_zeroprice = zeroProductValue ? zeroProductValue : '0'

		let defaultname = []

		for (let index1 = 0; index1 < itemsizeDatas?.data.length; index1++) {

			if (itemsizeDatas.data[index1].id == defaultItems) {

				defaultname.push(itemsizeDatas.data[index1].code)
			}
		}

		postData.default_size_name = (defaultname.length) ? defaultname[0] : '';
		postData.item_names = (defaultTags) ? defaultTags.toString() : ''
		postData.description = productDatas.data[0].description
		postData.temp_token = localStorage.getItem('temporaryToken')

		if (userDetails) {
			postData.created_by = userDetails?.id
		} else {
			postData.created_by = ""
		}

		console.log(postData, 'postData');

		dispatch(addToCart({ postData }));

		// window.setTimeout(function () {
		// 	window.location.href = '/cartlist'
		// }, 1000);

	}

	const TabName = (row, index) => {
		const get_arr = _.filter(custommenuDatas, { name: row?.name });

		let minimum = 0;
		let maximum = 1;
		if (productDatas && productDatas.data) {
			let datas_check = productDatas.data[0].custom_menu_ids.split(',');
			let get_index = datas_check.indexOf(get_arr[0].id.toString());
			if (get_index >= 0) {
				let minarray = productDatas.data[0].custom_menu_min.split(',');
				let maxarray = productDatas.data[0].custom_menu_max.split(',');
				minimum = minarray[get_index]
				maximum = maxarray[get_index]
			}
		};


		if (get_arr.length > 0)
			return row?.name
		//  + " (Min:" + minimum + ", Max:" + maximum + ")";
		else
			return row?.name;
	}

	const changePrice = (e, index) => {
		const re = /^\d*\.?\d*$/
		if (e.target.value.match(re) || e.target.value == "") {
			chosenItems[index].price = e.target.value
			console.log(chosenItems);
			setChosenItems([...chosenItems]);
		}
	}

	const ItemPrice = (price, row_data, index, item) => {
		//console.log(row_data, price)
		if (row_data)
			return price;
		else {
			let data = selectedDatas;
			let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
			if (index_selected >= 0) {
				return data[index_selected].price[index];
			}
		}

	};

	const ChangeItemPrice = (e, index, item) => {
		const re = /^\d*\.?\d*$/
		if (e.target.value.match(re) || e.target.value == "") {
			let data = selectedDatas;
			let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
			console.log(index_selected, "data")
			if (index_selected >= 0) {
				data[index_selected].price[index] = e.target.value;
			}
			setselectedDatas([...data]);
			console.log(data, "data")
		}
	};

	const setItemPrice = (e) => {
		let defaultVariationPrice = [];
		for (let index = 0; index < custommenuDatas.length; index++) {
			let defaultProduct = custommenuDatas[index].custom_products
			if (defaultItems) {
				let defaultItemPriceCheck = defaultProduct && defaultProduct.length && defaultProduct.map((item) => {
					if (item.itemsize_id == defaultItems) {
						defaultVariationPrice.push({ menu_id: item.custom_item_id, price: Number(item.price), itemSizeId: item.itemsize_id })
					}
				})
			}
		}
		setItemVariationCost([...defaultVariationPrice])
	}

	const TagcustomRemove = (data) => {

		let rowCheckboxRemove = rowcheckbox;
		let selectedDataRemove = selectedDatas;


		for (let index = 0; index < custommenuDatas.length; index++) {
			let cusdata = custommenuDatas[index];

			if (cusdata?.custom_menu_items) {

				for (let newindex = 0; newindex < cusdata?.custom_menu_items.length; newindex++) {

					if (cusdata?.custom_menu_items[newindex].id == data.menu_id) {
						selectedDataRemove = selectedDataRemove.filter((item) => item.item_id != data.menu_id)
						rowCheckboxRemove[index] = rowCheckboxRemove[index].filter((item) => item != newindex)
					}
				}
			}
		}

		let checkSubarraylength;
		if (rowCheckboxRemove && rowCheckboxRemove.length > 0) {
			checkSubarraylength = rowCheckboxRemove.map((item) => {
				if (item && item.length) {
					return true
				}
				else {
					return false
				}
			})
		}
		let tagsRemove = []
		let toppinIDremove = [];
		let defaultArray = [];
		let topPriceRemove = [];
		if (checkSubarraylength.includes(true)) {
			selectedDataRemove.forEach((val, key) => {
				if (key == 0) {
					tagsRemove.push({ menu_name: val.item_name, menu_id: val.item_id });
					defaultArray.push(val.item_name)
					topPriceRemove.push(val.item_price)
					toppinIDremove.push(val.item_id)
				}
				else {
					tagsRemove.push({ menu_name: val.item_name, menu_id: val.item_id });
					defaultArray.push(" " + val.item_name)
					topPriceRemove.push(val.item_price)
					toppinIDremove.push(val.item_id)
				}
			})
			setTag([...tagsRemove])
			setrowcheckbox([...rowCheckboxRemove])
			setselectedDatas([...selectedDataRemove])
			setDefaultTags([...defaultArray])
			setToppins([...topPriceRemove]);
			setToppinID([...toppinIDremove]);
			let addtoppins = 0;
			if (toppinIDremove.length > 0) {
				addtoppins = topPriceRemove.reduce((a, b) => Number(a) + Number(b));
			}

			setTotalCost((Number(selectedPrice) + Number(addtoppins)) * num)
			// setZeroProductValue((Number(selectedPrice) + Number(addtoppins)) * num)
		}
		else {
			setrowcheckbox([])
			setselectedDatas([])
			setTag([])
			setDefaultTags([...defaultArray])
			setToppins([...topPriceRemove]);
			setToppinID([...toppinIDremove]);
			let addtoppins = 0;
			if (topPriceRemove.length == 1) {
				addtoppins = topPriceRemove.reduce((a, b) => Number(a) + Number(0));
			}

			if (topPriceRemove.length > 1) {
				addtoppins = topPriceRemove.reduce((a, b) => Number(a) + Number(b));
			}

			if (productDatas) {

				for (var i = 0; i < productDatas?.data[0].price.length; i++) {
					if (productDatas.data[0].price[i].itemsize_id == defaultItems) {
						let productPrice = setOnlinePrice(productDatas.data[0].price[i].price)

						//setselectedPrice(productDatas.data[0].price[i].price)
						setselectedPrice(productPrice)
						break;
					}
				}
			}

			setTotalCost((selectedPrice * num) + Number(addtoppins))
			// setZeroProductValue((selectedPrice * num) + Number(addtoppins))
		}
	}


	const selectItems = (row, tindex, index, item, e, itemVariationCost) => {

		let selectedItemPrice;
		itemVariationCost && itemVariationCost.length && itemVariationCost.map((priceID) => {
			if (priceID.menu_id == item.id) {
				selectedItemPrice = priceID.price
			}
			return true
		})

		let minimum;
		let get_max;
		let filterCustomSize = _.find(productDatas.data[0].custom_size, { itemsize_id: `${defaultItems}` })
		let datas_check = productDatas.data[0].category_id == 4 ?  filterCustomSize.custom_menu_id.split(','):productDatas.data[0].custom_menu_ids.split(',');
		// let datas_check = productDatas.data[0].custom_menu_ids.split(',');
		let get_index = datas_check.indexOf(row.id.toString());
		if (get_index >= 0) {
			let minarray = productDatas.data[0].category_id == 4 ? filterCustomSize.custom_min.split(',') : productDatas.data[0].custom_menu_min.split(',');
			let maxarray = productDatas.data[0].category_id == 4 ? filterCustomSize.custom_max.split(',') : productDatas.data[0].custom_menu_max.split(',');
			// let minarray = productDatas.data[0].custom_menu_min.split(',');
			// let maxarray = productDatas.data[0].custom_menu_max.split(',');
			minimum = minarray[get_index]
			get_max = maxarray[get_index]
		}

		// let minarray = productDatas.data[0].custom_menu_min.split(',');
		// let maxarray = productDatas.data[0].custom_menu_max.split(',');
		// // let get_min = minarray[tindex];
		// let get_max = maxarray[tindex];

		let indexdata = _.indexOf(rowcheckbox[tindex], index)

		if (typeof (rowcheckbox[tindex]) === "undefined") {
			// rowcheckbox.push(tindex);
			rowcheckbox[tindex] = [index];
		}
		else {

			if (indexdata >= 0) {
				_.unset(rowcheckbox[tindex], indexdata);
			} else {
				rowcheckbox[tindex].push(index);
			}

			rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
				return element !== undefined;
			});
		}

		let data = selectedDatas;

		if ((rowcheckbox[tindex] != undefined && rowcheckbox[tindex].length > get_max) || rowcheckbox[tindex].length > get_max) {
			let exist = custommenuDatas && custommenuDatas[tindex].custom_menu_items.filter(exist => data.some(exist2 => exist.id === exist2.item_id));

			if (exist && exist.length > 0) {

				let itemRemove = custommenuDatas && custommenuDatas[tindex].custom_menu_items[rowcheckbox[tindex][rowcheckbox[tindex].length - 2]]

				data.splice(data.findIndex(item => item.item_id === itemRemove.id), 1)
			}
			else {
				data.splice(data.findIndex(item => item.item_id === custommenuDatas[tindex].custom_menu_items[index]), 1)
			}
			rowcheckbox[tindex].splice(-2, 1);
		}


		//rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
		setrowcheckbox([...rowcheckbox]);
		let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
		let item_price = _.fill(Array(chosenItems.length), item.price);
		// let data = selectedDatas;
		let index_selected = _.findIndex(selectedDatas, { item_id: item.id });

		if (index_selected < 0) {
			data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: [], itemsize_id: itemsize_ids, item_price: selectedItemPrice ? selectedItemPrice : 0, default: 0 });
		} else {
			_.unset(data, index_selected);
		}
		data = data.filter(function (element) {
			return element !== undefined;
		});

		let selectedArray = [];
		let selectedToppin = [];
		let selectedToppinIds = [];
		let customButtonArray = [];
		// console.log(data, 'data');
		data.forEach(function (selecteditem, key) {
			if (key == 0) {
				customButtonArray.push({ menu_name: selecteditem.item_name, menu_id: selecteditem.item_id });
				selectedArray.push(selecteditem.item_name);
				selectedToppin.push(selecteditem.item_price ? selecteditem.item_price : 0)
				selectedToppinIds.push(selecteditem.item_id)
			}
			else {
				customButtonArray.push({ menu_name: selecteditem.item_name, menu_id: selecteditem.item_id });
				selectedArray.push(" " + selecteditem.item_name);
				selectedToppin.push(selecteditem.item_price ? selecteditem.item_price : 0)
				selectedToppinIds.push(selecteditem.item_id)
			}
		});
		setTag([...customButtonArray]);
		setDefaultTags([...selectedArray]);
		setToppins([...selectedToppin]);
		setToppinID([...selectedToppinIds]);
		let addtoppins = 0;
		if (selectedToppin.length > 0) {
			addtoppins = selectedToppin.reduce((a, b) => Number(a) + Number(b));
		}

		//   setTotalCost((selectedPrice * num ) + Number(addtoppins))
		setTotalCost((Number(selectedPrice) + Number(addtoppins)) * num)
		// setZeroProductValue((Number(selectedPrice) + Number(addtoppins)) * num)
		//let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
		setselectedDatas([...data])

		let newTitle = 'Default';

		let uniqueArr = [];

		// loop through array
		for (let i of defaultSizeOptions) {
			if (uniqueArr.indexOf(i) === -1) {
				uniqueArr.push(i);
			}
		}

		for (let titleindex = 0; titleindex < selectedToppinIds.length; titleindex++) {

			if (uniqueArr.indexOf(selectedToppinIds[titleindex]) === -1) {
				newTitle = 'Customized';
			}
		}

		if (uniqueArr.length !== selectedToppinIds.length) {
			newTitle = 'Customized';
		} else if (uniqueArr.length == 0) {
			newTitle = 'Default';
		}

		setTitle(newTitle);
	};
	// const selectItems = (row, tindex, index, item, e) => {
	//     console.log(rowcheckbox, "rowcheckbox")
	//     console.log(item.id, "item.id")

	// 	let minarray = productDatas.data[0].custom_menu_min.split(',');
	// 	let maxarray = productDatas.data[0].custom_menu_max.split(',');
	//     let get_max = maxarray[tindex];

	// 	let new_rowcheckbox = rowcheckbox.map(function(numbervalue) {
	// 		return parseInt(numbervalue);
	// 	});
	// 	if(e.target.checked == true){
	// 		let indexdata = _.indexOf(new_rowcheckbox, item.id)
	// 		if(indexdata >= 0){
	// 		    _.unset(new_rowcheckbox, item.id)
	// 		}else {
	// 			new_rowcheckbox.push(item.id)
	// 		}
	// 	}else{
	// 		for( var i = 0; i < new_rowcheckbox.length; i++){ 

	// 			if ( Number(new_rowcheckbox[i]) === item.id) { 

	// 				new_rowcheckbox.splice(i, 1); 
	// 			}

	// 		}

	// 	}

	// 	let updateSelected = []
	// 	custommenuDatas.forEach(function(menu){
	// 		menu.custom_menu_items.forEach(function(menuitems){

	// 			let menuarray = _.indexOf(new_rowcheckbox, menuitems.id)

	// 			if(menuarray >= 0){
	// 				updateSelected.push(menuitems)
	// 			}
	// 		})
	// 	})


	// 	let selectedArray = [];
	// 	let selectedToppin = [];
	// 	let selectedToppinIds = [];

	// 	updateSelected.forEach(function(selecteditem) {
	// 		selectedArray.push(" " + selecteditem.name);
	// 		selectedToppin.push(selecteditem.price)
	// 		selectedToppinIds.push(Number(selecteditem.id))
	// 	});

	// 	setTag([selectedArray]);
	// 	setToppins(selectedToppin);
	// 	setToppinID(selectedToppinIds);
	// 	let addtoppins = 0;
	// 	if(selectedToppin.length > 0){
	// 	addtoppins = selectedToppin.reduce((a, b) => Number(a) + Number(b));
	// 	}

	// 	setTotalCost((selectedPrice * num ) + Number(addtoppins))
	//     setselectedDatas([...new_rowcheckbox])
	// };

	useEffect(() => {
		console.log(selectedDatas, "selectedDatas")
		console.log(chosenItems, "chosenItems")
		console.log(Tag, "tags")
		console.log(toppins, "toppind")

		// if(toppins.length){
		// 	let updatenewtoppins = toppins.map(function(numbervalue) {
		// 		return parseInt(numbervalue);
		// 	});
		// 	setToppins([...updatenewtoppins])
		// }



	}, [selectedDatas, chosenItems, Tag, toppins])

	const selectDefault = (row, tindex, rindex, item) => {
		console.log(row);
		console.log(item);
		let data = defaultSelect;
		let countminmax = _.filter(custommenuDatas, { id: row.id });
		let get_min = countminmax[0]['min'];
		let get_max = countminmax[0]['max'];
		console.log(data, "data");
		let datadefault = 0;
		let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
		if (index_selected < 0) {
			let getcustomdatas = _.filter(data, { custom_menu_id: row.id });
			console.log(getcustomdatas);
			console.log(get_min, get_max, getcustomdatas.length);
			if (getcustomdatas.length >= parseInt(get_max)) {
				var lastItem = data.pop();
				console.log(lastItem);
				selectedDatas.map((datas) => {
					if (datas.item_id == lastItem.item_id && datas.custom_menu_id == lastItem.custom_menu_id) {
						datas.default = 0;
						return datas;
					}
				});
				setselectedDatas([...selectedDatas])
				//data.splice(-1);
				//return false;
			}
			data.push({ custom_menu_id: row.id, item_id: item.id });
			datadefault = 1;
		} else {
			_.unset(data, index_selected);
		}
		data = data.filter(function (element) {
			return element !== undefined;
		});
		//let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
		selectedDatas.map((datas) => {
			if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
				datas.default = datadefault;
				return datas;
			}
		});
		setselectedDatas([...selectedDatas])
		//console.log(seledata, "data");
		setDefaultSelect(data);
	};

	useEffect(() => {
		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
		}

		if (posDatas) {

			let endtime = '';
			let starttime = ''
			let showfromtime = 0;
			let showtotime = 0

			if (posDatas.data[0].start_time !== '' && posDatas.data[0].end_time !== '' && posDatas.data[0].schedule == 1) {
				let from_array = posDatas.data[0].start_time.split(' ') // AM,PM
				let to_array = posDatas.data[0].end_time.split(' ')

				starttime = from_array[0].split(':') //01, 22
				endtime = to_array[0].split(':')

				let frommoment = moment().tz(timezone).format('LT').split(' ')

				let restricttime = frommoment[0].split(':')
				let currenttime = restricttime[0];

				if (restricttime[0].length == 1) {
					currenttime = '0' + restricttime[0];
				}

				var timenow = parseInt(currenttime);
				var startnow = parseInt(starttime[0])
				var endnow = parseInt(endtime[0])

				if (from_array[1] === to_array[1]) {
					if (frommoment[1] !== from_array[1]) {
						showfromtime = 0;
						showtotime = 0;
					} else {

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1;
							// showtotime = 1;
						}

						if (startnow === timenow && (parseInt(starttime[1]) > parseInt(restricttime[1]))) {
							showfromtime = 0;
						}

						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1;
							// showfromtime = 1;

						}

						if (endnow === timenow && (parseInt(endtime[1]) < parseInt(restricttime[1]))) {
							showtotime = 0;
						}

						if (startnow !== timenow) {

							if ((startnow !== 12 && startnow < timenow)) {
								showfromtime = 1;
							}

							if (startnow === 12 && startnow > timenow) {
								showfromtime = 1;
							}
						}
						if (endnow !== timenow) {


							if (timenow !== 12 && (endnow > timenow)) {
								showtotime = 1;
							}

							if (timenow === 12 && (endnow < timenow)) {
								showtotime = 1;
							}
						}

					}
				}

				if (from_array[1] === 'AM' && to_array[1] === 'PM') {
					showfromtime = 0;
					showtotime = 0;

					if (frommoment[1] === 'AM') {
						showtotime = 1;
						if (parseInt(starttime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(starttime[1]) <= parseInt(restricttime[1])) {
								showfromtime = 1
							} else {
								showfromtime = 0
							}
						} else if (parseInt(starttime[0]) === 12) {
							showfromtime = 1
						} else {
							if (parseInt(starttime[0]) <= parseInt(restricttime[0]) && parseInt(restricttime[0]) != 12) {
								showfromtime = 1
								if (parseInt(starttime[0]) === parseInt(restricttime[0]) && parseInt(starttime[1]) <= parseInt(restricttime[1])) {
									showfromtime = 1
								}
							}
						}
					}

					if (frommoment[1] === 'PM') {
						showfromtime = 1;
						if (parseInt(endtime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(endtime[1]) >= parseInt(restricttime[1])) {
								showtotime = 1
							} else {
								showtotime = 0
							}
						} else if (parseInt(restricttime[0]) === 12) {
							showtotime = 1
						} else {
							if (parseInt(endtime[0]) >= parseInt(restricttime[0]) || parseInt(restricttime[0]) === 12) {
								showtotime = 1
								if (parseInt(endtime[0]) === parseInt(restricttime[0]) && parseInt(endtime[1]) >= parseInt(restricttime[1])) {
									showtotime = 1
								}
							}
						}
					}

				}

				if (from_array[1] === 'PM' && to_array[1] === 'AM') {

					if (frommoment[1] === 'PM' && startnow <= timenow) {
						showfromtime = 1
						showtotime = 1
						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1
						}

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 0
						}
					}

					if (frommoment[1] === 'AM' && endnow >= timenow) {
						showtotime = 1
						showfromtime = 1
						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1
						}

						if (endnow === timenow && (parseInt(endtime[1]) <= parseInt(restricttime[1]))) {
							showtotime = 0
						}
					}
				}

			}

			setFromTime(showfromtime)
			setToTime(showtotime)
		}

	}, [posDatas, generalDatas])

	useEffect(() => {

	}, [Title]);

	console.log(custommenuDatas, 'custommenuDatas');
	console.log(itemsizeDatas, 'itemsizeDatas');
	console.log(finalCartProduct, 'finalCartProductdatas');


	return (
		<>
			<Header />

			<div className="page-content bg-white">


				<div className="breadcrumb-row" style={{ backgroundColor: '#c1272d', paddingLeft: '20px' }}>
					<ul className="list-inline">
						{
							(productDatas && productDatas.data) ? <li><Link to={`/`} style={{ color: 'white' }}><i className="fa fa-home"></i></Link></li> : <li><Link to={'/'}>Home</Link></li>
						}
						{/* <li>{(productDatas && productDatas.data) ? <Link to={`/subcategory/${EncryptDecrypt.encryptdata(productDatas.data[0].category_id, secretKey)}`} style={{ color: 'white' }}>{productDatas.data[0].category.name}</Link> : 'No Name'}</li> */}
						<li>{(productDatas && productDatas.data) ? <Link to={`/subcategories/products/${EncryptDecrypt.encryptdata(productDatas.data[0].subcategory_id, secretKey)}`} style={{ color: 'white' }}>{productDatas.data[0].subcategory.name}</Link> : 'No Name'}</li>
						<li>{(productDatas && productDatas.data) ? <Link to={'#'} style={{ color: 'white' }}>{productDatas.data[0].name}</Link> : ''}</li>
					</ul>
				</div>
				{(posDatas && posDatas.data) ? ((posDatas && posDatas.data[0].schedule == 0 || fromTime == 0 || toTime == 0) ?

					<div className="tab-content product_list">
						<div id="web-design-1" className="tab-pane active">
							<p className="m-b0" style={{ color: 'red' }}> {posDatas.data[0].message} </p>
						</div>
					</div>
					: '') : ''
				}
				{
					(productDatas && productDatas.data) ?
						<div className="content-block">

							<div className="section-full content-inner-1 bg-gray-light" style={{ paddingTop: '20px' }}>
								<div className="container woo-entry">
									<div className="row">
										<div className="col-lg-6 m-b30" style={{ maxWidth: '40%', left: '50px' }}>
											<div className="product-gallery on-show-slider lightgallery" id="lightgallery">
												<div className="dlab-box">
													<div className="dlab-thum-bx">
														{
															(productDatas && productDatas.data) ? <img src={assestURL + productDatas.data[0].image} alt="" style={{ height: '403px' }} /> : <img src={require('./../../images/product/item2.jpg')} alt="" />
														}
														{/* <img src={require('./../../images/product/item2.jpg')} alt="" /> */}
														{/* <img src={require('./../../images/product/item2.jpg')} alt="" /> */}
														{/* <span data-exthumbimage="images/product/item2.jpg" data-src={require("./../../images/product/item2.jpg")} className="check-km" title="Image 1 Title will come here" >		
														<i className="fa fa-search"></i>
													</span> */}
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-6 m-b30" style={{ marginLeft: '5rem' }}>
											<form method="post" >
												<div className="dlab-post-title">
													<h4 className="post-title">{(productDatas && productDatas.data) ? productDatas.data[0].name : 'No Name'}</h4>
													<p className="m-b10">{(productDatas && productDatas.data) ? productDatas.data[0].description : '-'}</p>
													<div className="dlab-divider bg-gray tb15">
														<i className="icon-dot c-square"></i>
													</div>
												</div>
												<div className="relative">
													{/* <h3 className="m-tb10"><s style={{ color: '#c1272d', fontSize: '23px' }}>$ {parseFloat(slashPrice).toFixed(2)}</s>&nbsp;&nbsp;$ {(productDatas && productDatas.data) ? parseFloat(selectedPrice).toFixed(2) : 0}&nbsp;&nbsp;<span style={{ fontSize: '15px', color: 'green' }}>(Online Price)</span>  </h3> */}
													{parseFloat(slashPrice).toFixed(2) > 0.00 || parseFloat(selectedPrice).toFixed(2) > 0.00 ?
														<h3 className="m-tb10"><s style={{ color: '#c1272d', fontSize: '23px' }}>$ {parseFloat(slashPrice).toFixed(2)}</s>&nbsp;&nbsp;$ {(productDatas && productDatas.data) ? parseFloat(selectedPrice).toFixed(2) : 0}&nbsp;&nbsp;<span style={{ fontSize: '15px', color: 'green' }}>(Online Price)</span>  </h3> :
														<h3 className="m-tb10">$ {(productDatas && productDatas.data && custommenuDatas && custommenuDatas.length) ? parseFloat(zeroProductValue).toFixed(2) : (productDatas && productDatas.data) ? parseFloat(zeroProductValue).toFixed(2) : parseFloat(selectedPrice).toFixed(2)}&nbsp;&nbsp; </h3>
													}
													{/* <h3 className="m-tb10">$ {(productDatas && productDatas.data) ? parseFloat(selectedPrice).toFixed(2) : 0.00} </h3> */}
													<div className="shop-item-rating" style={{ left: '350px', marginTop: '5px', color: 'red' }}>
														<i className={(checked) ? "fa fa-heart" : "fa fa-heart-o"} value={checked} style={{ fontSize: '20px', color: 'red', marginRight: '5px' }}
														// onClick={addFavourite}
														>Added to Favorite</i>
														{/* <span><i className={(checked) ? "fa fa-heart" : "fa fa-heart-o"} value={checked} style={{ fontSize: '20px', color: 'red', marginRight: '5px' }}
														// onClick={addFavourite}
														><span style={{fontSize:'20px'}}>Added to Favorite</span></i></span> */}
														</div>
													{/* <div className="shop-item-rating">
														<span className="rating-bx">
															<i className="fa fa-star"></i>
															<i className="fa fa-star"></i>
															<i className="fa fa-star"></i>
															<i className="fa fa-star-o"></i>
															<i className="fa fa-star-o"></i>
														</span>
														<span>4.5 Rating</span>
													</div> */}
												</div>
												<div className="shop-item-tage" style={{ paddingBottom: '1rem' }}>
													<span>{(Title) ? Title : '-'} : </span>
													{(defaultTags) ? <Link to={'#'}>{defaultTags.toString().replaceAll(",", ", ")}</Link> : '-'}
												</div>
												<div className="dlab-divider bg-gray tb15">
													<i className="icon-dot c-square"></i>
												</div>
												<div className="row">
													<div className="m-b30 col-md-7 col-sm-8">
														<h6>Product Size</h6>
														<div className="btn-group product-item-size" data-toggle="buttons" style={{ display: 'block' }}>
															{
																itemsizeDatas && itemsizeDatas.data.map((row) => (

																	<label className={(productDatas && productDatas.data) ? ((row.id == defaultItems) ? 'btn active' : 'btn') : 'btn'} >
																		<input name="options" id="option7" type="radio" value={row.id} onClick={toggleChecked} /> {row?.code}
																	</label>
																))
															}
														</div>
													</div>

												</div>
												<div className="row">
													<div className="m-b30 col-md-4 col-sm-4" style={{ 'width': '35%' }}>
														<h6>Quantity</h6>
														<div className="btn-group product-item-size" data-toggle="buttons">
															<button className="btn btn-outline-primary" disabled={num == 1 || selectedPrice >= totalCost} type="button" onClick={() => {

																setNum(num - 1);
															}}><i className="ti-minus"></i></button>
															<input readOnly style={{ 'height': '40px' }} type="text" className="form-control" value={num} onChange={val => {
																console.log(val.target.value, 'etarval')
																setNum(val.target.value);
															}} />&nbsp;
															<button className="btn btn-outline-primary" type="button" onClick={
																() => { setNum(num + 1); }} style={{ marginRight: 'auto' }}><i className="ti-plus"></i>
															</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

														</div>

													</div>
													<div className="m-b30 col-md-3 col-sm-3">
														<h6>Total Price</h6>

														<h3 className="m-tb10">$ {(totalCost) ? parseFloat(totalCost).toFixed(2) : parseFloat(selectedPrice).toFixed(2)} </h3>
													</div>
													<div className="m-b30 col-md-2 col-sm-2">
														<h6></h6>
														{custommenuDatas && custommenuDatas.length ?
															<button className="btn btnhover customize" style={{ background: 'red', color: 'white', marginTop: '10px' }} onClick={(e) => { e.preventDefault(); setItemPrice(); setshowCustommenuItem(true) }}>
																<i className="ti-menu"></i>Update
															</button>
															:
															<button className="btn btnhover customize" style={{ background: 'red', color: 'white', marginTop: '10px' }} onClick={(e) => { e.preventDefault(); onSaveCustommenuItem() }}>
																<i className="ti-menu"></i>Update Cart
															</button>
														}
														&nbsp;&nbsp;
													</div>
												</div>
											</form>
										</div>
									</div>
									{
										(productDatas && productDatas.data) ? ((productDatas.data[0].additional_information) ? <div className="row" style={{ marginLeft: '30px' }}>
											<div className="col-lg-12">
												<div className="dlab-tabs product-description tabs-site-button m-t30">
													<ul className="nav nav-tabs">
														<li><Link data-toggle="tab" to={'#'} className="active">Additional Description</Link></li>
													</ul>
													<div className="tab-content">
														<div id="web-design-1" className="tab-pane active" style={{ overflowWrap: 'break-word' }}>
															<p className="m-b10">{(productDatas && productDatas.data) ? productDatas.data[0].additional_information : ''}</p>
														</div>
													</div>
												</div>
											</div>
										</div> : '') : ''
									}
								</div>
							</div>

							<Modal className='dialog-screen modal-dialog1' size="xl" show={showCustommenuItem} onHide={onCloseCustommenuItem}>
								<Modal.Header closeButton>
									<Modal.Title>Customize your Order</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{/* <div className='container mr-4 '> */}
									<div className="row">

										<div className="col-md-12 col-sm-12">
											{/* <h6>Product Name</h6> */}
											{/* <div className="btn-group product-item-size" data-toggle="buttons" style={{ overflowWrap: 'anywhere' }}> */}
											{
												<h4 style={{ color: 'green' }}>{(productDatas && productDatas.data) ? productDatas.data[0].name : ''} </h4>
											}
											{/* </div> */}
										</div>
										<div className="m-b30 col-md-8 col-sm-8">
											<h6>Product Size</h6>
											<div className="btn-group product-item-size" data-toggle="buttons">
												{
													itemsizeDatas && itemsizeDatas.data.map((row) => (

														<label className={(productDatas && productDatas.data) ? ((row.id == defaultItems) ? 'btn active' : 'btn') : 'btn'} >
															<input name="options" id="option7" type="radio" value={row.id} onClick={toggleChecked} /> {row?.code}
														</label>
													))
												}
											</div>
										</div>
										<div className="m-b30 col-md-2 col-sm-3">
											<h6>Total Price</h6>

											<h3 className="m-tb10">$ {(productDatas && productDatas.data) ? ((totalCost) ? parseFloat(totalCost).toFixed(2) : parseFloat(selectedPrice).toFixed(2)) : 0.00} </h3>
										</div>
										<div className="m-b30 col-md-2 col-sm-3" style={{ 'width': '35%' }}>
											<h6>Quantity</h6>
											<div className="btn-group product-item-size" data-toggle="buttons">
												<button className="btn btn-outline-primary" disabled={num == 1 || selectedPrice >= totalCost} type="button" onClick={() => {

													setNum(num - 1);
												}}><i className="ti-minus"></i></button>
												<input readOnly style={{ 'height': '40px', paddingRight: '0px' }} type="text" className="form-control" value={num} onChange={val => {
													console.log(val.target.value, 'etarval')
													setNum(val.target.value);
												}} />&nbsp;
												<button className="btn btn-outline-primary" type="button" onClick={
													() => { setNum(num + 1); }} style={{ marginRight: 'auto' }}><i className="ti-plus"></i>
												</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

											</div>
										</div>

									</div>

									<div className="shop-item-tage" style={{ paddingBottom: '1rem' }}>
										<span>{Title} : </span>
										{(Tag && Tag.length) ? Tag && Tag.length && Tag.map((item, i) => {
											return (
												<>
													<Button className='hover_zoom' size="sm" color='#5fcac7' style={{ backgroundColor: '#5fcac7' }} onClick={(e) => {
														TagcustomRemove(item)
													}}>{item.menu_name}&nbsp;<i class="fa fa-window-close" aria-hidden="true"></i></Button>&nbsp;
												</>
											)
										})
											: ''
										}
									</div>

									<Tabs defaultActiveKey="0" type="card" >
										{
											custommenuDatas && custommenuDatas.map((row, tindex) => (
												<Tabs.TabPane tab={TabName(row, tindex)} key={tindex} onClick={(tindex) => {
													alert('tindex' + tindex)
												}}>
													<div className="row col-md-12">


														{row?.custom_menu_items.length > 0 && row?.custom_menu_items.map((item, rindex) => {

															console.log(rowcheckbox[tindex], 'rowcheckbox[tindex]');
															// console.log(toppins, 'toppins');
															return (MenuItems.indexOf(item.id) > -1) ? <div className="col-md-2">
																&nbsp;&nbsp;
																<div className="card card-border text-center position-relative" style={{ minHeight: '250px' }}>
																	{/* <Checkbox required="" name={rindex} checked={(toppinArray.indexOf(item.id) != -1) ? true : false} onClick={(e) => selectItems(row, tindex, rindex, item, e)}>
																</Checkbox>  */}
																	<Checkbox className='position-absolute' style={{ zIndex: 1050 }} required="" name={rindex} checked={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? false : true} onClick={(e) => selectItems(row, tindex, rindex, item, e, itemVariationCost)}>
																	</Checkbox>
																	<label htmlFor={rindex}>

																		<img src={assestURL + item?.image}
																			onClick={(e) => selectItems(row, tindex, rindex, item, e, itemVariationCost)} style={{ height: '140px', objectFit: 'cover' }} className="card-img-top" alt="..." />
																	</label>
																	<div className="card-body1" style={{ minHeight: '3.5rem' }}>
																		<h5 className="card-title  h6 mb-1">{item?.name}</h5>
																		{/* <p className="card-text" style={{ color: 'green', fontSize: '20px' }}>{(item?.price > 0) ? '$' + parseFloat(item?.price).toFixed(2) : ''}</p> */}
																		<p className="card-text h6 mb-2" style={{ color: 'green' }}>{(itemVariationCost && itemVariationCost.length) ? itemVariationCost && itemVariationCost.map((priceID) => {
																			if (priceID.menu_id == item.id && priceID.price > 0) {
																				return '$' + parseFloat(priceID.price).toFixed(2)
																			}
																		}) : ''}</p>

																	</div>
																</div>

															</div> : ''
														})}
													</div>
												</Tabs.TabPane>
											))

										}
									</Tabs>
									{/* </div> */}
								</Modal.Body>
								<Modal.Footer>
									{(showValidationMessage) ? <Alert message={showValidationMessage} type="error" showIcon /> : ''}
									<Button variant="primary" onClick={onSaveCustommenuItem}>
										Update
									</Button>
									<Button variant="secondary" onClick={onCloseCustommenuItem}>
										Back
									</Button>
								</Modal.Footer>
							</Modal>

							<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showLoginModal} onHide={onCloseLoginCart}>
								<Modal.Header closeButton>
									<Modal.Title>Login</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<TabContent activeTab={activeTab}>
										<TabPane tabId="1">
											<form id="login" onSubmit={(e) => submitHandler(e)} className="tab-pane active col-12 p-a0">
												<div className="form-group">
													<label >E-Mail *</label>
													<input
														className="form-control"
														id="signin-email"
														placeholder="Email"
														type="email"
														value={loginDetails.email}
														autoComplete="off"
														onChange={val => {
															setemailMessage('');
															setLoginDetails({ ...loginDetails, email: val.target.value });
														}}
													/>
													<span style={{ color: 'red' }}>{emailMessage}</span>
												</div>
												<div className="form-group">
													<label>Password *</label>
													{/* <input
												className="form-control"
												id="signin-password"
												placeholder="Password"
												type={PasswordType}
												value={loginDetails.password}
												autoComplete="off"
												onChange={val => {
													setpasswordMessage('')
													setLoginDetails({ ...loginDetails, password: val.target.value });
												}}
											/>
											<span style={{ position: 'absolute', right: '-24px', marginTop: '-34px' }}>
												{(PasswordType === 'text') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
													< i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
											</span>
											<span style={{ color: 'red' }}>{passwordMessage}</span> */}
													<div className='position-relative'>
														<input
															className="form-control"
															id="signin-password"
															placeholder="Password"
															type={PasswordType}
															value={loginDetails.password}
															autoComplete="off"
															onChange={val => {
																setpasswordMessage('')
																setLoginDetails({ ...loginDetails, password: val.target.value });
															}}
														/> <span style={{ position: 'absolute', top: '11px ', right: '15px' }}>
															{(PasswordType === 'password') ? <i onClick={togglePassword} className="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
																< i onClick={togglePassword} className="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
														</span>
														<span style={{ color: 'red' }}>{passwordMessage}</span>
													</div>

												</div>
												<button className="btn btn-primary btn-block1" href="javascript:void(0);" onClick={onLoginSubmit}
												>Login</button>&nbsp;&nbsp;

												<Button variant="secondary" onClick={onCloseLoginCart}>
													Close
												</Button>
												<br />
												<br />
												<a to="#" data-toggle="tab" id="#forgot-password"
													className={classnames({ active: activeTab === '1' })}
													onClick={() => { toggle('2'); }}
												> <u id='hover_password'>Forgot Password </u></a> |  &nbsp;

												<a href={'/register'}><u>Signup</u></a>&nbsp;&nbsp;&nbsp;
											</form>
										</TabPane>
										<TabPane tabId="2">
											<form id="forgot-password"
												onSubmit={(e) => submitHandler(e)}
												className={activeTab === '2' ? 'tab-pane fade col-12 p-a0  show' : ' tab-pane fade col-12 p-a0 '}>
												<h4>Forgot Password ?</h4>
												<p>We will send you an email to reset your password. </p>
												<div className="form-group">
													<label>E-Mail *</label>
													<input
														className="form-control"
														id="signin-email"
														placeholder="Email"
														type="email"
														value={forgotDetails.email}
														autoComplete="off"
														onChange={val => {
															setForgotDetails({ ...forgotDetails, email: val.target.value });
														}}
													/>
												</div>
												<div className="text-left gray-btn">
													<button type="submit" className="btn btnhover" onClick={onForgotSubmit}>Submit</button>&nbsp;

													<Link
														className={classnames({ active: activeTab === '2' }) + ' btn  gray'}
														onClick={() => { toggle('1'); }}
														data-toggle="tab" to={"#"} >Back</Link>
												</div>
											</form>
										</TabPane>
									</TabContent>
								</Modal.Body>
								<Modal.Footer>
									{/* <button className="btn btn-primary btn-lg btn-block1" href="javascript:void(0);" onClick={onLoginSubmit}
									>Login</button>
									<Button variant="secondary" onClick={onCloseLoginCart}>
										Back
									</Button> */}

								</Modal.Footer>
								{/* <a href={'/register'} style={{ paddingLeft:'60px' }}>Create An Account</a> */}
							</Modal>

							{/* <Owl  /> */}

						</div> : ''
					// 	(<>
					// <div className="row">
					// <div className="col-lg-6 col-md-6 m-b30"></div>

					// 	<div className="col-lg-6 col-md-6" style={{ marginTop:'30px',marginLeft:'500px' }}>
					// 				<h3>Product not available</h3>
					// 				<div className="form-group">
					// 					<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
					// 				</div>
					// 			</div>
					// 			</div></>)
				}
			</div>


			<Footer />

		</>
	)

}

export default FavoriteProduct; 