import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackTop } from 'antd';
import moment from 'moment';
// var ios = require('./../../images/logos/ios.png');	
// var android = require('./../../images/logos/google_play.png');	
// var bgfoter = require('./../../images/banner/bn4.png');

// class Footer extends Component {
function Footer() {

	const [scroll, setScroll] = useState(false)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	}, []);


	const handleScroll = () => {
		// console.log(window.scrollY, 'window.scrollY ');
		if (window.scrollY >= 220) {
			setScroll(true)
		}
		else {
			setScroll(false)
		}
	}

	const style = {
		height: 40,
		width: 40,
		lineHeight: '40px',
		borderRadius: 4,
		// backgroundColor: '#1088e9',
		// color: '#fff',
		textAlign: 'center',
		fontSize: 14,
		paddingLeft: '55px'
	};
	return (
		<footer className="site-footer " style={{ marginTop: '25px' }} >
			<div className="footer-top bg-line-top">
				<div className="container">
					<div>
						<BackTop className={scroll == true ? "d-block" : "d-none"} >
							<div style={style}><i className="fa fa-arrow-circle-up" title='Back to Top' style={{ fontSize: '45px', color: '#5fcac7' }}></i></div>
						</BackTop>
					</div>
					<div className='col-md-12'>
						<div className="row">
							<div className="col-md-4">
								{/* <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'> */}
								<div className="widget widget_services border-0">
									<h4 className="footer-title">Quick Links</h4>
									<ul className="list-1">
										{/* <li><Link to={'/'}>Home</Link></li> */}
										<li><Link to={'/aboutus'}>About Us</Link></li>
										<li><Link to={'/products'}>Products</Link></li>
										{/* <li><Link to={'/our-menu-1'}>Our Menu</Link></li> */}
										{/* <li><Link to={'/faq'}>FAQ</Link></li> */}
										{/* <li><Link to={'/blog-grid-2'}>Blog</Link></li> */}
										{/* <li><Link to={'/shop'}>Shop</Link></li> */}
										<li><Link to={'/contactus'}>Contact Us</Link></li>
										<li><Link to={'/privacypolicy'}>Privacy Policy</Link></li>
										{/* <li><Link to={'/team'}>Team</Link></li> */}
										{/* <li><Link to={'/calendar'}>Calendar</Link></li> */}
									</ul>
								</div>
							</div>
							<div className="col-md-4">
								<div className="widget widget_getintuch">
									<h5 className="footer-title text-white">Contact Us</h5>
									<ul>
										<li>
											<i className="fa fa-map-marker"></i>
											<p>2290 Hwy 51 S, Hernando, MS 38632</p>
										</li>
										{/* <li>
										<i className="fa fa-mobile"></i>
										<p>(662) 429-6540</p>
									</li> */}
										<li>
											<i className="fa fa-envelope"></i>
											<p style={{ textTransform: 'lowercase' }}>velvetcream@gmail.com</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4">
								<div className="widget widget_getintuch">
									<h5 className="footer-title text-white">Download App</h5>
									<ul>
										<li className='p-0'>
											<a href="https://play.google.com/store/apps/details?id=com.dipmenu" className="dez-page" target="_blank"><img src={require('./../../images/logos/google_play.png')} alt="" /></a>
										</li>
										<li className='p-0'>
											<a href="https://apps.apple.com/us/app/velvet-cream/id1495798783?ls=1" className="dez-page" target="_blank"><img src={require('./../../images/logos/ios.png')} alt="" /></a>
										</li>

									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-bottom" >
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 text-left"> <span>Copyright © {moment().format('YYYY')} DipMenu. All rights reserved.</span> </div>
						<div className="col-lg-6 col-md-6 text-right">
							<div className="widget-link">
								<ul>
									{/* <li><Link to={'/aboutus'}></Link></li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>

	)

}

export default Footer;