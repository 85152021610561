import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Form} from 'react-bootstrap';
import { profileData, RedeemCouponData, giftcardData, profileSelector } from "../../store/reducer/profile";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import { API_STATUS } from '../../utils/constants';
import { toast } from 'react-hot-toast';

function Giftcard(){
	const [redeemDetails, setRedeemDetails] = useState({ coupon_code: "" });
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const dispatch = useDispatch();
	const [showRedeemModal, setshowRedeemModal] = useState(false);
	const [errorMessage, seterrorMessage] = useState('');
	if (!userDetails) {
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const { redeemDatas,redeemMessage, redeemloading, giftDatas, profileDatas } = useSelector(profileSelector);
	const [walletAmount, setwalletAmount] = useState(0)
	const [showHistory, setshowHistory] = useState(false);

	const giftCardDispatch = (loginUser) => {

		dispatch(
			giftcardData({ user_id: loginUser })
		);

	};

	const UserDispatch = (loginUser) => {
		dispatch(
			profileData({ user_id: loginUser })
		);
	}
	const onCloseRedeem = () => {
		setshowRedeemModal(false);
		window.scroll({ top: 0, left: 0, behavior: "smooth", });
	}

	const showRedeemPopup = () => {
		setshowRedeemModal(true);
	}

	const AddWalletAmount = () => {

		
		if(redeemDetails.coupon_code.trim() == ''){
			seterrorMessage('Please enter the coupon code');
			return false;
		}
		
		let postData = { redeem_code: redeemDetails.coupon_code }

		dispatch(
			RedeemCouponData({ postData })
		);

	};

	useEffect(() => {
		giftCardDispatch(loginUser)
		UserDispatch(loginUser)
		//sharedgiftCardDispatch(loginUser)
	}, [])

	useEffect(() => {
		if(giftDatas){
			console.log(giftDatas, 'giftDatas');
		}
	
	}, [giftDatas])

	useEffect(() => {
		if(profileDatas){
			setwalletAmount(profileDatas.wallet)
			console.log(profileDatas, 'profileDatas');
		}
	}, [profileDatas])
console.log(profileDatas, 'profileDatas');
	useEffect(() => {
		console.log(redeemloading, "redeemloading")
		if (redeemloading === API_STATUS.FULFILLED) {

			if(redeemDatas.status ==true){
				setshowRedeemModal(false)
				toast.dismiss();
				toast.success('Coupon amount added to your wallet successfully.!');

				window.setTimeout(function () {
					window.location.reload()
				}, 2000);
			}else{
				setshowRedeemModal(true)
				// console.log(re);
				seterrorMessage(redeemDatas.message);
				setRedeemDetails({...redeemDetails, coupon_code: ''})
			}

		}
		if (redeemloading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (redeemloading === API_STATUS.REJECTED) {
			toast.dismiss();
			// setshowRedeemModal(false)
			seterrorMessage('Something went wrong.');
			setRedeemDetails({...redeemDetails, coupon_code: ''})
			
		}
	}, [redeemloading]);


	return(
		<>
		<Header />
		
			<div className="page-content bg-white">
			<div className="section-full content-inner1">
			
				<div className="container">
				<div className='row' style={{paddingTop: '0.5rem'}}>
					<div className="col-lg-9" style={{ paddingTop: '10px'}}> <h4 style={{ fontWeight: '800'}}> My Gift Cards</h4></div>
					<div className="col-lg-3" style={{ paddingTop: '10px'}}> <h4 style={{ fontWeight: '800'}}>Balance: ${ parseFloat(walletAmount).toFixed(2)}</h4></div>
					
				</div>
				<div className='row' style={{paddingTop: '0.5rem', marginTop: '-1rem', paddingBottom:'0.5rem'}}>
					<div className="col-lg-12" style={{ paddingTop: '0.5rem'}}>
					<a href="/newgiftcard" class="btn btn-primary btn-xs" style={{ marginRight: '10px' }}>Buy Gift Card</a>
					<a href="#" class="btn btn-info btn-xs" onClick={showRedeemPopup} style={{ marginRight: '10px' }}>Redeem Code</a>&nbsp;
					<a href="/wallettopup" class="btn btn-success btn-xs" style={{ marginRight: '10px' }}>Dip Wallet</a>&nbsp;
					<button class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} onClick={val => {
						console.log(showHistory, 'beforeshowhistory');
						
						if(showHistory)
							setshowHistory(false)
						else
							setshowHistory(true)

						console.log(showHistory, 'aftershowhistory');
					}}>{ (showHistory) ? "Hide History" : "View History" }</button>

					{/* </div>
					<div className="col-lg-4" style={{ paddingTop: '0.5rem', paddingLeft: '16rem'}}> */}
					
					</div>
				</div>
					<div className="row" style={ (showHistory) ? {display:'block'} : {display:'none'} }>
						<div className="col-lg-12">
							<h2>Transaction Details</h2>
							<div className="table-responsive m-b50">
								<table className="table check-tbl">
									<thead>
										<tr>
											<th>Purchased By</th>
											<th>Email</th>
											<th>Amount</th>
											<th>Status</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>

									{ (giftDatas && giftDatas.data ) ? giftDatas.data.map((giftcard, key) => {
										return <><tr className="alert">
											<td className="product-item-totle">{ (giftcard?.user) ? giftcard?.user.first_name + ' ' + giftcard?.user.last_name : 'Super Admin'  }</td>
											<td className="product-item-price"> { giftDatas.userEmails[0] }</td>	
											<td className="product-item-price">$ {parseFloat(giftcard.amount).toFixed(2)}</td>	
											<td className="product-item-name">{(giftcard.redeem_flag == 1) ? 'Redeem' : 'Topup'}</td>
											<td className="product-item-price"> { (giftcard.created_at) ? moment(giftcard.created_at).format('DD-MM-YYYY h:mm A') : '-'}</td>	

										</tr></>
										}) : ''
									}

									{
										(giftDatas && giftDatas.data && giftDatas.data.length == 0 ) ? <td colSpan={4} style={{ textAlign: 'center' }}>No data found</td> : ''
									}
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
			<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showRedeemModal} onHide={onCloseRedeem}>
				<Modal.Header closeButton>
					<Modal.Title>Redeem Code</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label >Coupon Code<span style={{color:'red'}}>*</span></label>
						<input
							className="form-control"
							id="signin-email"
							placeholder="Please enter coupon code"
							type="text"
							value={redeemDetails.coupon_code}
							autoComplete="off"
							onChange={val => {
								if(val.target.value.length){
									seterrorMessage('')
								}else{
									seterrorMessage('Please enter the coupon code')
								}
								setRedeemDetails({ ...redeemDetails, coupon_code: val.target.value });
							}}
						/>
						<span style={{ color: 'red' }}>{errorMessage}</span>
					</div>
					
					<Button className="btn btn-primary btn-block1" href="javascript:void(0);" onClick={AddWalletAmount}
					>Redeem</Button>&nbsp;&nbsp;

					<Button variant="secondary" onClick={onCloseRedeem}>
						Close
					</Button>
				</Modal.Body>
				<Modal.Footer>

				</Modal.Footer>
			</Modal>
		</div>
		
		<Footer  />
		</>
	)
} 


export default Giftcard;