import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { addfavouriteProductCart, favouriteData, favouriteSelector } from '../../store/reducer/favourite';
import { addToCart, ProductSelector } from '../../store/reducer/product';
import moment from 'moment';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { assestURL, secretKey } from '../../services/config';
// var img = require('./../../images/banner/bnr3.jpg');
import { Input, Space } from 'antd';
import { API_STATUS } from '../../utils/constants';
import { toast } from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import { custommenuData, custommenuSelector } from '../../store/reducer/custommenu';


// import { allFavProductsData, ProductSelector } from '../../store/reducer/product';

function FavouriteList() {
    let temp_token = localStorage.getItem('temporaryToken');
    let userDetails = JSON.parse(localStorage.getItem('userdata'));
    const dispatch = useDispatch();
    const { productDatas, cartLoading, cartMessage } = useSelector(ProductSelector);
    const [showCustommenuItem, setshowCustommenuItem] = useState(false);
    const { favDatas, favloading, favmessage } = useSelector(favouriteSelector);
    const { custommenuDatas } = useSelector(custommenuSelector);
    const [finalCartProduct, setFinalCartProduct] = useState({ product_id: "", quantity: 0, product_price: 0, item_price: 0, item_ids: [], total_cost: 0, status: 1, temp_token: '' });
    const [defaultPrice, setDefaultPrice] = useState([]);
    const { Search } = Input;
    const aboutUsDispatch = () => {
        dispatch(
            favouriteData({})
        );
    };

    useEffect(() => {
        aboutUsDispatch()
    }, [])

    console.log(favDatas, 'favDatas');

    // useEffect(() => {
    //     if (favDatas) {

    //         // let price = favDatas.prices.filter( (result, index) => {
    //         // 	return result.product_id==10 && result.itemsize_id==1
    //         // });
    //         // console.log(price, 'price');
    //         setDefaultPrice([...favDatas.prices])
    //     }
    // }, [favDatas])

    const onSearch = (searchQuery) => {
        dispatch(
            favouriteData({
                query: searchQuery,
            })
        );
    }

    const addFavtoCart = (id) => {

        dispatch(
            addfavouriteProductCart({
                productid: id,
                temp_token: temp_token
            })
        );
    }

    useEffect(() => {
        if (favloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success(favmessage);
            setTimeout(() => {
                window.location.href = '/cartlist'
            }, 1000);
        } else if (favloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.success(favmessage);
        }

    }, [favloading])
    useEffect(() => {
        console.log(cartLoading, "loading")
        if (cartLoading === API_STATUS.FULFILLED) {
            setshowCustommenuItem(false);
            toast.dismiss();
            toast.success('Product added to cart successfully.!');

            // window.setTimeout(function () {
            // 	window.location.reload();
            // }, 2000);

            window.location.href = '/'

        }
        if (cartLoading === API_STATUS.PENDING) {
            console.log('erorr')

            //  toast.dismiss();
            //  toast.error('Login Failed! Please check username and password');
        }
        if (cartLoading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error(cartMessage);
            setshowCustommenuItem(false);
        }
    }, [cartLoading]);
    const onSaveCustommenuItem = (result) => {
        // setshowCustommenuItem(false);
        // console.log(result, 'result');
        // let postData = finalCartProduct
        // postData.product_id = `${result.product_id
        //     }`
        // postData.quantity = result.quantity
        // postData.product_price = Number(result.product_price)
        // postData.item_price = result.item_price
        // postData.item_ids = result.item_ids
        // postData.item_names = result.item_names
        // postData.total_cost = result.price
        // postData.default_size = Number(result.default_size)
        // postData.default_custom = result.default_custom
        // postData.description = result.description
        // postData.default_size_name = result.default_size_name
        // postData.status = result.status
        // postData.temp_token = temp_token
        // // postData.trash = result.trash
        // postData.total_cost = Number(result.total_cost)
        // if (userDetails) {
        //     postData.created_by = userDetails?.id
        // } else {
        //     postData.created_by = ""
        // }
        // dispatch(addToCart({ postData }));
        // console.log(postData, 'postData');
        window.location.href = `/productview/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}`

    }

    console.log(favDatas, 'favDatas');
    console.log(defaultPrice, 'favDatas');
    return (
        <>
            <Header />

            <div className="section-full content-inner1" >

                <div className="container">

                    <div className="row">

                        <div className='col-6'>
                            <h2>My Favorites</h2>
                            {/* <h3>Products</h3> */}
                            {/* {
			(allProductDatas && allProductDatas.count) ? <p>Showing {allProductDatas.count} products</p> : '' 
		} */}
                        </div>
                        <div className='col-6'>
                            <Search className='mt-4 product-search' placeholder="Search by product name" size="large" onSearch={onSearch} enterButton />
                        </div>
                        <div className="col-lg-12">
                            <p>Showing <strong>{(favDatas && (favDatas.data && favDatas.data.length)) ? favDatas.data.length : 0} </strong>favorites</p>
                            {favDatas && favDatas.data.map((result, tindex) => (
                                <div className='card p-5 my-4'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            {result.reward == 1 ?
                                                <div className='reward-label'>
                                                    <img style={{ width: '154px', height: '115px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
                                                </div> :
                                                (result.reward == 0 && (result.item_names) && result.default_custom == 0) ?
                                                    <div className='customized-label'>
                                                        <img style={{ width: '154px', height: '115px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
                                                    </div>
                                                    :
                                                    // (result.reward == 0 && (result.item_names == "" || result.item_names == null)) ?
                                                    <img style={{ width: '154px', height: '115px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />}
                                        </div>
                                        <div className='col-5'>
                                            {
                                                (result.reward == '1') ?
                                                    <a href={`/viewfavoritereward/${EncryptDecrypt.encryptdata(result.id, secretKey)}/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}`} ><span style={{ fontSize: '25px' }}>{result.product.name}</span></a>
                                                    : <a href={`/viewfavourite/${EncryptDecrypt.encryptdata(result.id, secretKey)}/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}`} ><span style={{ fontSize: '25px' }}>{result.product.name}</span></a>
                                            }

                                            {/* <span className='ml-5'><strong>Prices :</strong> { (result.name == 1)  ? result.name : '$' + parseFloat(result.name).toFixed(2) }</span> */}
                                            <p>{result.product.description}</p>
                                            <p>{(result.item_names && result.default_custom == 0) ? <p><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : (result.item_names && result.default_custom == 1) ? <p><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : ''}</p>
                                            {/* { (result.default.length) ? 
							<p><strong>Default:</strong> { result.default.replace(",", ', ') }</p> : ""} */}

                                            {/* New One 

                                            <p>{result.product.description}</p>
                                            <p>{(result.item_names && result.default_custom == 0) ? <p><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : (result.item_names && result.default_custom == 1) ? <p><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : ''}</p>

                                            */}

                                        </div>
                                        <div className='col-2'>
                                            {/* <span ><strong>{(result.reward == 1) ? 'Points' : 'Price'} :</strong> {result.reward == '1' ?
                                                result.selectedPrice + ' pts' : '$' + parseFloat(result.selectedPrice).toFixed(2)
                                            }</span><br></br> */}
                                            {/* <span ><strong>{(result.reward == 1) ? 'Points' : 'Price'}:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span><br></br>
                                            <span ><strong>Size :</strong> {result.default_size_name}</span> */}
                                            {result.product_price > 0.00 ?
                                                <>
                                                    <span ><strong>Size :</strong> {result.default_size_name}</span>
                                                    <br /><span><strong>{(result.reward == 1) ? 'Points' : 'Price'}:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span></>
                                                :
                                                <>
                                                    <span ><strong>Size :</strong> {result.default_size_name}</span>
                                                    <br /><span><strong>{(result.reward == 1) ? 'Points' : 'Price'}:</strong> {(result.reward == 1) ? Number(result.total_cost) + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)}</span></>
                                            }
                                            {/* <br/><span><strong>{(result.reward == 1) ? 'Points' : 'Price' }:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span> */}

                                        </div>
                                        <div className='col-3 text-center'>
                                            {/* <h3><p className='ml-3' style={{ paddingRight: '40px', marginTop: '20px' }}> <strong>{result.reward == '1' ?
                                                result.total_cost + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)
                                            }</strong></p></h3> */}

                                            {result.product_price == 0 ? <strong style={{ color: 'black', fontSize: '20px' }}>${parseFloat(result.total_cost).toFixed(2)}</strong> : (result.reward == 0) ? <span><span style={{ color: 'green' }}>{result.quantity} x ${parseFloat(result.product_price).toFixed(2)} = <strong style={{ color: 'black', fontSize: '20px' }}>${parseFloat(result.total_cost).toFixed(2)}</strong></span></span> : <span><strong style={{ color: 'black', fontSize: '20px' }}>{result.product_price} pts</strong></span>}<br />

                                            {/* {
                                                (result.reward == 1) ? <a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btn-primary btn-lg btn-block">Add to Cart</a> :
                                                    <a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btn-primary btn-lg btn-block">View Details</a>
                                            } */}

                                            {/* Add To Cart */}
                                            {(result.item_names && result.default_custom == 0) ? <span>(Added customization)</span> : ''}
                                            <Button className='mt-3' variant="danger" style={{ boxShadow: '0px 0px 15px #ff3c20' }} onClick={() => { onSaveCustommenuItem(result) }}>
                                                View Details
                                            </Button>
                                            {/* <a className='btn btnhover mt-1' onClick={() => { onSaveCustommenuItem(result)}} data-dismiss="alert" aria-label="close" title="" style={{color:'white'}}>Add to Cart</a> */}

                                        </div>
                                    </div>

                                </div>
                            ))}

                            {favDatas && (favDatas.data && favDatas.data.length == 0) ? <div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
                                <h3>No Products Found</h3>
                                <div className="form-group">
                                    <Link to={'/products'} className="btn btnhover" >Browse Products</Link>
                                </div>
                            </div> : ''}
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}


export default FavouriteList;