import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2'
import { CheckValidateToken, doLogin, resetPwd, loginSelector } from "../../store/reducer/login";
import { toast } from 'react-hot-toast';
import { API_STATUS } from "../../utils/constants";
import CryptoJS from 'crypto-js';

function MobilePayment() {

    let params = useParams()
    const dispatch = useDispatch();
    const { TokenLoading } = useSelector(loginSelector);
    // localStorage.setItem('mobile', 1)

    console.log(params, params?.paymentUrl, params.userId, 'params-value===///');

    //Token check in User Table
    const CheckTokenDispatch = (id) => {
        dispatch(
            CheckValidateToken({ token: id })
        );
    };

    useEffect(() => {
        CheckTokenDispatch(params.userToken)
    }, [params])

    useEffect(() => {
        console.log(TokenLoading, API_STATUS.PENDING, "TokenLoading==")
        if (TokenLoading === API_STATUS.FULFILLED) {
            window.location.href = `https://testpayments.worldnettps.com/merchant/paymentpage?${params?.paymentUrl}`
            console.log(`https://testpayments.worldnettps.com/merchant/paymentpage?${params?.paymentUrl}`);
        }
        if (TokenLoading === API_STATUS.PENDING) {
            // Swal.fire({
            //     title: 'Payment in Progress!',
            //     icon: 'info',
            //     timer: 3000,
            //     timerProgressBar: true,
            //     showConfirmButton: false,
            //     footer: 'Please wait Redirecting to Payment Page...',
            // })
        }
        if (TokenLoading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('Invalid Payment!..')
        }
    }, [TokenLoading]);

    return (
        <div>
        </div>
    )
}

export default MobilePayment
