import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
// import { ToastContainer, toaster } from 'react-toastify';
// import {  toaster } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { signupData, signupSelector } from "../../store/reducer/signup";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
// import { register } from '../../services/api';
import SimpleReactValidator from 'simple-react-validator';
import toast from 'react-hot-toast';
import profileAvatar from "../../images/gallery/profile.jpg"
import profileBanner from "../../images/background/profile_banner.png"
import { assestURL, secretKey } from '../../services/config';

var bnr = require('./../../images/banner/bnr1.jpg');

const Shopregister = ({ history }) => {
	// toast.success("Signup Successfully!");
	// toaster.success("Signup Successfully!");
	// Toastr.success('new message');
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	//   let navigate = useNavigate();
	// const toastr = useContext(ToastrContext);
	const [formDetails, setformDetails] = useState({ first_name: "", last_name: "", email: "", password: "", confirmpassword: "", role_id: 2, mobile: '', profile_imageurl: '', imageUpload: false });
	const { loading, errorMessage } = useSelector(signupSelector);
	//   toast.success("Signup Successfully!");
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [fileUpload, setfileUpload] = useState('');
	const [fileName, setFileName] = useState("");

	console.log(errorMessage, 'errorMessage--');
	// const onSignupSubmit = () => {
	// 	let postData = formDetails;
	// 	let errors = [];
	// 	if (postData.first_name.trim() == '') {
	// 		toast.error('First Name is required.!');
	// 		return false;
	// 	}
	// 	if (postData.last_name.trim() == '') {
	// 		toast.error('Last Name is required.!');
	// 		return false;
	// 	}
	// 	if (postData.email == '') {
	// 		toast.error('Email is required.!');
	// 		return false;
	// 	}
	// 	if (postData.password.trim() == '') {
	// 		toast.error('Password is required.!');
	// 		return false;
	// 	}

	// 	if (postData.confirmpassword.trim() == '') {
	// 		toast.error('Confirm Password is required.!');
	// 		return false;
	// 	}

	// 	if (postData.password != postData.confirmpassword) {
	// 		toast.error("Password and Confirm Password didn't match.!");
	// 		return false;
	// 	}

	// 	if (postData.mobile.trim() == '') {
	// 		toast.error('Mobile is required.!');
	// 		return false;
	// 	}

	// 	if (!postData.mobile.trim().match('[0-9]{10}')) {
	// 		toast.error('Mobile should be 10 digits.!');
	// 		return false;
	// 	}

	// 	if (postData.location.trim() == '') {
	// 		toast.error('Location is required.!');
	// 		return false;
	// 	}

	// 	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(postData.email)) {
	// 	} else {
	// 		toast.error(' Please enter the vaild email .!');
	// 		return false;
	// 	}

	// 	delete postData.confirmpassword;

	// 	console.log(postData, 'postData')
	// 	// toast.dismiss();

	// 	//   toast.success("Signup Successfully!");
	// 	//   history('/login');
	// 	dispatch(signupData({ postData }));
	// 	//   console.log("Signup Successfully!");

	// 	// history('/login');
	// };

	const imageOnChange = (e) => {
		e.preventDefault();
		const image = e.target.files[0];
		if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
			toast.error('Select Valid Image - (jpg,jpeg,png)')
			return false;
		} else {
			const reader = new FileReader();
			const file = e.target.files[0];

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onloadend = () => {
				const binaryStr = reader.result
				setformDetails({
					...formDetails,
					// profile_imageurl: binaryStr,
					imageName: file.name
				})
				setfileUpload(binaryStr)
				setFileName(file.name)
			}
			reader.readAsDataURL(file);
		}
	}

	const onSignupSubmit = (e) => {
		e.preventDefault();
		setAddFormSubmit(true);
		setFileName('')
		let postData = formDetails;
		if (formValidator.current.allValid()) {
			delete postData.confirmpassword;
			if (fileUpload) {
				postData.profile_imageurl = fileUpload;
				postData.imageUpload = true
			}
			setformDetails({
				...formDetails,
				confirmpassword: formDetails.password
			})
			dispatch(signupData({ postData }));
			// history('/login');
		}
	};

	useEffect(() => {
		formValidator.current.showMessages()
	}, [])

	useEffect(() => {
		console.log(loading, "loading")
		if (loading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Signed up Successfully.!");
			//   console.log("Signup Successfully!");

			// history.push('/');
			window.location.href = '/login'
		}
		// if (loading === API_STATUS.REJECTED && loading != API_STATUS.PENDING) {
		// 	toast.dismiss();
		// 	toast.error('Signup Failed! Please check details');
		// }
		if (loading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(errorMessage);
		}
		// if (loading === API_STATUS.PENDING) {
		// 	toast.dismiss();
		// 	// toast.error('pendding');
		// }
	}, [loading]);


	// const submitHandler = (e) => {
	// 	e.preventDefault();
	// 	history.push("/");
	// };

	return (
		<>
			{/* <Toastr.success message="Success mess" show={true}></Toastr.success> */}

			<Header />
			<div className="page-content bg-white">
				<div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Register</h1>

							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'} onClick={() => {
										window.scroll({ top: 0, left: 0, behavior: "smooth", });
									}}>Home</Link></li>
									<li>Register</li>
								</ul>
							</div>

						</div>
					</div>
				</div>
				<div className="section-full content-inner-2 shop-account">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<h2 className="m-b40 m-md-b20">Create An Account</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="p-a30 border-1 m-auto radius-sm">
									<div className="tab-content">
										<form id="login" className="tab-pane active">
											<h3 className="m-b5">Personal Information</h3>
											<p>If you have an account with us, please log in.</p>
											<div style={{ backgroundImage: `url(${profileBanner})`, backgroundSize: 'cover' }} className="form-group text-center" >
												<div>
													<label htmlFor="photo-upload ml-0" className="custom-file-upload fas">
														<div className='position-relative'>
															<div className="img-wrap" >
																<input id='profile-input' type='file' accept="image/*" onChange={imageOnChange}></input>
																<label className='position-absolute profile-edit' htmlFor='profile-input'><span className='profile-edit-btn rounded-circle p-1 bg-secondary '><i className='fa fa-edit text-white'></i></span></label>
																{fileUpload ? <img for="photo-upload" src={fileUpload} alt="" /> : formDetails.profile_imageurl && fileUpload == '' ? <img for="photo-upload" src={assestURL + formDetails.profile_imageurl} alt="" /> : fileUpload == '' && formDetails.profile_imageurl == '' ? < img for="photo-upload" src={profileAvatar} alt="" /> : ''}
															</div>
															<label style={{ paddingTop: '15px' }}>Upload Profile Photo</label>
														</div>

													</label>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6" >
													<label>First Name <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="First Name" type="text" /> */}
													<input
														className="form-control"
														name='first_name'
														placeholder="First Name"
														type="text"
														value={formDetails.first_name}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, first_name: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'first_name',
														formDetails.first_name,
														'required|alpha_space',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter First Name',
																alpha_space: 'Please Enter Valid Input',
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
													<label>Last Name <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														placeholder="Last Name"
														name='last_name'
														type="text"
														value={formDetails.last_name}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, last_name: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'last_name',
														formDetails.last_name,
														'required|alpha_space',
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Last Name',
																alpha_space: 'Please Enter Valid Input',
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
													<label>Mobile Number <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														name='mobile'
														placeholder="Mobile Number"
														type="text"
														value={formDetails.mobile}
														autoComplete="off"
														onChange={val => {
															let name = val.target.value;
															if (name.length > 10) {
																return false
															}
															var format = /^[0-9\s]*$/;
															if (format.test(name)) {
																setformDetails({ ...formDetails, mobile: val.target.value });

															} else {
																return false
															}
															//setformDetails({ ...formDetails, mobile: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'mobile',
														formDetails.mobile,
														'required|phone',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Mobile Number',
																phone: 'Please Enter Valid Mobile Number',
															}
														}
													)}
												</div>
												{/* <div className="form-group col-6" >
													<label>Location <span style={{ color: 'red' }}>*</span></label>
													<input
														className="form-control"
														name='location'
														placeholder="Location"
														type="text"
														value={formDetails.location}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, location: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'location',
														formDetails.location,
														// 'required|alpha_num_space',
														['required',
															{ regex: /^[a-zA-Z0-9\s,/:'-]*$/ }],
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Location',
																regex: 'Please Enter Valid Input',
															}
														}
													)}
												</div> */}
												<div className="form-group col-6" >
													<label>Email <span style={{ color: 'red' }}>*</span></label>
													<input
														className="form-control"
														name='email'
														placeholder="Email"
														type="email"
														value={formDetails.email}
														autoComplete="off"
														onChange={val => {
															let email = val.target.value
															setformDetails({ ...formDetails, email: email.toLocaleLowerCase() });
														}}
													/>
													{formValidator.current.message(
														'email',
														formDetails.email,
														'required|email',
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter E-mail',
																email: 'Please Enter Valid E-mail',
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
													<label>New Password <span style={{ color: 'red' }}>*</span></label>
													{/* <input defaultValue="Password" className="form-control " placeholder="Type Password" type="password" /> */}
													<input
														className="form-control"
														// id="signin-email"
														name='password'
														placeholder="Type Password"
														type="password"
														value={formDetails.password}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, password: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'password',
														formDetails.password,
														['required',
															{
																regex: /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/
															}
															// {
															// 	regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+={}|[\]\\:';"<>?,./]).*$/
															// }

														],
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Password',
																password: 'Please enter valid password',
																regex: 'Your password must contain Minimum 8 numerics with one special character and one caps.'
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
													<label>Confirm Password <span style={{ color: 'red' }}>*</span></label>
													{/* <input defaultValue="Password" className="form-control " placeholder="Type Password" type="password" /> */}
													<input
														className="form-control"
														name='confirmpassword'
														placeholder="Type Confirm Password"
														type="password"
														value={formDetails.confirmpassword}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, confirmpassword: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'confirmpassword',
														formDetails.confirmpassword,
														['required', `in:${formDetails.password}`,
														],
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Confirm Password',
																// regex: 'Please Enter Valid Password',
																in: "New Password and Confirm password doesn't match"
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
												</div>

											</div>
											<div className='row'>
												<div className='col-6'>
													{/* <button  type="submit" className="btn btnhover">CREATE</button> */}
													<a href='javascript:;' style={{ color: 'black' }} className=' btn btn-success  pull-right w-50'
														onClick={onSignupSubmit}
													>Create</a>
												</div>
												<div className='col-6'>
													<span>Already have an account ?</span>&nbsp;
													<a href={'/login'} style={{ color: 'black' }} className='btn btn-info text-center w-30'
													>Sign In Here</a>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>

	)
}

export default Shopregister;
