import { Http } from "./Http";
import { getBaseEndpointUrl, secretKey } from "./config";
import EncryptDecrypt from '../utils/encrypt&decrypt';

let userDetails = JSON.parse(localStorage.getItem('userdata'));
export const tryLogin = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};

export const register = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signup", postData);
};

export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(baseUrl, 'baseUrl');
    return Http.post(baseUrl + "/auth/forgot-password", postData);
};

export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/reset-password", postData);
};

//Profile Update
export const getProfileDetails = (getID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/profile/" + getID, Http.getAuthToken());
};


export const ProfileUpdateDetails = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/profileupdate", postData, Http.getAuthToken());
};

export const PasswordUpdateDetails = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/passwordupdate", postData, Http.getAuthToken());
};

/* Custom Menu*/
export const custommenuList = (postData) => {
    console.log('menu 3');

    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/custommenu", postData);
};
export const custommenuListById = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/custommenu/listbyId", postData);
};

/* Category*/
export const categoryList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/category/", Http.getAuthToken());
};
export const categoryAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/category/add_category", postData, Http.getAuthToken());
};
export const categoryUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/category/" + updateID, postData, Http.getAuthToken());
};
export const categoryDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/category/" + deleteID, Http.getAuthToken());
};

/* Sub Category*/
export const subcategoryList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/subcategory/", Http.getAuthToken());
};
export const subcategoryAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/subcategory/add_subcategory", postData, Http.getAuthToken());
};
export const subcategoryUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/subcategory/" + updateID, postData, Http.getAuthToken());
};
export const subcategoryDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/subcategory/" + deleteID, Http.getAuthToken());
};

/* Itemsize */
export const itemsizeList = (postData) => {
    console.log('api item size', postData);
    console.log(postData);

    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/itemsize", postData);
};

/* Website Category */
export const websiteCategoryList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/website/category");
};

/* Website SubCategory */
export const websiteSubCategoryList = (query, page, limit, sortby, order, id) => {
    const baseUrl = getBaseEndpointUrl();
    // return Http.get(baseUrl + '/website/category/1');
    return Http.get(baseUrl + '/website/category/' + id);
};

/* Website SubCategory product */
export const websiteSubCategoryProductList = (query = 'undefined', page, limit, sortby, order, id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/subcategory/products/' + id + '?query=' + query, Http.getAuthToken());
};

export const websiteProductView = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/product/' + id + '?query=' + localStorage.getItem('temporaryToken'), Http.getAuthToken());
};


/* addtocart */
export const websiteAddToCart = (postData) => {
    console.log(postData, 'API postData');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/addtocart", postData, Http.getAuthToken());
};

/*updatetocart*/

export const websiteUpdateToCart = (postData) => {
    console.log(postData, 'API postData');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/updatecart", postData, Http.getAuthToken());
};

export const websiteCartList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + '/website/cartlists', postData, Http.getAuthToken());
};
/* Remove cart products */
export const websiteRemoveCart = (postData) => {
    console.log('api cart', postData);
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/removecart", postData, Http.getAuthToken());
};

/* view cart products */
export const websiteViewCart = (postData) => {
    console.log('vew cart', postData);
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/viewcart", postData, Http.getAuthToken());
};

// view fav Products
export const websiteViewFav = (postData) => {
    console.log('vew cart', postData);
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/viewFav", postData, Http.getAuthToken());
};

/*POS get API*/
export const websiteGetPosList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/poslist');
};

/*contact us API*/
export const getContactList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/contactus');
};

/*about us API*/
export const getAboutList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/aboutus');
};

/*clear cart by subcategory API*/
export const websiteClearCartData = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/clearinactivecart');
};

/*clear cart by subcategory API*/
export const websiteClearCartCategoryData = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/clearinactivecartcategory');
};

/*clear cart by product API*/
export const websiteClearCartProductData = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/clearinactivecartproduct');
};

/* Product List */
export const websiteProductList = (query, page, limit, sortby, order, reward) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/website/products?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&reward=" + reward, {}, Http.getAuthToken());
};


export const websiteBannerList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/banners');
};

export const websiteClearItemCartData = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/removeinactiveitem');
};

export const websiteUpdateQuantityToCart = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/updateQuantity", postData, Http.getAuthToken());
};

export const createOrder = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/website/createorder", postData, Http.getAuthToken());
};

export const websiteRecentProduct = () => {
    console.log('4');

    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + '/website/recentproducts');
};

export const addToProductFavourite = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/website/addtofavorites", postData, Http.getAuthToken());
    // return Http.post(baseUrl + "/website/removefavorites", { id: 49 }, Http.getAuthToken());
};


export const removeFavouriteProduct = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/website/removefavorites", postData.payload, Http.getAuthToken());
};

// export const websiteFavouriteList = (query, page, limit, sortby, order, reward) => {
//     const baseUrl = getBaseEndpointUrl();
//     return Http.get(baseUrl + '/website/favourites?query=' + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
// };


//general setting
export const generalList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/website/generalsetting", Http.getAuthToken());
};


export const websiteFavouriteList = (query, page, limit, sortby, order, reward) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/website/getfavoritelist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const websiteAddFavouriteCart = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/website/addfavouriteCart", postData, Http.getAuthToken());
};

export const validateGiftCard = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/website/validateGiftcard", postData, Http.getAuthToken());
};

export const CreateNewPayment = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/order/payment", postData, Http.getAuthToken());
};

export const CreateNewOrder = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postData');
    return Http.post(baseUrl + "/order/newOrder", postData, Http.getAuthToken());
};

export const GetRecentOrderList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postData');
    return Http.post(baseUrl + "/order/recentOrder", postData, Http.getAuthToken());
};

export const getScheduleTime = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/getscheduleTime", Http.getAuthToken());
};

//general setting
export const viewOrderList = (order_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/" + order_id, Http.getAuthToken());
};

export const ReOrderNew = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postData');
    return Http.post(baseUrl + "/order/CreateReorder", postData, Http.getAuthToken());
};

export const getCardType = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'cardtype');
    return Http.post(baseUrl + "/order/checkcardtype", postData, Http.getAuthToken());
};

export const NewOrderPDF = (order_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/CreateOrderPdf/" + order_id, Http.getAuthToken());
};

export const validateToken = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postData');
    return Http.post(baseUrl + "/auth/validateToken", postData, Http.getAuthToken());
};

export const UpdateOrderDetail = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postData');
    return Http.post(baseUrl + "/order/UpdateOrderDetails", postData, Http.getAuthToken());
};


export const getUserGiftcardDetails = (user_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/giftcards", {}, Http.getAuthToken());
};

export const getAllUsers = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/lists", {}, Http.getAuthToken());
};

export const addAmountToWallet = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/user/addtowallet", postData, Http.getAuthToken());
};

export const getSharedGiftcardDetails = (user_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/purchasedgiftcards", {}, Http.getAuthToken());
};
export const addAmountToCoupon = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/user/addtocoupon", postData, Http.getAuthToken());
};

export const validateRedeemCoupon = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(postData, 'postDatapostData');
    return Http.post(baseUrl + "/user/checkCoupon", postData, Http.getAuthToken());
};

export const getColorList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/colorpicker/getColorList", Http.getAuthToken());
};

export const GetRecentReceiptList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/getlastreceipt", Http.getAuthToken());
};

export const BuyGiftCardTable = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/getGiftList", Http.getAuthToken());
};
