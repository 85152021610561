import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { userData, giftcardData, profileData, profileUpdateData, profileSelector } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';
import { assestURL, secretKey } from '../../services/config';
import moment from 'moment';
import { CardType, generalSelector } from "../../store/reducer/general";
import { newPayment, orderSelector } from '../../store/reducer/order';
import Swal from 'sweetalert2'
import { DatePicker, Space } from 'antd';

function Topup(){
	const { RangePicker } = DatePicker;	
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const [cardtypeImage, setcardtypeImage] = useState('')
	const { cardtypeDatas, cardtypeLoading } = useSelector(generalSelector);
    let { id } = useParams();
	if (!userDetails) {
		// dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const [formDetails, setformDetails] = useState(
		{ id:0,  user_id: '', first_name: "", email: "", mobile: '', amount: '', cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' });
	const { userDatas, userloading, giftDatas, profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const {  paymentloading, paymentMessage, paymentDatas } = useSelector(orderSelector);
	const [displayFakeData, setdisplayFakeData] = useState(0)
	const [TransactionID, setTransactionID] = useState(null);
	const BuyNewCard = (e) => {
		e.preventDefault();
		setAddFormSubmit(true);

	
		if (formValidator.current.allValid()) {
			
			let paymentData = {channel: 'WEB', autoCapture: true, processAsSale: false}

			paymentData.order = {
				orderId: 'buy',
				totalAmount: parseFloat(formDetails.amount).toFixed(2),
				description: 'buy gift cards for the user',
				currency: 'USD',
				orderBreakdown: {
					subtotalAmount: parseFloat(formDetails.amount).toFixed(2),
					items: [{ "unitPrice": formDetails.amount, "quantity": 1}]
				}
			}
			paymentData.customerAccount = {
				payloadType: "KEYED",
				accountType: "CHECKING",
				cardDetails: {
					dataFormat: "PLAIN_TEXT",
					cardNumber: formDetails.cc_number.replaceAll(" ", ""),
					cvv: formDetails.cc_cvc,
					expiryDate: formDetails.cc_month.replace('/', '')
				}
			}

			dispatch(newPayment({ postData: paymentData }));

		}
	};

	const getProfileDispatch = (loginUser) => { dispatch( profileData({ user_id: loginUser }) ) };
	const giftCardDispatch = (loginUser) => { dispatch( giftcardData({ user_id: loginUser })) };
	const userDispatch = (loginUser) => {  dispatch( userData({ user_id: loginUser }) ) };

	useEffect(() => {
		getProfileDispatch(loginUser)
		giftCardDispatch(loginUser)
		userDispatch(loginUser)
	}, [])

	const displayFakeNumber = () => {
		setdisplayFakeData(1)
	}

	const displayNumber = () => {
		setdisplayFakeData(0)
	}

	useEffect(() => {
		if (cardtypeDatas) {

			console.log(cardtypeDatas, 'cardtypeDatas');
			if (cardtypeLoading === API_STATUS.FULFILLED) {
				if (cardtypeDatas.status == true) {

					if (cardtypeDatas?.result.cardType == 'VISA' || cardtypeDatas?.result.cardType == 'VISA DEBIT') {
						setcardtypeImage(require('../../images/visa.jpg'));
					}
					if (cardtypeDatas?.result.cardType === 'ELECTRON') {
						setcardtypeImage(require('../../images/electron.jpeg'));
					}
					if (cardtypeDatas?.result.cardType === 'MAESTRO') {
						setcardtypeImage(require('../../images/Maestro.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DISCOVER') {
						setcardtypeImage(require('../../images/discover.png'));
					}
					if (cardtypeDatas?.result.cardType === 'JCB') {
						setcardtypeImage(require('../../images/jcb.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}

					if (cardtypeDatas?.result?.cardType == undefined) {
						setcardtypeImage('')
					}
				}
			}

			if (cardtypeLoading === API_STATUS.PENDING) {
				toast.dismiss();
			}

		}
	}, [cardtypeDatas])

	useEffect(() => {
		formValidator.current.showMessages()
	}, [])



	useEffect(() => {
		if(giftDatas){
			console.log(giftDatas, 'giftDatas');
		}
	}, [giftDatas])

	useEffect(() => {
		if(userDatas){
			console.log(userDatas, 'userDatas');
		}
	}, [userDatas])
	
	const SelectUser = (e) => {

		console.log(e.target.value, 'e.target')
		if(e.target.value){
			let selecteduser = userDatas.data.filter((user, key)=>{
				// console.log(user, user.id ,e.target.value, 'user');
				return user.id === Number(e.target.value)
			})

			if(selecteduser.length){
				setformDetails({...formDetails, user_id: e.target.value, first_name: selecteduser[0].first_name + ' ' + selecteduser[0].last_name, email: selecteduser[0].email, mobile: selecteduser[0].mobile})
			}

		}else{
			setformDetails({...formDetails, user_id: e.target.value, first_name: '', email: '', mobile: ''})
		}	
	}

	const cc_format = (value) => {
		const v = value
			.replace(/\s+/g, "")
			.replace(/[^0-9]/gi, "")
			.substr(0, 16);
		const parts = [];

		for (let i = 0; i < v.length; i += 4) {
			parts.push(v.substr(i, 4));
		}

		return parts.length > 1 ? parts.join(" ") : value;
	}

	useEffect(() => {
		if (paymentloading === API_STATUS.FULFILLED) {
			if (paymentDatas) {
				if (paymentDatas.status === false) {
					Swal.fire({
						title: 'Payment Failed!',
						icon: 'error',
						text: paymentDatas.message,
						cancelButtonColor: '#d33',
						cancelButtonText: 'Retry Payment',
						showConfirmButton: false,
						showCancelButton: true
					}).then((result) => {
						if (result.dismiss) {
							setformDetails({ ...formDetails, user_id: '', first_name: '', email:'', mobile:'', amount: 0, cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' })
							setdisplayFakeData(0)
							setcardtypeImage('')
						}
					})
					// return false;
				}
				else {
					setTransactionID(paymentDatas.data.uniqueReference)

					let name = formDetails.first_name.split(" ")
					let newgiftcard = { 
						load_points: formDetails.amount, 
						user_id: Number(formDetails.user_id), 
						giftcard_id : paymentDatas.data.uniqueReference,
						from_date: null, 
						to_date: null, 
						email_id: formDetails.email,
						mobile: formDetails.mobile,
						gift_from: loginUser,
						first_name: name[0],
						last_name: name[1]
					}
					// dispatch(newGiftCoupon({ postData: newgiftcard }));

					// setshowModal(true);
					Swal.fire({
						title: 'Payment Successful!',
						icon: 'success',
						html: `Payment Successful. Gift ID : <strong>${paymentDatas.data.uniqueReference}</strong>`,
						// timer: 2000,
						timerProgressBar: true,
						// footer: 'Please wait Confirming your Order...',
						// showConfirmButton: false,
						confirmButtonText: 'Close',
						}).then((result) => {
							if (result.isConfirmed) {
								window.location.href = '/giftcards'
							}
					})

				}

				
			}
			// window.scrollTo(0, 0)
		} else if (paymentloading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (paymentloading === API_STATUS.REJECTED) {

		}
	}, [paymentloading, paymentDatas])

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="section-full mt-4 shop-account">
					<div className="container">
						<h3 className="m-b5">New GiftCard </h3>
						<div className="row">
							<div className="col-lg-12">
								<div className="p-a30 border-1 m-auto radius-sm">
									<div className="tab-content">
										<form id="login" className="tab-pane active">
											
											<div className='row'>
											
												<div className="form-group col-6" >
												{/* <label>Select User <span style={{ color: 'red' }}>*</span></label> */}
													<select name="user_id" className='form-control' style={{height: '2.75rem'}} 
													onChange={e=> SelectUser(e)}>
													<option value="">Select User</option>
														{ (userDatas && userDatas.data) ? userDatas.data.map((user, key) => {
															return <option value={user.id}> {user.first_name} {user.last_name} - {user.email} </option>
														}) : <option value=""> </option>
															
														}
													</select>

													{formValidator.current.message(
														'user_id',
														formDetails.user_id,
														'required',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please select user',
															}
														}
													)}
												</div>
												<div className="form-group col-6" >
													{/* <label>  </label> */}
													{/* <input required="" className="form-control" placeholder="First Name" type="text" /> */}
													<input
														className="form-control"
														name='first_name'
														placeholder="Full Name"
														type="text"
														value={formDetails.first_name}
														autoComplete="off"
														readOnly={true}
														onChange={val => {
															setformDetails({ ...formDetails, first_name: val.target.value });
														}}
													/>
												</div>
												
												<div className="form-group col-6">
													{/* <label> </label> */}
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														name='mobile'
														placeholder="Mobile"
														type="text"
														value={formDetails.mobile}
														autoComplete="off"
														readOnly={true}
														onChange={val => {
															setformDetails({ ...formDetails, mobile: val.target.value });
														}}
													/>
												</div>
												
												<div className="form-group col-6">
													{/* <label> </label> */}
													<input
														className="form-control"
														name='email'
														placeholder="Email"
														type="email"
														value={formDetails.email}
														autoComplete="off"
														readOnly={true}
														onChange={val => {
															setformDetails({ ...formDetails, email: val.target.value });
														}}
													/>
												</div>
												<div className="form-group col-6">
													{/* <label> </label> */}
													<input
														className="form-control"
														name='amount'
														placeholder="Enter the Amount"
														type="text"
														value={formDetails.amount}
														autoComplete="off"
														onChange={val => {
															var numbers = /^[0-9]*\.?\d*$/;
															
															if (val.target.value.match(numbers) || val.target.value == '') {
																setformDetails({ ...formDetails, amount: val.target.value });
															} else {
																return false;
															}
														}}
													/>
													{formValidator.current.message(
													'amount',
													formDetails.amount,
													'required',
													{
														className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
														messages: {
															required: 'Please Enter amount',
														}
													}
												)}
												</div>
											<div className='form-group col-8'>
												<h4>Payment Method</h4>
											</div>
											<div className='form-group col-4' style={{ maxWidth: '10%' }}>
												{(cardtypeImage) ? <img src={cardtypeImage}></img> : ""}
											</div>
											<div className="form-group col-6">
												<input type="text" value={formDetails.cc_name} className="form-control"
													placeholder="Card Holder Name"
													name='cc_name'
													onChange={val => {
														setformDetails({ ...formDetails, cc_name: val.target.value });
													}}
												/>
												{formValidator.current.message(
													'cc_name',
													formDetails.cc_name,
													'required|alpha_space',
													{
														className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
														messages: {
															required: 'Please Enter Name',
															alpha_space: 'Please Enter Valid Name',
														}
													}
												)}
											</div>
											<div className="form-group col-6">
												<input type="text" value={(displayFakeData == 0 || formDetails.cc_number == '') ? cc_format(formDetails.cc_number) : ('****' + formDetails.cc_number.substr(-4))} onBlur={displayFakeNumber} onFocus={displayNumber} className="form-control"
													placeholder="Card number"
													name='cc_number'
													onPaste={(e) => {
														e.preventDefault()
														return false;
													}}
													onCopy={(e) => {
														e.preventDefault()
														return false;
													}}
													onChange={val => {

														let name = val.target.value;
														var format = /^[0-9\s]*$/;
														if (format.test(name)) {
															setformDetails({ ...formDetails, cc_number: val.target.value });

														} else {
															return false
														}

														console.log(val.target.value, 'val.target.value');
														let type = val.target.value.replaceAll(" ", "")
														if (type.length == 16) {
															let type = val.target.value.replaceAll(" ", "")
															dispatch(
																CardType({ card_id: type })
															)
														} else {
															setcardtypeImage('')
														}


														// if (name.length > 16) {
														// 	return false
														// }

														// var regExp = /[a-zA-Z]/g;

														// 	const joy = val.target.value.toString().replace(/\d{2}(?=.)/g, '$& ');
														// joy.join(' ');
														//let name = val.target.value.toString().replace(/.{1,4}(?=(.{4})+$)/g, '$& ')

													}}
												/>
												{formValidator.current.message(
													'cc_number',
													formDetails.cc_number,
													'required|card_num',
													{
														className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
														messages: {
															required: 'Please Enter Card Number',
															card_num: 'Please Enter Valid Card Number',
														}
													}
												)}
												{/* <span className=''>{cardNumberError}</span> */}
											</div>
											<div className="form-group col-6">
												<input type="text" value={formDetails.cc_month} className="form-control"
													placeholder="MM/YY"
													name='cc_month'
													onChange={val => {

														if (val.target.value == '/') {
															setformDetails({ ...formDetails, cc_month: '' });
															return false
														}
														console.log(val.target.value, val.target.value.length, new Date().getFullYear().toString().substr(-2), 'val.target.value');
														if (val.target.value.length > 5) {
															return false;
														}
														if (val.target.value.length) {
															let data = val.target.value.split("/")

															if (data[0] > 12) {
																// if (data[0] > 12 || ( data[0].length == 1 && data[0] < 1)) {
																return false
															}
															let year = new Date().getFullYear().toString().substr(-2);
															console.log(year, 'year');

															if (data.length > 1) {
																if (data[1].length == 2) {
																	if (year === data[1] || (data[1] <= (Number(year) + Number(20)))) {

																	} else {
																		return false
																	}
																}

																if (data[1].length > 2) {
																	return false
																}
															}
															//  && (Number(data[1])) > new Date().getFullYear().toString().substr(-2)){
															// 	return false;
															// }

														}

														var regExp = /[a-zA-Z]/g;
														if (regExp.test(val.target.value)) {
															return false
														}


														if (val.target.value.length === 2 && val.target.value > 12) {
															return false;
														}
														console.log(val.target.value.indexOf('/'), 'val.target.value--1');
														if (val.target.value.length === 2) {
															if (val.target.value.indexOf('/') !== -1) {
																let replacevalue = val.target.value.replace("/", '')
																if (replacevalue > 0 && val.target.value != '1/') {
																	setformDetails({ ...formDetails, cc_month: "0" + val.target.value });
																} else {
																	setformDetails({ ...formDetails, cc_month: val.target.value });
																}

																return false
															}
															val.target.value = val.target.value + '/'
														}
														else {
															// if (val.target.value.length === 3 && val.target.value.charAt(2) === '/')
															// val.target.value = val.target.value.replace('/', '');
														}

														if (val.target.value.length === 4 && val.target.value.charAt(3) < 2) {
															return false
														}
														if (val.target.value.length === 5) {
															let third = val.target.value.charAt(3)
															let fourth = val.target.value.charAt(4)
															let numbers = third + fourth
															console.log(numbers, 'numbers-');
															let currentyear = new Date().getFullYear().toString().substr(-2)
															if (Number(numbers) < currentyear)
																return false
														}
														let updateValue = val.target.value;

														if (updateValue.length === 1 && (updateValue != 1 && updateValue != 0)) {
															updateValue = "0" + updateValue;
															updateValue = updateValue + '/'
														}
														// console.log(val.target.value, 'val.target.value---2');
														setformDetails({ ...formDetails, cc_month: updateValue });
													}}
												/>
												{formValidator.current.message(
													'cc_month',
													formDetails.cc_month,
													'required|card_exp',
													{
														className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
														messages: {
															required: 'Please Enter Card Expiry Month & Year',
															card_exp: 'Please Enter Valid Month & Year (MM/YY)',
														}
													}
												)}
												{/* <span className=''>{expiryError}</span> */}
											</div>
											<div className="form-group col-6">
												<input type="password" value={formDetails.cc_cvc} className="form-control"
													placeholder="Card Verification Number"
													name='cc_cvc'
													onChange={val => {
														if (val.target.value.length > 4) {
															return false;
														}
														var numbers = /^[0-9]+$/;
														if (val.target.value.match(numbers) || val.target.value == '') {
															setformDetails({ ...formDetails, cc_cvc: val.target.value });
														} else {
															return false;
														}

													}}
												/>
												{formValidator.current.message(
													'cc_cvc',
													formDetails.cc_cvc,
													'required|integer|min:3|max:7',
													{
														className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
														messages: {
															required: 'Please Enter CVC',
															integer: 'Please Enter Valid Number',
															min: 'CVV Number must be at least 3 characters',
															max: 'CVV Number should not greater than 7 characters',
														}
													}
												)}

											</div>
												<div className='col-6'>
													<a className="btn btn-success pull-right w-50"
														onClick={BuyNewCard}
													>Submit</a>
												</div>
												{/* <div className='col-6'>
													<a href='/changepassword' style={{ color: 'black' }}
														className='btn btn-info  text-center w-50' >Change password</a>
												</div> */}
											</div>

											{/* <button  type="submit" className="btn btnhover">CREATE</button> */}

										</form>
									</div>
								</div>
							</div>
						</div><br></br>
					</div>
				</div >
			</div >
			<Footer />
		</>

	)
}

export default Topup;
